import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  TextField,
  ThemeProvider,
} from "@mui/material";
import PasswordInput from "./PasswordInput"; // Import your PasswordInput component
import { createTheme } from '@mui/material/styles';
import {Slide} from '@mui/material';

const theme2 = createTheme({
    palette: {
      primary: {
        main: "#000000"
      }
    }
  });
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const AuthScreen = ({
  openDialog,
  setOpenDialog,
  DialogMessage,
  username,
  setUsername,
  password,
  setPassword,
  handleLogin,
  isAnimating,
}) => {
  return (
    <div
      style={{
        backgroundColor: "#f4f4f9",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "'Roboto', sans-serif",
        overflow: "hidden",
      }}
    >
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{"System"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{DialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#ffffff",
          padding: "30px",
          borderRadius: "12px",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
          width: "100%",
          maxWidth: "360px",
          animation: "fadeIn 0.5s ease-in-out",
        }}
      >
        <img
          src="https://edugate-eg.com/wp-content/uploads/2023/04/10-YEARS-EDUGATE-copy.png"
          alt="Logo"
          style={{
            borderRadius: "8px",
            marginBottom: "15px",
            width: "80px",
            transform: isAnimating ? "scale(1.2) rotate(360deg)" : "scale(1)",
            transition: "transform 0.6s ease-in-out",
          }}
        />
        <h2
          style={{
            color: "#2c3e50",
            marginBottom: "20px",
            fontWeight: "500",
            fontSize: "22px",
          }}
        >
          EDU GATE Dashboard
        </h2>
        <ThemeProvider theme={theme2}>
          <TextField
            id="username"
            label="Username"
            margin="normal"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            required
            InputLabelProps={{
              style: { color: "#34495e" },
            }}
            InputProps={{
              style: {
                color: "#34495e",
                borderRadius: "6px",
              },
            }}
            sx={{
              marginBottom: "15px",
            }}
          />
          <PasswordInput
            password={password}
            handlePassword={(e) => setPassword(e.target.value)}
            sx={{
              marginBottom: "20px",
            }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              marginTop: "15px",
              padding: "10px",
              fontWeight: "600",
              backgroundColor: "#3498db",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#2980b9",
              },
            }}
            onClick={handleLogin}
          >
            Login
          </Button>
        </ThemeProvider>
      </Box>
    </div>
  );
};

export default AuthScreen;
