// EditTalentModal.js
import React from 'react';
import { Modal, Box, Button,Typography, TextField, Grid, FormControl, InputLabel, Select, MenuItem, LinearProgress, Chip, InputAdornment, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';


const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: '10px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));

const EditTalentModal = ({
  open,
  handleClose,
  currentTalent,
  setCurrentTalent,
  handleEditSubmit,
  handlePhotoUpload,
  photo,
  progress,
  memberName,
  setMemberName,
  handleKeyPress,
  handleAddMember,
  handleDeleteMember
}) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-talent-modal"
      aria-describedby="edit-talent-modal-description"
    >
      <Box
        component="form"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: 'auto',
          maxHeight: '90%',
        }}
        onSubmit={handleEditSubmit}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Edit Talent
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Full Name"
              fullWidth
              value={currentTalent.full_name}
              onChange={(e) => setCurrentTalent({ ...currentTalent, full_name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              fullWidth
              value={currentTalent.email}
              onChange={(e) => setCurrentTalent({ ...currentTalent, email: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="School Name"
              fullWidth
              value={currentTalent.school_name}
              onChange={(e) => setCurrentTalent({ ...currentTalent, school_name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Grade</InputLabel>
              <Select
                label="Grade"
                value={currentTalent.school_grade}
                onChange={(e) => setCurrentTalent({ ...currentTalent, school_grade: e.target.value })}
              >
                <MenuItem value="Grade 10">Grade 10</MenuItem>
                <MenuItem value="Grade 11">Grade 11</MenuItem>
                <MenuItem value="Grade 12">Grade 12</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Mobile"
              fullWidth
              value={currentTalent.mobile}
              onChange={(e) => setCurrentTalent({ ...currentTalent, mobile: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Talent Category</InputLabel>
              <Select
                label="Talent Category"
                value={currentTalent.talent_category}
                onChange={(e) => setCurrentTalent({ ...currentTalent, talent_category: e.target.value })}
              >
                <MenuItem value="Singing">Singing</MenuItem>
                <MenuItem value="Theater Performance">Theater Performance</MenuItem>
                <MenuItem value="Music">Music</MenuItem>
                <MenuItem value="Art">Art</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Presentation Type</InputLabel>
              <Select
                label="Presentation Type"
                value={currentTalent.presentation_type}
                onChange={(e) => setCurrentTalent({ ...currentTalent, presentation_type: e.target.value })}
              >
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="Group">Group</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Add Team Member"
                fullWidth
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
                onKeyPress={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="add team member" onClick={handleAddMember}>
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box display="flex" flexWrap="wrap" gap={1}>
                {currentTalent.team_members.map((member, index) => (
                  <Chip
                    key={index}
                    label={member}
                    onDelete={() => handleDeleteMember(member)}
                    style={{ margin: '2px' }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Teacher Name"
              fullWidth
              value={currentTalent.teacher_name}
              onChange={(e) => setCurrentTalent({ ...currentTalent, teacher_name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Teacher Mobile"
              fullWidth
              value={currentTalent.teacher_mobile}
              onChange={(e) => setCurrentTalent({ ...currentTalent, teacher_mobile: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Additional Details"
              fullWidth
              value={currentTalent.additional_details}
              onChange={(e) => setCurrentTalent({ ...currentTalent, additional_details: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Video Link"
              fullWidth
              value={currentTalent.video_link}
              onChange={(e) => setCurrentTalent({ ...currentTalent, video_link: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Comment"
              fullWidth
              value={currentTalent.comment}
              onChange={(e) => setCurrentTalent({ ...currentTalent, comment: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={currentTalent.status}
                onChange={(e) => setCurrentTalent({ ...currentTalent, status: e.target.value })}
              >
                <MenuItem value="0">Not Approved</MenuItem>
                <MenuItem value="1">Approved</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="photo-upload">
              <input
                style={{ display: "none" }}
                id="photo-upload"
                name="photo-upload"
                type="file"
                accept="image/jpeg,image/png"
                onChange={handlePhotoUpload}
              />
              <StyledButton
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload Photo
              </StyledButton>
            </label>
            {photo && <Typography sx={{ mt: 1, color: 'text.secondary' }}>{photo.name}</Typography>}
          </Grid>
        </Grid>
        <StyledButton variant="contained" type="submit" fullWidth>
          Save Changes
        </StyledButton>
        {progress && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default EditTalentModal;
