import React, { useEffect,useState } from 'react';
import {
  Container, TextField, Tooltip,CssBaseline,Button, Grid, Typography, MenuItem, Select, InputLabel, FormControl, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import "../../RegistrationForm.css"
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import StarIcon from '@mui/icons-material/Star'; // Star icon from Material UI Icons
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy'; // Theater Mask for acting
import MovieCreationIcon from '@mui/icons-material/MovieCreation'; // Film for filmmaking
import HighlightIcon from '@mui/icons-material/Highlight'; // Spotlight Icon
import { keyframes } from '@mui/system';
import ReportProblem from '../ReportProblem';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import HelpButton from '../HelpButton';
// Keyframes for glowing text effect

// Keyframes for glowing text effect
const glowEffect = keyframes`
  0% { text-shadow: 0 0 8px rgba(255, 215, 0, 0.8), 0 0 15px rgba(255, 215, 0, 0.6); }
  50% { text-shadow: 0 0 12px rgba(255, 99, 71, 0.8), 0 0 20px rgba(255, 99, 71, 0.6); }
  100% { text-shadow: 0 0 8px rgba(255, 215, 0, 0.8), 0 0 15px rgba(255, 215, 0, 0.6); }
`;

// Keyframes for subtle icon rotation
const rotateIcon = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
`;

// Styled title with glowing animation
const AnimatedTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  fontWeight: 600,
  background: 'linear-gradient(90deg, #FF6347, #FFD700)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${glowEffect} 3s ease-in-out infinite`,
  fontSize: '2.5rem',
  textTransform: 'uppercase',
  letterSpacing: '1.5px',
  marginTop:"10px"
}));

// Styled Star Icon
const AnimatedIcon = styled(StarIcon)(({ theme }) => ({
  color: '#FFD700', // Uniform golden color for icons
  fontSize: '2.2rem',
  animation: `${rotateIcon} 4s ease-in-out infinite`,
  transition: 'transform 0.4s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

// Styled Theater Mask Icon for Acting
const AnimatedTheaterIcon = styled(TheaterComedyIcon)(({ theme }) => ({
  color: '#FFD700',
  fontSize: '2.2rem',
  animation: `${rotateIcon} 4s ease-in-out infinite reverse`,
  transition: 'transform 0.4s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

// Styled Film Icon for Filmmaking
const AnimatedFilmIcon = styled(MovieCreationIcon)(({ theme }) => ({
  color: '#FFD700',
  fontSize: '2.2rem',
  animation: `${rotateIcon} 4s ease-in-out infinite`,
  transition: 'transform 0.4s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));
const AnimatedMusicIcon = styled(MusicNoteIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '2.2rem',
  animation:`${rotateIcon} 4s ease-in-out infinite reverse`, // Subtle reverse rotation
  transition: 'transform 0.4s ease',
  '&:hover': {
    transform: 'scale(1.1)', // Less aggressive hover effect
  },
}));

// Styled Spotlight Icon placed beneath the text
const SpotlightIconLeft = styled(HighlightIcon)(({ theme }) => ({
  color:"#ab003c  ",
  fontSize: '5rem',
  position: 'absolute',
  top: '-50px', // Hanging from above
  left: '10%',  // Positioned on the left side
  transform: 'rotate(-200deg)', // Angled towards the text
  //filter: 'blur(3px)',
//  opacity: 0.7,
}));

const SpotlightIconRight = styled(HighlightIcon)(({ theme }) => ({
  color:"#ab003c",
  fontSize: '5rem',
  position: 'absolute',
  top: '-50px', // Hanging from above
  right: '10%', // Positioned on the right side
  transform: 'rotate(200deg)', // Angled towards the text
  //filter: 'blur(3px)',
 // opacity: 0.7,
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000",
    },
    secondary: {
      main: "#FFD700",
    },
    background: {
      default: "#F3F4F6",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  shadows: ["none", "0px 4px 12px rgba(0, 0, 0, 0.1)"],
});

const BackgroundContainer = styled('div')({
  minHeight: '100vh',
  width: '100vw', // Ensure full width
  //height: '100vh', // Ensure full height
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '20px',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient from dark red to black
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1,

  // Optional: Adding abstract soft shapes or lines
  '::before': {
    content: '""',
    position: 'absolute',
    top: '-50px',
    left: '-50px',
    width: '300px',
    height: '300px',
    background: 'rgba(255, 255, 255, 0.1)', // Subtle white blur
    borderRadius: '50%',
    zIndex: -1,
  },
  '::after': {
    content: '""',
    position: 'absolute',
    bottom: '-100px',
    right: '-100px',
    width: '200px',
    height: '200px',
    background: 'rgba(255, 255, 255, 0.1)', // Subtle white blur on the opposite side
    borderRadius: '50%',
    zIndex: -1,
  },
});

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  maxWidth: '800px',
  margin: 'auto',
  background: 'rgba(255, 255, 255, 0.9)', // Slightly transparent white background
  padding: '40px',
  borderRadius: '20px', // Smooth rounded corners
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)', // Soft and professional shadow
  position:"relative"
});

// Adjusted Button Style
const StyledButton = styled(Button)({
  marginTop: '10px',
  padding: '12px 20px',
  backgroundColor: '#C8102E', // Dark red color
  color: '#fff',
  '&:hover': {
    backgroundColor: '#a00d1e', // Slightly darker shade for hover effect
  },
  transition: 'background-color 0.3s',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  borderRadius: '30px',
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TalentContestForm = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [schoolGrade, setSchoolGrade] = useState('');
  const [mobile, setMobile] = useState('');
  const [talentCategory, setTalentCategory] = useState('');
  const [presentationType, setPresentationType] = useState('');
  const [teacherName, setTeacherName] = useState('');
  const [teacherMobile, setTeacherMobile] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [photo, setPhoto] = useState(null);
  const [videoLink, setVideoLink] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState({
    message: '',
    type: '',
  });
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [memberName, setMemberName] = useState('');
  const [progress, setprogress] = useState(false);
  const [availableEvents, setAvailableEvents] = useState([]);




  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const allEvents = response.data;
      const filteredEvents = allEvents.filter(event => event.deleted === "0" && event.has_talent==="1");

      setAvailableEvents(filteredEvents);
    } catch (error) {
    }
};

useEffect(() => {
  fetchAvailableEvents();
}, []);


  const handleAddMember = () => {
    if (memberName.trim() !== '') {
      setTeamMembers([...teamMembers, memberName.trim()]);
      setMemberName('');
    }
  };

  const handleDeleteMember = (memberToDelete) => {
    setTeamMembers(teamMembers.filter(member => member !== memberToDelete));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddMember();
    }
  };

  const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      display: 'flex',
      alignItems: 'center',
      padding: '6px',
      gap: '8px',
      flexWrap: 'wrap',
    },
  }));

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        handleOpenDialog();
        setMessage("Please upload a valid image file (JPEG, PNG)");
        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        handleOpenDialog();
        setMessage('File size exceeds the limit of 2MB. Please choose a smaller file.');
        setPhoto(null);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const extension = file.name.split('.').pop();
          const newName = `edugate_talent_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          handleOpenDialog();
          setMessage('The uploaded file is not a valid image or is corrupted');
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if(availableEvents.length === 0){
      setFeedback({
        message: "The Form is not Open yet. Please Check Later",
        type: 'error',
      });
      setIsSubmitting(false);
      return;

    }

    if (!fullName || !email || !schoolName || !schoolGrade || !mobile || !talentCategory || !presentationType) {
      setFeedback({
        message: "Please fill in all the required fields",
        type: 'error',
      });
      setIsSubmitting(false);
      return;
    }
    setprogress(true);
    const teamFull= fullName+","+teamMembers;

    try {
      const formData = new FormData();
      formData.append('full_name', fullName);
      formData.append('email', email);
      formData.append('school_name', schoolName);
      formData.append('school_grade', schoolGrade);
      formData.append('mobile', mobile);
      formData.append('talent_category', talentCategory);
      formData.append('presentation_type', presentationType);
      formData.append('team_members', teamFull);
      formData.append('teacher_name', teacherName);
      formData.append('teacher_mobile', teacherMobile);
      formData.append('additional_details', additionalDetails);
      formData.append('photo', photo);
      formData.append('video_link', videoLink);
      formData.append('deleted', 0);
      formData.append('comment', "");
      formData.append('status', "0");
      formData.append('event_id', parseInt(availableEvents[0].id,10));




      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/submit-talent-form', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setFeedback({
          message: "Your response was submitted successfully. Thank you for your time.",
          type: 'success',
        });
      } else {
        setFeedback({
          message: "Error submitting the form",
          type: 'error',
        });
      }
    } catch (error) {
      setFeedback({
        message: "Network error. Please try again later.",
        type: 'error',
      });
    } 
    setprogress(false);

  };

  return (
    <div>

    {availableEvents.length === 0 && (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        width: '400px'
      }}>
        <Alert 
          icon={<CheckIcon fontSize="inherit" />} 
          severity="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Registration for the GotTalent is now Closed
        </Alert>
      </div>
    )}
      <div style={availableEvents.length === 0 ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>

<BackgroundContainer >
  <HelpButton/>
    <div style={{marginTop:"50px",marginBottom:"50px", width:"90vw"}} className="form-menu-container">

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <ThemeProvider theme={theme}>
      <CssBaseline />
     
        <StyledCard>
        <Box sx={{ position: 'absolute', right: 16, top: 16 }}> {/* Positioning the ReportProblem component */}
    <Tooltip title="Report a Problem">
    <IconButton
  color="inherit"
  aria-label="notifications"
  sx={{
    mr: 2,
    zIndex: 1200, // Ensures the menu appears on top of other elements
  }}
>
<ReportProblem dynamicNumber="Got Talent Form" />
          </IconButton>
        </Tooltip>
        </Box>
        <Box sx={{ position: 'relative', textAlign: 'center', mt: 4 }}>
      {/* Left and Right Spotlights */}
      <SpotlightIconLeft />
      <SpotlightIconRight />      <AnimatedTitle variant="h4" component="div">
        <AnimatedIcon />
        <AnimatedMusicIcon/>
        EDU GATE GOT Talent 2024 Season 7
        <AnimatedTheaterIcon />
        <AnimatedFilmIcon />
      </AnimatedTitle>
    </Box>
    

            <form encType="multipart/form-data" onSubmit={handleSubmit}>

              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                  <TextField
                    label="Full Name Of Contestant / Team"
                    fullWidth
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="School Name"
                    fullWidth
                    value={schoolName}
                    onChange={(e) => setSchoolName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>School Grade</InputLabel>
                    <Select
                      label="School Grade"
                      value={schoolGrade}
                      onChange={(e) => setSchoolGrade(e.target.value)}
                    >
                      <MenuItem value="Grade 10">Grade 10</MenuItem>
                      <MenuItem value="Grade 11">Grade 11</MenuItem>
                      <MenuItem value="Grade 12">Grade 12</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Mobile"
                    type="tel"
                    inputProps={{ pattern: '[0-9]*' }} 
                    fullWidth
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Talent Category</InputLabel>
                    <Select
                      label="Talent Category"
                      value={talentCategory}
                      onChange={(e) => setTalentCategory(e.target.value)}
                    >
                      <MenuItem value="Singing">Singing</MenuItem>
                      <MenuItem value="Theater Performance">Theater Performance</MenuItem>
                      <MenuItem value="Music">Music</MenuItem>
                      <MenuItem value="Art">Art</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
             
                <Grid item xs={12} sm={6}>
             
                  <FormControl fullWidth required>
                    <InputLabel>Presentation Type</InputLabel>
                    <Select
                      label="Presentation Type"
                      value={presentationType}
                      onChange={(e) => setPresentationType(e.target.value)}
                    >
                      <MenuItem value="Individual">Individual</MenuItem>
                      <MenuItem value="Group">Group</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {presentationType === 'Group' && (
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                          label="Add Team Member"
                          fullWidth
                          value={memberName}
                          onChange={(e) => setMemberName(e.target.value)}
                          onKeyPress={handleKeyPress}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="add team member"
                                  onClick={handleAddMember}
                                >
                                  <AddIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {teamMembers.map((member, index) => (
                            <Chip
                              key={index}
                              label={member}
                              onDelete={() => handleDeleteMember(member)}
                              style={{ margin: '2px' }}
                            />
                          ))}
                        </Box>
                      </Box>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Teacher's Name"
                    fullWidth
                    value={teacherName}
                    onChange={(e) => setTeacherName(e.target.value)}
                    required
                  />
                
                </Grid>
               
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Teacher's Mobile"
                    type="tel"
                    inputProps={{ pattern: '[0-9]*' }}
                    fullWidth
                    value={teacherMobile}
                    onChange={(e) => setTeacherMobile(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Additional Details"
                    fullWidth
                    multiline
                    rows={4}
                    value={additionalDetails}
                    onChange={(e) => setAdditionalDetails(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
  <Button
    variant="contained"
    component="label"
    startIcon={<CloudUploadIcon />}
    fullWidth
    sx={{
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      color: 'white',
      fontSize: '16px',
      padding: '12px 20px',
      borderRadius: '30px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }}
  >
    Upload Photo
    <input
      type="file"
      accept="image/*"
      hidden
      onChange={handlePhotoUpload}
    />
  </Button>
  {photo && <Typography sx={{ marginTop: '8px', fontStyle: 'italic', color: '#555' }}>{photo.name}</Typography>}
</Grid>

                <Grid item xs={12}>
                <TextField
                    label="Video Link"
                    fullWidth
                    value={videoLink}
                    onChange={(e) => setVideoLink(e.target.value)}
                    required
                  />                          
                <Typography
    variant="body2"
    style={{ color: 'red', marginTop: '8px', fontStyle: 'italic' }}
  >
    (Tip: Please upload your video to a drive and make sure the access is public. Then add the link here.)
  </Typography>
                </Grid>


                <Grid item xs={12}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </StyledButton>
                </Grid>
                {progress && (
    <Box sx={{ width: '100%', mt:1 }}>
    <LinearProgress />
                    </Box>
                )}
                {feedback.message && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                      align="center"
                    >
                      {feedback.message}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>

        </StyledCard>
      </ThemeProvider>
      </div>
    </BackgroundContainer>
    </div>
    </div>

  );
};

export default TalentContestForm;
