import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Modal, TextField, IconButton, Typography, Grid, InputLabel, Select, MenuItem, FormControl, LinearProgress,Dialog,
    DialogTitle,InputAdornment, Chip,DialogContentText,
    DialogContent,Slide,
    DialogActions, } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Avatar from '@mui/material/Avatar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Collapse, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CommentIcon from '@mui/icons-material/Comment';
import LinkIcon from '@mui/icons-material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import * as XLSX from 'xlsx';
import DeleteIcon from '@mui/icons-material/Delete';
import EditTalentModal from './EditTalentModal';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const GotTalentPage = () => {
  const [talents, setTalents] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentTalent, setCurrentTalent] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialTalent, setInitialTalent] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [progress, setProgress] = useState(false);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [CurrentTeam, setCurrentTeam] = useState([]);
  const [selectedComment, setSelectedComment] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [memberName, setMemberName] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterTalent, setFilterTalent] = useState('');
  const [TalentToDelete, setTalentToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const extension = file.name.split('.').pop();
          const newName = `got_talent_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage("The uploaded file is not a valid image or is corrupted");
          setSeverity("error");
          setAlertOpen(true);
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000",
      },
      secondary: {
        main: "#000000",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });

  const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: '10px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));

  useEffect(() => {
    fetchTalents();
  }, []);



  const fetchTalents = async () => {
    setProgress(true);
    try {
        // Fetch the talents data
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
        const talentsResponse = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/gettalents',{
          headers: {
            Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
  
          }
        });
        
        // Fetch the available events data
        const eventsResponse = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
        const availableEventsData = eventsResponse.data.filter(event => event.deleted === "0");
        
        // Extract available event IDs
        const availableEventIds = availableEventsData.map(event => event.id);
        
        // Filter talents based on whether their event_id exists in availableEventIds
        const filteredTalents = talentsResponse.data.filter(talent => availableEventIds.includes(talent.event_id));
        
        // Set the filtered talents
        setTalents(filteredTalents.reverse());
    } catch (error) {
        setMessage("Error fetching talents: " + error);
        setSeverity("error");
        setAlertOpen(true);
    }
    setProgress(false);
};


  const handleEditOpen = (talentItem) => {
    setCurrentTalent(talentItem);
    setInitialTalent(talentItem);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentTalent(null);
    setInitialTalent(null);
    setEditOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
  
    // Check if the presentation type is "individual" and set team_members to an empty array if it is
    if (currentTalent.presentation_type === 'individual') {
      currentTalent.team_members = [];
    }
  
    const hasChanged = Object.keys(initialTalent).some(
      key => initialTalent[key] !== currentTalent[key]
    );
  
    if (hasChanged || photo) {
      setProgress(true);
      try {
        const { _id, ...updatedTalent } = currentTalent;
        const updatedTalent2 = {
          ...updatedTalent,
          photo: photo
        };
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage

        if (!token) {
          // Handle missing token (e.g., redirect to login page)
          console.error('No token found');
          return;
        }
  
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/updatetalent/${_id}`, updatedTalent2, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

          }
        });
  
        setPhoto(null);
        fetchTalents();
        setMessage("Talent updated successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleEditClose();
      } catch (error) {
        setMessage("Error updating talent: " + error);
        setSeverity("error");
        setAlertOpen(true);
        handleEditClose();
      }
      setProgress(false);
    } else {
      handleEditClose();
    }
  };
  const handleAddMember = () => {
    if (memberName.trim() !== '') {
      const updatedTeamMembers = [...currentTalent.team_members, memberName.trim()];
      
      const updatedTalent = {
        ...currentTalent,
        team_members: updatedTeamMembers
      };
      
      setCurrentTalent(updatedTalent);
      setMemberName('');
    }
  };
  
  const handleDeleteMember = (memberToDelete) => {
    const updatedTeamMembers = currentTalent.team_members.filter(member => member !== memberToDelete);
    
    const updatedTalent = {
      ...currentTalent,
      team_members: updatedTeamMembers
    };
    
    setCurrentTalent(updatedTalent);
  };
  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddMember();
    }
  };
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setSelectedImage('');
    setImageModalOpen(false);
  };
  const handleTeamClick = (teamMembers) => {
    setCurrentTeam(teamMembers);
    setTeamModalOpen(true);
  };

  const handleTeamModalClose = () => {
    setCurrentTeam(null);
    setTeamModalOpen(false);
  };
  const handleCommentClick = (comment) => {
    setSelectedComment(comment);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedComment("");
  };
  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
  };
  const filteredTalents = talents.filter((talent) => {
    return (
      (filterStatus === '' || talent.status === filterStatus) &&
      (filterTalent === '' || talent.talent_category === filterTalent)
    );
  });



  const handleExportToExcel = () => {
    // Map over filteredTalents and convert team_members array to a string
    const exportData = filteredTalents.map(talent => ({
      ...talent,
      team_members: talent.team_members.join(', ') // Converts the array to a comma-separated string
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Talents');
    XLSX.writeFile(workbook, 'Talents.xlsx');
  };
  
  const handleOpenDeleteDialog = (TalentId) => {
    setTalentToDelete(TalentId);
    setDeleteDialogOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setTalentToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    setProgress(true);
    const token = localStorage.getItem('token');  // Retrieve the token from localStorage

    if (!token) {
      // Handle missing token (e.g., redirect to login page)
      console.error('No token found');
      return;
    }
    try {
      await axios.delete(`https://edutech-backend-471f7cb69116.herokuapp.com/api/deleteTalent/${TalentToDelete}`,{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

        }
      });
      fetchTalents();
      setMessage("Talent deleted successfully");
      setSeverity("success");
      setAlertOpen(true);
      handleCloseDeleteDialog();
    } catch (error) {
      setMessage("Error deleting Talent: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };


  const columns = [
    { field: 'full_name', headerName: 'Full Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'school_name', headerName: 'School Name', width: 150 },
    { field: 'school_grade', headerName: 'Grade', width: 100 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'talent_category', headerName: 'Talent Category', width: 150 },
    { field: 'presentation_type', headerName: 'Presentation Type', width: 150 },
    {
      field: 'team_members',
      headerName: 'Team Members',
      width: 150,
      renderCell: (params) => {
        const teamMembers = params.value;
        
        // Check if the array exists and the first item is not an empty string
        if (teamMembers && teamMembers[0] !== "") {
          return (
            <IconButton onClick={() => handleTeamClick(teamMembers)}>
              <Diversity3Icon />
            </IconButton>
          );
        }
        
        // Return null or an empty fragment if the first item is empty
        return null;
      }
    }
    ,    { field: 'teacher_name', headerName: 'Teacher Name', width: 150 },
    { field: 'teacher_mobile', headerName: 'Teacher Mobile', width: 150 },
    {
      field: 'additional_details',
      headerName: 'Additional Details',
      width: 200,
      renderCell: (params) => {
        const details = params.value;
    
        // Check if the field is not empty
        if (details && details.trim() !== "") {
          return (
            <IconButton
              onClick={() => handleCommentClick(details)} 
              variant="outlined"
            >
              <CommentIcon />
            </IconButton>
          );
        }
    
        // Return null or an empty fragment if the field is empty
        return null;
      }
    },
    
      {
        field: 'link',
        headerName: 'Image',
        width: 150,
        renderCell: (params) => (
          <>
            <IconButton onClick={() => handleImageClick(params.value)}>
              <Avatar
                alt={params.row.full_name}
                src={params.value}
                sx={{ cursor: 'pointer',width: 56, height: 56  // Ensure the Avatar is centered vertically
               }}
              />
            </IconButton>
            <IconButton href={params.value} download>
              <DownloadIcon />
            </IconButton>
          </>
        )
      },
    { field: 'video_link', headerName: 'Video Link', width: 200,
    renderCell: (params) => (
        <IconButton href={params.value} target="_blank" rel="noopener noreferrer">
          <LinkIcon />
        </IconButton>
      ),
     },
    {
        field: 'comment',
        headerName: 'Comment',
        width: 150,
        renderCell: (params) => (
            <IconButton
    onClick={() => handleCommentClick(params.value)} variant="outlined">
     <CommentIcon/>
    </IconButton>

  )
      },     {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        params.value === '0' ? (
          <CancelIcon style={{ color: 'red' }} />
        ) : (
          <CheckCircleIcon style={{ color: 'green' }} />
        )
      )
    },
    {
      field: 'actions',
      headerName: 'Tools',
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(params.row._id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <div>
      <Box sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Talent</InputLabel>
              <Select
                label="Talent"
                value={filterTalent}
                onChange={handleFilterChange(setFilterTalent)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Singing">Singing</MenuItem>
                      <MenuItem value="Theater Performance">Theater Performance</MenuItem>
                      <MenuItem value="Music">Music</MenuItem>
                      <MenuItem value="Art">Art</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={filterStatus}
                onChange={handleFilterChange(setFilterStatus)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="0">Not Approved</MenuItem>
                <MenuItem value="1">Approved</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'right' }}>
            <Button variant="contained" color="success" onClick={handleExportToExcel} sx={{ width: '100%' }}>
              Export to Excel
            </Button>
          </Grid>
        </Grid>
      </Box>
        <Box sx={{ width: '100%' }}>
          <Collapse in={alertOpen}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {message}
            </Alert>
          </Collapse>
        </Box>
        {progress && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={filteredTalents}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            getRowId={(row) => row._id}
            rowHeight={60}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                position: 'sticky',
                top: 0,
                zIndex: 1,
              },
            }}
          />
        </Box>
        {currentTalent && (
          <EditTalentModal
          open={editOpen}
          handleClose={handleEditClose}
          currentTalent={currentTalent}
          setCurrentTalent={setCurrentTalent}
          handleEditSubmit={handleEditSubmit}
          handlePhotoUpload={handlePhotoUpload}
          photo={photo}
          progress={progress}
          memberName={memberName}
          setMemberName={setMemberName}
          handleKeyPress={handleKeyPress}
          handleAddMember={handleAddMember}
          handleDeleteMember={handleDeleteMember}
        />
        )}
        <Modal
          open={imageModalOpen}
          onClose={handleImageModalClose}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              overflowY: 'auto',
            maxHeight: '90%',
            }}
          >
            <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </Box>
        </Modal>
        <Modal open={teamModalOpen} onClose={handleTeamModalClose}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '90%',
          }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Team Members
            </Typography>
            <ul>
              {CurrentTeam?.map((member, index) => (
                <li key={index}>{member}</li>
              ))}
            </ul>
          </Box>
        </Modal>
        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="body1">{selectedComment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Talent"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this Talent? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button color="error"  onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default GotTalentPage;
