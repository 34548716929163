import React, { useState } from 'react';
import {
  Box, Button, Modal, TextField, Typography, FormControl, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Slide
} from '@mui/material';

import axios from 'axios';
import dayjs from 'dayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
  overflowY: 'auto',
};

const dialogStyle = {
  zIndex: 1400, // Ensure the dialog is above the modal
};

const AddNewsModal = ({ open, handleClose, fetchNews, setAlertOpen, setMessage, setSeverity }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [URL, setURL] = useState('');
  const [photo, setPhoto] = useState(null);
  const [error, setError] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      if (!photo) {
        setError('Image cannot be left empty. upload a picture please!');
      return;

      }
      setProgress(true);
      const formData = new FormData();
      formData.append('name', description);
      formData.append('status', "0");
      formData.append('photo', photo);
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }

      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/addPartner', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

        }
      });

      if (response.status === 201) {
        fetchNews();
        handleCloseModal();
        setMessage("Partner Added successfully");
        setSeverity("success");
        setAlertOpen(true);
      } else {
        handleCloseModal();
        setMessage("Error adding Partner");
        setSeverity("error");
        setAlertOpen(true);
      }
    } catch (error) {
      handleCloseModal();
      setMessage("Error adding Partner");
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png','image/webp'];
      if (!validImageTypes.includes(file.type)) {
        handleOpenDialog();
        setPhoto(null);
        return;
      }
       // Check if the file size exceeds 2MB (for example)
    const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSizeBytes) {
      setError('File size exceeds the limit of 2MB. Please choose a smaller file.');
      setPhoto(null);
      return;
    }
      const extension = file.name.split('.').pop();
      const newName = `edutech_partner_${Date.now()}.${extension}`;
      const renamedFile = new File([file], newName, { type: file.type });
      setPhoto(renamedFile);
    }
  };

  const handleCloseModal = () => {
    setTitle('');
    setDescription('');
    setDate('');
    setURL('');
    setError("");
    setPhoto(null);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={dialogStyle}
      >
        <DialogTitle>{"Invalid Image Format"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please upload a valid image file (JPEG, PNG, WEBP).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Modal
  open={open}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
  sx={{ zIndex: 1300 }} // Ensure the modal is below the dialog
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: 600,
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      overflowY: 'auto',
      maxHeight: '85%',
    }}
  >
    <Typography
      id="modal-title"
      variant="h5"
      component="h2"
      sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
    >
      Add New Partner
    </Typography>

    <form encType="multipart/form-data" onSubmit={handleSubmit}>

      <FormControl fullWidth margin="normal" variant="outlined">
        <TextField
          label="Name"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={4}
          variant="outlined"
        />
      </FormControl>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="upload-photo"
        type="file"
        onChange={handlePhotoUpload}
      />
      <label htmlFor="upload-photo">
        <Button
          startIcon={<CloudUploadIcon />}
          variant="outlined"
          component="span"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            fontWeight: 'bold',
            borderColor: 'primary.main',
            color: 'primary.main',
          }}
        >
          Upload Photo
        </Button>
      </label>
      {photo && <Typography sx={{ mt: 1, color: 'text.secondary' }}>{photo.name}</Typography>}
      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
      >
        Add Partner
      </Button>

      {progress && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
    </form>
  </Box>
</Modal>

    </div>
  );
};

export default AddNewsModal;
