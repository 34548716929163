import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemSecondaryAction,
  IconButton,
  Slide,
  Collapse
} from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash'; // Import lodash
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
const ViewAndUpdateCarts = () => {
  const [carts, setCarts] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [currentCart, setCurrentCart] = useState(null);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialCart, setInitialCart] = useState(null); // New state to hold initial event data
  const [checkboxChanged, setCheckboxChanged] = useState(false); // New state to track checkbox changes
  const [progress, setProgress] = useState(false);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  useEffect(() => {
    fetchCarts();
    fetchAvailableEvents();
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getproducts');
      setProducts(response.data);
    } catch (error) {
      setMessage("Error fetching products" + {error});
      setSeverity("error")
      setAlertOpen(true);      }
  };

  const fetchCarts = async () => {
    setProgress(true);

    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getcarts',{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

        },
      });
      setCarts(response.data.reverse());
    } catch (error) {
      setMessage("Error fetching carts" + {error});
      setSeverity("error")
      setAlertOpen(true);      }
      setProgress(false);

  };

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setAvailableEvents(filteredEvents);
    } catch (error) {
      setMessage("Error fetching events" + {error});
      setSeverity("error")
      setAlertOpen(true);      }
  };

  const handleEditOpen = (cart) => {
    setCurrentCart(cart);
    setInitialCart(cart);
    setCheckboxChanged(false); // Reset checkbox changed state
    setEditOpen(true);
  };

  const handleViewOpen = (cart) => {
    setCurrentCart(cart);

    setViewOpen(true);
  };

  const handleEditClose = () => {
    setCurrentCart(null);
    setInitialCart(null);

    setEditOpen(false);
  };

  const handleViewClose = () => {
    setCurrentCart(null);
    setViewOpen(false);
  };

  const handleInputChange = (index, field) => (event) => {
    const { value } = event.target;
    setCurrentCart(prevCart => {
      const updatedCart = _.cloneDeep(prevCart);
      updatedCart.cart[index][field] = value;
      return updatedCart;
    });
  };

  const handleProductCheckboxChange = (index) => (event) => {
    const { checked } = event.target;
    setCurrentCart(prevCart => {
      const updatedCart = _.cloneDeep(prevCart);
      updatedCart.cart[index].done = checked;
      return updatedCart;
    });
    setCheckboxChanged(true); // Mark checkbox as changed
  };

  const handleUpdateCart = async () => {

    const hasChanged = !_.isEqual(initialCart, currentCart) || checkboxChanged; // Include checkboxChanged in the comparison

    if (hasChanged) {
      setProgress(true);
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const { _id, info, status, cart } = currentCart;
      const response = await axios.put('https://edutech-backend-471f7cb69116.herokuapp.com/api/updatecart', { id: _id, info, status, cart },{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

      }
      });

      if (response.status === 200) {
        setCarts(carts.map(cart => cart._id === _id ? { ...cart, info, status, cart: currentCart.cart } : cart));
        setMessage("cart updated Successfully");
        setSeverity("success")
        setAlertOpen(true);
        handleEditClose();
      }
    } catch (error) {
      setMessage("Error updating cart" + {error});
      setSeverity("error")
      setAlertOpen(true);     }
      setProgress(false);

    }else{
      handleEditClose();
    }
  };

  const getEventName = (eventId) => {
    const event = availableEvents.find(event => event.id === parseInt(eventId, 10));
    return event ? event.name : 'Unknown Event';
  };

  const getStatus = (deleted) => {
    if (deleted !== "1") {
      return 'ToDo';
    }
    return "Done";
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  // Filter carts to include only those with eventId in availableEvents
  const filteredCarts = carts.filter(cart =>
    availableEvents.some(event => event.id === parseInt(cart.eventID, 10)) &&
    (!selectedEvent || parseInt(cart.eventID, 10) === selectedEvent) &&
    (!selectedStatus || getStatus(cart.status) === selectedStatus)
  );

  const handleDownloadOrder = (cart) => {
    const doc = new jsPDF();
    const event = getEventName(cart.eventID);
  
    doc.setFontSize(18);
    doc.text('Order Details', 105, 20, null, null, 'center');
  
    doc.setFontSize(12);
    doc.text('User Information', 10, 70);
    doc.setFontSize(10);
    doc.text(`Name: ${cart.userInfo.name}`, 10, 80);
    doc.text(`Email: ${cart.userInfo.email}`, 10, 90);
    doc.text(`Institution: ${cart.userInfo.institution}`, 10, 100);
    doc.text(`Phone Number: ${cart.userInfo.phoneNumber}`, 10, 110);
  
    doc.setFontSize(12);
    doc.text('Event and Comment', 10, 120);
    doc.setFontSize(10);
    doc.text(`Event: ${event}`, 10, 130);
    doc.text(`Equires: ${cart.comment}`, 10, 140);
  
    const rows = [];
    cart.cart.forEach((item) => {
      const product = products.find((p) => p._id === item.productID);
      const customizationOptions = item.customizationOptions ? JSON.parse(item.customizationOptions) : {};
      const status = item.done ? 'Done' : 'ToDo';
      const customizationText = item.customizationText;

      rows.push([
        product ? product.name : 'Unknown Product',
        item.quantity,
        customizationOptions.logo ? 'Yes' : 'No',
        customizationOptions.text ? 'Yes' : 'No',
        customizationText,
        status,
      ]);
    });
  
    doc.autoTable({
      head: [['Product', 'Quantity', 'Add Logo', 'Add Text', 'Customize Text', 'Status']],
      body: rows,
      startY: 150,
      theme: 'grid',
      headStyles: { fillColor: [22, 160, 133] },
    });
  
    doc.save(`Order_${cart._id}.pdf`);
  };
  const rows = filteredCarts.map((cart) => ({
    id: cart._id,
    name: cart.userInfo.name,
    email: cart.userInfo.email,
    institution: cart.userInfo.institution,
    logo: products.find((product) => product._id === cart.cart[0].productID)?.logo || '',
    status: getStatus(cart.deleted),
    ...cart,
  }));

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'institution', headerName: 'Institution', flex: 1 },
    {
      field: 'link',
      headerName: 'Logo',
      flex: 0.5,
      renderCell: (params) => <Avatar alt="Product Logo" src={params.value} />,
    },
    { field: 'status', headerName: 'Status', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleViewOpen(params.row)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton  onClick={() => handleDownloadOrder(params.row)}>
            <DownloadIcon />
          </IconButton>
          <IconButton  onClick={() => handleEditOpen(params.row)}>
            <ModeEditIcon />
          </IconButton>
        </div>
      ),
    },
  ];


  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ width: '100%' }}>
      <Collapse in={alertOpen}>
        <Alert
        severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
      <Typography variant="h4" gutterBottom>Orders</Typography>

      <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', padding: 2, backgroundColor: 'white', borderRadius: '8px', boxShadow: 1,  marginBottom: 2 }}>
      <FormControl variant="outlined" sx={{ minWidth: 240 }}>
        <InputLabel>Filter by Event</InputLabel>
        <Select
          value={selectedEvent}
          onChange={handleEventChange}
          label="Filter by Event"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {availableEvents.map(event => (
            <MenuItem key={event.id} value={event.id}>
              {event.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ minWidth: 240, marginBottom: 2 }}>
        <InputLabel>Filter by Status</InputLabel>
        <Select
          value={selectedStatus}
          onChange={handleStatusChange}
          label="Filter by Status"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="ToDo">ToDo</MenuItem>
          <MenuItem value="Done">Done</MenuItem>
        </Select>
      </FormControl>
    </Box>
      {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}

<Box sx={{ height: 400, width: '100%' }}>
        <DataGrid 
         rows={rows}
          columns={columns}
           pageSize={5}
            checkboxSelection
            getRowId={(row) => row._id}
            rowHeight={60} // Increase row height here
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
             />
      </Box>

      {currentCart && (
        <Modal open={editOpen} onClose={handleEditClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            borderRadius: 2, // Rounded corners
            boxShadow: 10, // Elevated shadow
            p: 4,
            maxHeight: '85%',
            overflowY: 'auto',
            transition: 'all 0.3s ease', // Smooth transition
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
            sx={{ color: 'primary.main' }}
          >
            Edit Cart
          </Typography>
          <form>
            <TextField
              label="Add Note"
              value={currentCart.info || ''}
              onChange={(event) =>
                setCurrentCart({ ...currentCart, info: event.target.value })
              }
              fullWidth
              multiline
              rows={6}
              margin="normal"
              variant="outlined"
              sx={{
                bgcolor: 'grey.100',
                borderRadius: 1,
              }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                value={currentCart.status || ''}
                onChange={(event) =>
                  setCurrentCart({ ...currentCart, status: event.target.value })
                }
                label="Status"
                variant="outlined"
                sx={{
                  bgcolor: 'grey.100',
                  borderRadius: 1,
                }}
              >
                <MenuItem value="0">ToDo</MenuItem>
                <MenuItem value="1">Done</MenuItem>
              </Select>
            </FormControl>
            <Typography
              variant="h6"
              component="h3"
              fontWeight="bold"
              gutterBottom
              sx={{ mt: 3, color: 'text.secondary' }}
            >
              Products
            </Typography>
            <List>
              {currentCart.cart.map((item, index) => (
                <ListItem key={index} sx={{ pl: 0 }}>
                  <ListItemText
                    primary={
                      products.find((p) => p._id === item.productID)?.name ||
                      'Unknown Product'
                    }
                    secondary={`Quantity: ${item.quantity}`}
                    primaryTypographyProps={{ fontWeight: 'medium' }}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={handleProductCheckboxChange(index)}
                      checked={item.done || false}
                      sx={{ color: 'primary.main' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Button
              onClick={handleUpdateCart}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 4, py: 1.5, fontWeight: 'bold', fontSize: '1rem' }}
            >
              Save Changes
            </Button>
          </form>
        </Box>
      </Modal>
      
      )}

      {currentCart && (
        <Modal open={viewOpen} onClose={handleViewClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 10,
            p: 4,
            maxHeight: '85%',
            overflowY: 'auto',
            transition: 'all 0.3s ease',
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
            sx={{ color: 'primary.main' }}
          >
            Cart Details
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary' }}>
            Equires: {currentCart.comment || "None"}
          </Typography>
          <List sx={{ mt: 2 }}>
            {currentCart.cart.map((item, index) => {
              const product = products.find((p) => p._id === item.productID);
              const customizationOptions = item.customizationOptions ? JSON.parse(item.customizationOptions) : {};
              const status = item.done ? 'Done' : 'ToDo';
      
              return (
                <ListItem
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 2,
                    p: 2,
                    bgcolor: 'grey.100',
                    borderRadius: 1,
                  }}
                >
                  <Avatar
                    alt={product ? product.name : 'Unknown Product'}
                    src={product ? product.link : ''}
                    sx={{ width: 56, height: 56, mr: 2 }}
                  />
                  <ListItemText
                    primary={product ? product.name : 'Unknown Product'}
                    primaryTypographyProps={{ fontWeight: 'bold' }}
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="text.secondary">
                          {`Quantity: ${item.quantity}`}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="text.secondary">
                          {customizationOptions.logo ? 'Add Logo: Yes' : 'Add Logo: No'}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="text.secondary">
                          {customizationOptions.text ? `Customization Text: ${customizationOptions.text}` : 'Add Text: No'}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="text.secondary">
                          {`Status: ${status}`}
                        </Typography>
                        <br />
                        {product && (
                          <Typography component="span" variant="body2" color="text.secondary">
                            {`Description: ${product.description}`}
                          </Typography>
                        )}
                      </>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
          {currentCart.link && (
            <Button
              onClick={() => {
                const link = document.createElement('a');
                link.href = currentCart.link;
                link.download = 'logo.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
            >
              Download Logo
            </Button>
          )}
          <Button
            onClick={handleViewClose}
            variant="contained"
            fullWidth
            sx={{ mt: 2, py: 1.5, fontWeight: 'bold' }}
          >
            Close
          </Button>
        </Box>
      </Modal>
      
      )}

    </Box>
  );
};

export default ViewAndUpdateCarts;
