// EditNewsModal.js
import React from 'react';
import { Modal, Box, Typography, FormControl, TextField, Button, LinearProgress, Grid } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import dayjs from 'dayjs';

const EditNewsModal = ({
  editOpen,
  handleEditClose,
  currentNews,
  setCurrentNews,
  handleEditSubmit,
  handlePhotoUpload,
  photo,
  progress
}) => {
  return (
    <Modal
      open={editOpen}
      onClose={handleEditClose}
      aria-labelledby="edit-news-title"
      aria-describedby="edit-news-description"
      sx={{ zIndex: 1300 }} // Ensure the modal is above other content
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          maxHeight: '85%',
        }}
      >
        <Typography
          id="edit-news-title"
          variant="h5"
          component="h2"
          sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
        >
          Edit News
        </Typography>

        <form onSubmit={handleEditSubmit}>
          {/* Title Input */}
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Title"
              value={currentNews.title}
              onChange={(e) => setCurrentNews({ ...currentNews, title: e.target.value })}
              fullWidth
              required
            />
          </FormControl>

          {/* Description Input */}
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Description"
              value={currentNews.body}
              onChange={(e) => setCurrentNews({ ...currentNews, body: e.target.value })}
              fullWidth
              multiline
              rows={4}
              required
            />
          </FormControl>

          {/* Date Input */}
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Date"
              type="date"
              value={dayjs(currentNews.date).format('YYYY-MM-DD')}
              onChange={(e) => setCurrentNews({ ...currentNews, date: e.target.value })}
              fullWidth
              InputLabelProps={{ shrink: true }}
              required
            />
          </FormControl>

          {/* URL Input */}
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="URL"
              value={currentNews.link}
              onChange={(e) => setCurrentNews({ ...currentNews, link: e.target.value })}
              fullWidth
              required
            />
          </FormControl>

          {/* Image Input (disabled) */}
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Image"
              value={currentNews.image}
              fullWidth
              disabled
            />
          </FormControl>

          {/* File Upload Section */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-photo"
                type="file"
                onChange={handlePhotoUpload}
              />
              <label htmlFor="upload-photo">
                <Button
                  startIcon={<CloudUploadIcon />}
                  variant="outlined"
                  component="span"
                  fullWidth
                  sx={{
                    mt: 2,
                    py: 1.5,
                    fontWeight: 'bold',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                  }}
                >
                  Change Image
                </Button>
              </label>
              {photo && <Typography sx={{ mt: 1, color: 'text.secondary' }}>{photo.name}</Typography>}
            </Grid>
          </Grid>

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
          >
            Save Changes
          </Button>

          {/* Progress Bar */}
          {progress && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress />
            </Box>
          )}
        </form>
      </Box>
    </Modal>
  );
};

export default EditNewsModal;
