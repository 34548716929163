import React, { useRef,useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import Products from '../components/Products/Products';
import Carts from '../components/Carts/CartPage';
import Overview from '../components/Home';
import Events from '../components/Event/Events';
import Exhibitors from '../components/Exhibitors';
import OrderManagement from '../components/OrderManagement';
import Sponsors from '../components/Sponsors/Sponsors';
import Stage_check from '../components/Stage/stage_check';
import SchoolManagement from '../SchoolComp/SchoolManagement';
import News from '../components/News/News';
import Rating from '../components/Rating';
import Generate from '../components/Stage/Generate';
import GenerateEdutech from '../EDUTECH/GenerateEdutech';
import Feedback from '../components/Survey/Feedback';
import Survey from '../components/Survey/SurveyTrack';
import EnquireTrack from '../components/Enquiry/EnquireTrack';
import EnquireTrackEdutech from '../EDUTECH/EnquireTrackEdutech';
import Hotel from '../components/Hotel/Hotel';
import Avatar from '@mui/material/Avatar';
import SettingsModal from './SettingsModal';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationMenu from '../components/Notifications/NotificationMenu';
import Offers from '../components/Offers/Offers';
import GotTalentManagment from '../components/Got-Talent/GotTalentManagment';
import NotificationForm from '../components/Notifications/NotificationForm';
import ReportProblem from '../components/ReportProblem';
import MiningView from '../miningtech/MiningView'
import AgritechView from '../Agritech/AgritechView'
import EdutechView from '../EDUTECH/EdutechView'
import EdutechViewForum from '../EDUTECH/EdutechViewForum'
import AgritechForumView from '../Agritech/AgritechForumView'
import ProblemView from '../components/ProblemView'
import Stage_Check_Edutech from '../EDUTECH/Stage_Check_Edutech'
import EDUTECHVideo from '../EDUTECH/EDUTECHVideo'
import SpeakersEdutechForum from '../EDUTECH/SpeakersEdutech'
import EdutechOfficialPartners from '../EDUTECH/EdutechOfficialPartners'
import TransferredParticipants from '../eduventure/TransferredParticipants'
import EdutechPartners from '../EDUTECH/EdutechSponsors'
import AgendaManagementEdutech from '../EDUTECH/AgendaManagementEdutech'
import EdutechPublicInfo from '../EDUTECH/EdutechPublicInfo'
import StudentServices from '../eduventure/studentServices';
import ControlForms from '../components/ControlForms';
import ContactEduventure from '../eduventure/ContactEduventure';
import ArchivedData from '../eduventure/ArchivedData';
import DrawerComp from './Drawer_comp'
import AuthScreen from "./AuthScreen";


const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    },
    secondary: {
      main: "#000000"
    }
  }
});


const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(() => sessionStorage.getItem('selectedComponent') || '');
  const [username, setUsername] = useState(() => sessionStorage.getItem('username') || null);
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(() => sessionStorage.getItem('authenticated') === 'true');
  const [level1, setLevel1] =  useState(() => sessionStorage.getItem('level1') === 'true');
  const [level2, setLevel2] =  useState(() => sessionStorage.getItem('level2') === 'true');
  const [level3, setLevel3] =  useState(() => sessionStorage.getItem('level3') === 'true');
  const [level4, setLevel4] =  useState(() => sessionStorage.getItem('level4') === 'true');
  const [level5, setLevel5] =  useState(() => sessionStorage.getItem('level5') === 'true');
  const [level6, setLevel6] =  useState(() => sessionStorage.getItem('level6') === 'true');
  const [level7, setLevel7] =  useState(() => sessionStorage.getItem('level7') === 'true');

  const [notifications, setNotifications] = useState([]);
  const [expandedSection, setExpandedSection] = React.useState(null);
const handleExpandClick = (section) => {
  setExpandedSection(expandedSection === section ? null : section);
};
  useEffect(() => {
    // Retrieve notifications from sessionStorage
    const storedNotifications = sessionStorage.getItem('notifications');

    // Parse the stored data
    const parsedNotifications = storedNotifications
      ? JSON.parse(storedNotifications) // For complex data
      : []; // For simple data, use .split(',') instead

    setNotifications(parsedNotifications);
  }, []);
  const [isAnimating, setIsAnimating] = useState(false);
  const [name, setName] = useState(() => sessionStorage.getItem('name') || '');
  const [avatar, setAvatar] = useState(() => sessionStorage.getItem('avatar') || '');
  const sessionTimeoutRef = useRef(null);


  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const handleSettingsOpen = () => {
    setSettingsModalOpen(true);
  };
  
  const handleSettingsClose = () => {
    setSettingsModalOpen(false);
  };

  const [DialogMessage, setDialogMessage] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  
  // Update user information
  const updateUser = (updatedUser) => {
    setName(updatedUser.name);
    setAvatar(updatedUser.avatar);
    sessionStorage.setItem('name', updatedUser.name);
    sessionStorage.setItem('avatar', updatedUser.avatar);


  };
  

  const [levelnum, setLevelnum] =  useState(() => sessionStorage.getItem('levelnum') || null);
  const [MAX_SELECTION_LIMIT, setMAX_SELECTION_LIMIT] =  useState(() => sessionStorage.getItem('MAX_SELECTION_LIMIT') || null);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  useEffect(() => {
    if (authenticated) {
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('authenticated', 'true');
      sessionStorage.setItem('level1', level1);
      sessionStorage.setItem('level2', level2);
      sessionStorage.setItem('level3', level3);
      sessionStorage.setItem('level4', level4);
      sessionStorage.setItem('level5', level5);
      sessionStorage.setItem('level6', level6);
      sessionStorage.setItem('level7', level7);
      sessionStorage.setItem('MAX_SELECTION_LIMIT', MAX_SELECTION_LIMIT);
      sessionStorage.setItem('levelnum', levelnum);
      sessionStorage.setItem('name', name);
      sessionStorage.setItem('avatar', avatar);
      sessionStorage.setItem('notifications', JSON.stringify(notifications));



      startSessionTimeout();
       // Add event listeners to track user activity
       document.addEventListener('click', resetSessionTimeout);
       document.addEventListener('keypress', resetSessionTimeout);
       document.addEventListener('mousemove', resetSessionTimeout);
    } else {
      clearSessionData();

    }
    return () => {
      // Clean up event listeners on unmount or logout
      document.removeEventListener('click', resetSessionTimeout);
      document.removeEventListener('keypress', resetSessionTimeout);
      document.removeEventListener('mousemove', resetSessionTimeout);
      clearTimeout(sessionTimeoutRef.current);
    };
  }, [authenticated, level1, level2, level3, level4, level5,level6,level7, levelnum,name,avatar,username,notifications]);
  

  const startSessionTimeout = () => {
    clearTimeout(sessionTimeoutRef.current);
    sessionTimeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
  };
  const resetSessionTimeout = () => {
    startSessionTimeout();
  };
  const clearSessionData = () => {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('authenticated');
    sessionStorage.removeItem('level1');
    sessionStorage.removeItem('level2');
    sessionStorage.removeItem('level3');
    sessionStorage.removeItem('level4');
    sessionStorage.removeItem('level5');
    sessionStorage.removeItem('level6');
    sessionStorage.removeItem('level7');
    sessionStorage.removeItem('levelnum');
    sessionStorage.removeItem('selectedComponent');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('avatar');
    sessionStorage.removeItem('notifications');

    clearTimeout(sessionTimeoutRef.current);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }

  }

  const handleLogout = () => {
    setAuthenticated(false);
    clearSessionData();
    handleOpenDialog();
    setIsAnimating(false)
    setDialogMessage("You have been logged out.");
  };

  const logout = () => {
    setIsAnimating(false)
    setAuthenticated(false);
    clearSessionData();
    handleOpenDialog();
    setDialogMessage("You have been logged out for not using the app.");
  };
  const handleListItemClick = (componentName) => {
    setSelectedComponent(componentName);
    sessionStorage.setItem('selectedComponent', componentName);
    handleDrawerClose();
    resetSessionTimeout(); // Reset session timeout on interaction
  };


  const handleLogin = () => {
    setIsAnimating(true);
    const lowercaseUsername = username.toLowerCase(); // Convert username to lowercase
  
    axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/loginedu', { username: lowercaseUsername, password })
      .then(response => {
        if (response.data.authenticated) {
          localStorage.setItem('token', response.data.token); // Store the token in localStorage
  
          // Proceed with the rest of the state updates
          setAuthenticated(true);
          setName(response.data.name);
  
          if (response.data.level === "2") {
            setLevel2(true);
          }
          if (response.data.level === "3") {
            setLevel3(true);
          }
          if (response.data.level === "4") {
            setLevel4(true);
          }
          if (response.data.level === "5") {
            setLevel5(true);
          }
          if (response.data.level === "6") {
            setLevel6(true);
          }
          if (response.data.level === "7") {
            setLevel7(true);
            setMAX_SELECTION_LIMIT(response.data.max_export);
          } else {
            setLevel1(true);
            setMAX_SELECTION_LIMIT(response.data.max_export);
          }
  
          setLevelnum(response.data.level);
          sessionStorage.setItem('levelnum', response.data.level);
          setAvatar(response.data.image);
  
          setNotifications(response.data.notifications.filter(noti => noti.status === "not done"));
          startSessionTimeout();
        } else {
          handleOpenDialog();
          setDialogMessage("Incorrect username or password");
        }
      })
      .catch(error => {
        handleOpenDialog();
        setDialogMessage("An error occurred while logging in. Please try again later.");
      });
  };
  
  
  
  if (!authenticated) {
      return (
        <AuthScreen
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          DialogMessage={DialogMessage}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
          isAnimating={isAnimating}
        />
      );
  }
  
  

  const container = window !== undefined ? () => window().document.body : undefined;
  const getDefaultComponent = () => {
    if (sessionStorage.getItem('levelnum') === "2") {
      return 'Speakers'; // If level is 2, default component is Speakers
    } else if (sessionStorage.getItem('levelnum') === "3") {
      return 'Hotel'; // If level is 3, default component is Hotel
      
    }else if (sessionStorage.getItem('levelnum') === "4") {
      return 'Carts'; // If level is 3, default component is Hotel
    }  
    else if (sessionStorage.getItem('levelnum') === "5") {
      return 'School Management'; // If level is 3, default component is Hotel
    }  
    else if (sessionStorage.getItem('levelnum') === "6") {
      return 'Sponsors'; // If level is 3, default component is Hotel
    }  
    else if (sessionStorage.getItem('levelnum') === "7") {
      return 'TransferredParticipants'; // If level is 3, default component is Hotel
    }  
    else {
      return 'Overview'; // Default to Home if level is not set
    }
  };
  
  
  const renderSelectedComponent = () => {
    const defaultComponent = getDefaultComponent(); // Use any of the levels
    if(!selectedComponent){
      handleExpandClick('EDUGATE');
        handleListItemClick(defaultComponent);
    }

    switch (selectedComponent || defaultComponent) {
          case 'Overview':
          return <Overview />;
          case 'Events':
          return <Events />;
          case 'Exhibitors':
          return <Exhibitors />;
          case 'Registrations':
          return <OrderManagement />;
          case 'Sponsors':
          return <Sponsors />;
          case 'Speakers':
          return <Stage_check />;
          case 'SpeakersEdutech':
          return <Stage_Check_Edutech />;
          case 'EDUTECHVideo':
          return <EDUTECHVideo />;
          case 'News':
          return <News />;
          case 'NotificationForm':
          return <NotificationForm />;
          case 'Rating':
          return <Rating />;
          case 'Generate':
          return <Generate />; 
          case 'GotTalentManagment':
          return <GotTalentManagment />;
          case 'Hotel':
          return <Hotel />;
          case 'Survey':
          return <Survey />;
          case 'Feedback':
          return <Feedback />;
          case 'Enquiry':
          return <EnquireTrack />;
          case 'EnquiryEdutech':
          return <EnquireTrackEdutech />;
          case 'Products':
          return <Products />;
          case 'Carts':
          return <Carts />;
          case 'School Management':
          return <SchoolManagement />;
          case 'Offers':
          return <Offers />;
          case 'MiningView':
          return <MiningView />;
          case 'AgritechView':
          return <AgritechView />;  
          case 'EdutechView':
          return <EdutechView />;  
          case 'EdutechViewForum':
          return <EdutechViewForum />;  
          case 'AgritechForumView':
          return <AgritechForumView />;  
          case 'GenerateEdutech':
          return <GenerateEdutech />;  
          case 'ProblemView':
          return <ProblemView />;  
          case 'ControlForms':
          return <ControlForms />;  
          case 'SpeakersEdutechForum':
          return <SpeakersEdutechForum />;  
          case 'EdutechOfficialPartners':
          return <EdutechOfficialPartners />;  
          case 'EdutechPartners':
          return <EdutechPartners />;  
          case 'AgendaManagementEdutech':
          return <AgendaManagementEdutech />;  
          case 'EdutechPublicInfo':
          return <EdutechPublicInfo />;  
          case 'TransferredParticipants':
          return <TransferredParticipants />;  
          case 'StudentServices':
          return <StudentServices />;  
          case 'ArchivedData':
          return <ArchivedData />;  
          case 'ContactEduventure':
          return <ContactEduventure />;  
          default: 
            return <Overview />; 
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            background: 'linear-gradient(to right, #ff512f, #dd2476)',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, 0.3)',
            borderRadius: '10px',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              <DashboardIcon /> Dashboard
            </Typography>
            <Typography variant="h6" noWrap component="div">
              Welcome, {name}!
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title="Notifications">
  <IconButton
    color="inherit"
    aria-label="notifications"
    sx={{
      mr: 2,
      position: 'relative',
      zIndex: 1200,
      display: { xs: 'none', sm: 'flex' }, // Hide on small screens
    }}
  >
    <NotificationMenu notifications={notifications} username={sessionStorage.getItem('username')} />
  </IconButton>
</Tooltip>

            <Tooltip title="Report a Problem">
              <IconButton
                color="inherit"
                aria-label="report-problem"
                sx={{
                  mr: 2,
                  position: 'relative',
                  zIndex: 1200,
                }}
              >
          <ReportProblem dynamicNumber="Dashboard" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Settings">
              <IconButton
                color="inherit"
                aria-label="settings"
                onClick={handleSettingsOpen}
                edge="end"
                sx={{ mr: 2 }}
              >
                <Avatar
                  alt={name}
                  src={avatar}
                  sx={{
                    width: 40,
                    height: 40,
                    border: '2px solid white',
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton
                color="inherit"
                aria-label="logout"
                onClick={handleLogout}
                edge="end"
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
           <DrawerComp 
           level2={level2}
           level3={level3}
           level4={level4}
           level5={level5}
           level6={level6}
           level7={level7}
           handleListItemClick={handleListItemClick}
           selectedComponent={selectedComponent}
           handleExpandClick={handleExpandClick}
           expandedSection={expandedSection}
            />
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            <DrawerComp 
           level2={level2}
           level3={level3}
           level4={level4}
           level5={level5}
           level6={level6}
           level7={level7}
           handleListItemClick={handleListItemClick}
           selectedComponent={selectedComponent}
           handleExpandClick={handleExpandClick}
           expandedSection={expandedSection}
            />
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          {renderSelectedComponent()}
        </Box>
      </ThemeProvider>
      <SettingsModal
        open={settingsModalOpen}
        handleClose={handleSettingsClose}
        user={{ name, username, avatar, levelnum }}
        updateUser={updateUser}
      />
    </Box>
  );
}  
ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;