// EditEventModal.js
import React from 'react';
import {
  Modal, Box, Typography, FormControl, TextField, InputLabel, Select, MenuItem, Button, LinearProgress
} from '@mui/material';
import dayjs from 'dayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const EditEventModal = ({
  editOpen,
  handleEditClose,
  currentEvent,
  setCurrentEvent,
  handleEditSubmit,
  handlePhotoFairUpload,
  handlePhotoScheduleUpload,
  Fairphoto,
  Schedulephoto,
  error,
  progress
}) => {
  return (
    <Modal open={editOpen} onClose={handleEditClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '90%',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          width: 500,
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}
        >
          Edit Event
        </Typography>

        <form onSubmit={(e) => { e.preventDefault(); handleEditSubmit(currentEvent); }}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              value={currentEvent.name}
              onChange={(e) => setCurrentEvent({ ...currentEvent, name: e.target.value })}
              fullWidth
              variant="outlined"
              required
            />
          </FormControl>

          {/* Date and Time Inputs */}
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Start Date"
                type="date"
                value={dayjs(currentEvent.start_date).format('YYYY-MM-DD')}
                onChange={(e) => setCurrentEvent({ ...currentEvent, start_date: e.target.value })}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="End Date"
                type="date"
                value={dayjs(currentEvent.end_date).format('YYYY-MM-DD')}
                onChange={(e) => setCurrentEvent({ ...currentEvent, end_date: e.target.value })}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
              />
            </FormControl>
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Start Time"
                type="time"
                value={currentEvent.start_time}
                onChange={(e) => setCurrentEvent({ ...currentEvent, start_time: e.target.value })}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="End Time"
                type="time"
                value={currentEvent.end_time}
                onChange={(e) => setCurrentEvent({ ...currentEvent, end_time: e.target.value })}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
              />
            </FormControl>
          </Box>

          {/* Location Inputs */}
          <FormControl fullWidth margin="normal">
            <TextField
              label="Location Address"
              value={currentEvent.location_address}
              onChange={(e) => setCurrentEvent({ ...currentEvent, location_address: e.target.value })}
              fullWidth
              variant="outlined"
              required
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Google Embedded Address"
              value={currentEvent.embedded_map}
              onChange={(e) => setCurrentEvent({ ...currentEvent, embedded_map: e.target.value })}
              fullWidth
              variant="outlined"
              required
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Location URL"
              value={currentEvent.map}
              onChange={(e) => setCurrentEvent({ ...currentEvent, map: e.target.value })}
              fullWidth
              variant="outlined"
              required
            />
          </FormControl>

          {/* Stage and Talent Options */}
          <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
            <FormControl required fullWidth>
              <InputLabel>Stage</InputLabel>
              <Select
                label="Stage"
                value={currentEvent.has_stage}
                onChange={(e) => setCurrentEvent({ ...currentEvent, has_stage: e.target.value })}
              >
                <MenuItem value="1">Create Stage</MenuItem>
                <MenuItem value="0">No Stage</MenuItem>
              </Select>
            </FormControl>
            <FormControl required fullWidth>
              <InputLabel>Got Talent</InputLabel>
              <Select
                label="Got Talent"
                value={currentEvent.has_talent}
                onChange={(e) => setCurrentEvent({ ...currentEvent, has_talent: e.target.value })}
              >
                <MenuItem value="1">Create Got Talent</MenuItem>
                <MenuItem value="0">No Got Talent</MenuItem>
              </Select>
            </FormControl>
            <FormControl required fullWidth>
              <InputLabel>School Event</InputLabel>
              <Select
                label="School Event"
                value={currentEvent.is_school}
                onChange={(e) => setCurrentEvent({ ...currentEvent, is_school: e.target.value })}
              >
                <MenuItem value="1">Yes</MenuItem>
                <MenuItem value="0">No</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* File Uploads */}
          <Box sx={{ mb: 2 }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-photo_fair"
              type="file"
              onChange={handlePhotoFairUpload}
            />
            <label htmlFor="upload-photo_fair">
              <Button
                startIcon={<CloudUploadIcon />}
                variant="outlined"
                component="span"
                fullWidth
                sx={{ mt: 2, py: 1.5, fontWeight: 'bold', borderColor: 'primary.main' }}
              >
                Upload Fair Map
              </Button>
            </label>
            {Fairphoto && <Typography sx={{ mt: 1 }}>{Fairphoto.name}</Typography>}
          </Box>

          <Box sx={{ mb: 2 }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-photo_schedule"
              type="file"
              onChange={handlePhotoScheduleUpload}
            />
            <label htmlFor="upload-photo_schedule">
              <Button
                startIcon={<CloudUploadIcon />}
                variant="outlined"
                component="span"
                fullWidth
                sx={{ mt: 2, py: 1.5, fontWeight: 'bold', borderColor: 'primary.main' }}
              >
                Upload Fair Schedule
              </Button>
            </label>
            {Schedulephoto && <Typography sx={{ mt: 1 }}>{Schedulephoto.name}</Typography>}
          </Box>

          {/* Error Message */}
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
          >
            Submit
          </Button>

          {/* Progress Indicator */}
          {progress && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress />
            </Box>
          )}
        </form>
      </Box>
    </Modal>
  );
};

export default EditEventModal;
