import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Container, Grid, Card, CardMedia, CardContent, LinearProgress, Typography, Select, MenuItem, FormControl, InputLabel, OutlinedInput, Box, Button } from '@mui/material';
import { jsPDF } from "jspdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { styled } from '@mui/system';
import HelpButton from '../HelpButton';

const EventSchedulePage = () => {
  const [events, setEvents] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [allSpeakers, setAllSpeakers] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [progress, setProgress] = useState(false);
  const [dates, setDates] = useState([]);
  const [slots, setSlots] = useState([]);

  const StyledTitle = styled(Typography)(({ theme }) => ({
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: '2.5rem', // Adjust size as needed
    color: 'transparent',
    background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    position: 'relative',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: '#FF6347', // Accent color for underline
      transform: 'scaleX(0)',
      transformOrigin: 'bottom right',
      transition: 'transform 0.3s ease-out',
    },
    '&:hover::after': {
      transform: 'scaleX(1)',
      transformOrigin: 'bottom left',
    },
  }));
  // Fetch events and speakers
  useEffect(() => {
    const fetchData = async () => {
        setProgress(true);
      try {
        const eventResponse = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
        const filteredEvents = eventResponse.data.filter(event => event.has_stage === "1");
        setEvents(filteredEvents.reverse());

        const speakerResponse = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/stage_data');
        setAllSpeakers(speakerResponse.data.filter(speaker => speaker.status === "1"));
      } catch (error) {
      }
      setProgress(false);

    };

    fetchData();
  }, []);

  // Update dates and slots based on selected event
  useEffect(() => {
    if (selectedEvent) {
      const filteredSpeakers = allSpeakers.filter(speaker => speaker.event_id === selectedEvent);

      setDates(Array.from(new Set(filteredSpeakers.map(speaker => speaker.date))).sort());
      setSlots(Array.from(new Set(filteredSpeakers.map(speaker => speaker.slot))).sort());

      // Filter speakers based on selected date and time
      const filtered = filteredSpeakers.filter(speaker =>
        (!selectedDate || speaker.date === selectedDate) &&
        (!selectedTime || speaker.slot === selectedTime)
      );

      setSpeakers(filtered);
    } else {
      setDates([]);
      setSlots([]);
      setSpeakers([]);
    }
  }, [selectedEvent, selectedDate, selectedTime, allSpeakers]);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    setSelectedDate('');
    setSelectedTime('');
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000"
      }
    }
  });

  const getImageData = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;  // Handle read errors
        reader.readAsDataURL(blob);
    });
};

const generatePDF = async () => {
  try {
    const pdf = new jsPDF();

    // Load and add the logo
    const logoUrl = './edutech_logo.png'; // Update with actual logo path
    const response = await fetch(logoUrl);
    if (!response.ok) throw new Error('Failed to load logo');
    const logoBlob = await response.blob();
    const logoImageData = await getImageData(logoBlob);

    // Add logo to the header
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(logoImageData, 'PNG', 10, 10, 40, 20);

    // Add title with word wrapping if needed
    pdf.setFont("Helvetica", "bold");
    pdf.setFontSize(24);
    pdf.setTextColor(139, 0, 0); // Dark red
    const title = "Stage Schedule";
    const titleLines = pdf.splitTextToSize(title, pageWidth - 40); // 40 for margins
    const titleHeight = 10 + titleLines.length * 10; // Calculate dynamic height
    pdf.text(titleLines, pageWidth / 2, 35, { align: 'center' });

    // Decorative line under the title
    const titleBottomY = 35 + titleHeight;
    pdf.setDrawColor(139, 0, 0); // Dark red
    pdf.setLineWidth(0.8);
    pdf.line(15, titleBottomY, pageWidth - 15, titleBottomY);

    // Set layout variables
    const margin = 15;
    let yOffset = titleBottomY + 10; // Start below the title and line
    const sectionPadding = 10;
    const maxContentWidth = pageWidth - margin * 2;
    const lineHeight = 10;

    // Sort speakers by date and time
    const sortedSpeakers = [...speakers].sort((a, b) => {
      if (a.date !== b.date) return new Date(a.date) - new Date(b.date);
      return a.slot.localeCompare(b.slot);
    });

    // Function to create a modern section with a border
    const drawSection = (x, y, width, height) => {
      pdf.setDrawColor(220, 20, 60); // Red border
      pdf.setFillColor(255, 228, 225); // Light red background
      pdf.rect(x, y, width, height, 'FD'); // Filled rectangle with border
    };

    // Loop through speakers
    sortedSpeakers.forEach((speaker, index) => {
      // Check for page overflow and add a new page if necessary
      const remainingHeight = pageHeight - yOffset - 20; // 20 for footer margin
      const contentHeight = 20 + (lineHeight * 3) + 10; // Section height
      if (remainingHeight < contentHeight) {
        pdf.addPage();
        pdf.addImage(logoImageData, 'PNG', 10, 10, 40, 20); // Re-add logo
        yOffset = 35; // Reset yOffset after adding new page
      }

      // Section Header
      const headerHeight = 20;
      drawSection(margin, yOffset, maxContentWidth, headerHeight);
      pdf.setFont("Helvetica", "bold");
      pdf.setFontSize(11);
      pdf.setTextColor(139, 0, 0); // Dark red
      pdf.text(
        `Session ${index + 1}: ${speaker.description}`,
        margin + sectionPadding,
        yOffset + 13
      );

      yOffset += headerHeight;

      // Details Section
      const details = [
        `Speaker: ${speaker.name}`,
        `Date: ${speaker.date}`,
        `Time: ${speaker.slot}`,
      ];
      const detailsHeight = lineHeight * details.length + sectionPadding * 2;
      drawSection(margin, yOffset, maxContentWidth, detailsHeight);
      pdf.setFont("Helvetica", "normal");
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0); // Black text

      let detailYOffset = yOffset + sectionPadding + 5;
      details.forEach(detail => {
        pdf.text(detail, margin + sectionPadding, detailYOffset);
        detailYOffset += lineHeight;
      });

      yOffset += detailsHeight + 10; // Add spacing between sections
    });

    // Footer with page number
    const footerHeight = 10;
    pdf.setFont("Helvetica", "italic");
    pdf.setFontSize(10);
    pdf.setTextColor(150, 150, 150);
    const pageCount = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.text(
        `Page ${i} of ${pageCount}`,
        pageWidth / 2,
        pageHeight - footerHeight,
        { align: 'center' }
      );
    }

    // Save the PDF
    pdf.save('stage_schedule.pdf');
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};






  const sortedSpeakers = [...speakers].sort((a, b) => {
    if (a.date !== b.date) return new Date(a.date) - new Date(b.date);
    return a.slot.localeCompare(b.slot);
  });

  return (
    <Container maxWidth="lg" sx={{ paddingY: 4, backgroundColor: '#f9f9f9' }}>
                    <ThemeProvider theme={theme}>

<HelpButton/>
      <Box sx={{ textAlign: 'center', mt: 4 }}>

<StyledTitle variant="h5" gutterBottom>
Events Stage Schedule
</StyledTitle>
</Box>

      <Box sx={{ marginBottom: 3, padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 1 }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Event</InputLabel>
              <Select
                value={selectedEvent}
                onChange={handleEventChange}
                input={<OutlinedInput label="Event" />}
              >
                <MenuItem value=""><em>Select Event</em></MenuItem>
                {events.map(event => (
                  <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Date</InputLabel>
              <Select
                value={selectedDate}
                onChange={handleDateChange}
                input={<OutlinedInput label="Date" />}
              >
                <MenuItem value=""><em>Select Date</em></MenuItem>
                {dates.length > 0 ? (
                  dates.map(date => (
                    <MenuItem key={date} value={date}>{date}</MenuItem>
                  ))
                ) : (
                  <MenuItem value=""><em>No Dates Available</em></MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Time</InputLabel>
              <Select
                value={selectedTime}
                onChange={handleTimeChange}
                input={<OutlinedInput label="Time" />}
              >
                <MenuItem value=""><em>Select Time</em></MenuItem>
                {slots.length > 0 ? (
                  slots.map(slot => (
                    <MenuItem key={slot} value={slot}>{slot}</MenuItem>
                  ))
                ) : (
                  <MenuItem value=""><em>No Slots Available</em></MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Button startIcon={<PictureAsPdfIcon />}
variant="contained"  onClick={generatePDF} 
    color="secondary"
              sx={{
                marginBottom: 2,
            bgcolor: '#000', // Set the background color to black
  color: '#fff', // Set the text color to white for contrast
  '&:hover': {
    bgcolor: '#333', // Slightly lighter black for the hover state
  },}}
>
       Schedule
      </Button>

      {progress ? (
        <LinearProgress sx={{ display: 'block', margin: 'auto', color: 'primary.main' }} />
      ) : (
        <div id="pdf-content">
          <Grid container spacing={3}>
            {sortedSpeakers.map(speaker => (
              <Grid item xs={12} sm={6} md={4} key={speaker._id}>
               <Card
  sx={{
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: 3,
    boxShadow: 5, // Slightly elevated shadow for a premium feel
    overflow: 'hidden', // To ensure clean edges
    backgroundColor: '#f9f9f9', // Light background for elegance
    transition: 'transform 0.2s ease-in-out', // Subtle hover effect
    '&:hover': {
      transform: 'scale(1.02)', // Slight scale on hover
      boxShadow: 8, // Enhance shadow on hover
    },
  }}
>
  <CardMedia
    component="img"
    alt={speaker.description}
    image={speaker.photoId}
    sx={{
      height: 200,
      objectFit: 'contain',
      filter: 'brightness(95%)', // Slightly dim to avoid overpowering content
      borderBottom: '2px solid #ccc', // Elegant border for separation
    }}
  />
  <CardContent
    sx={{
      flex: '1 0 auto',
      padding: 2,
      textAlign: 'center', // Center-align text for balance
    }}
  >
    {/* Speaker Name */}
    <Typography
      variant="h5"
      gutterBottom
      sx={{
        fontWeight: 'bold',
        color: '#333', // Dark, neutral text for readability
        textTransform: 'capitalize', // Uniform style
      }}
    >
      {speaker.name}
    </Typography>

    {/* Speaker Description */}
    <Typography
      variant="subtitle1"
      gutterBottom
      sx={{
        fontWeight: 'medium',
        color: '#555', // Subtle difference from name
        fontStyle: 'italic', // Adds a graceful touch
      }}
    >
      {speaker.description}
    </Typography>

    {/* Date and Time */}
    <Typography
      variant="body2"
      sx={{
        color: '#777', // Light text for less prominent details
      }}
    >
      <span style={{ fontWeight: 'bold' }}>Date:</span> {speaker.date} |{' '}
      <span style={{ fontWeight: 'bold' }}>Time:</span> {speaker.slot}
    </Typography>
  </CardContent>
</Card>


              </Grid>
            ))}
          </Grid>
        </div>
      )}
            </ThemeProvider>

    </Container>
  );
};

export default EventSchedulePage;