import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Modal, TextField, IconButton,Typography, Switch, Select, MenuItem, FormControl, InputLabel, Collapse, Alert, LinearProgress, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExhibitorsPage = () => {
  const [exhibitors, setExhibitors] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentExhibitor, setCurrentExhibitor] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [exhibitorToDelete, setExhibitorToDelete] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [initialExhibitor, setInitialExhibitor] = useState(null);
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    fetchExhibitors();
    fetchEvents();
  }, []);

  useEffect(() => {
    if (selectedEvent) {
      updateExhibitorUserCount(selectedEvent);
    } else {
      fetchExhibitors();
    }
  }, [selectedEvent]);

  const fetchExhibitors = async () => {
    setProgress(true);
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

    if (!token) {
      // Handle missing token (e.g., redirect to login page)
      console.error('No token found');
      return;
    }
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getexhibitors',{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

        }
      });
      const data = response.data.reverse();
      setExhibitors(data);
    } catch (error) {
      setMessage("Error fetching exhibitors: " + error);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setEvents(filteredEvents);
    } catch (error) {
      setMessage("Error fetching events: " + error);
      setSeverity("error");
      setAlertOpen(true);
    }
  };

  const updateExhibitorUserCount = (eventId) => {
    const updatedExhibitors = exhibitors.map(exhibitor => {
      const event = exhibitor.events.find(e => e.event_id === eventId);
      return { ...exhibitor, user_count: event ? event.user_count : 0 };
    });
    setExhibitors(updatedExhibitors);
  };

  const getBackageText = (backage) => {
    switch (backage) {
      case '1':
        return 'Platinum';
      case '2':
        return 'Gold';
      case '3':
        return 'Silver';
      default:
        return 'Default';
    }
  };

  const handleEditOpen = (exhibitor) => {
    setCurrentExhibitor(exhibitor);
    setInitialExhibitor(exhibitor);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentExhibitor(null);
    setInitialExhibitor(null);
    setEditOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const hasChanged = Object.keys(initialExhibitor).some(
      key => initialExhibitor[key] !== currentExhibitor[key]
    );
    if (hasChanged) {
      try {
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
        await axios.put(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/updateexhibitor/${currentExhibitor.id}`, currentExhibitor,{
          headers: {
            Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
  
          }
        });
        fetchExhibitors();
        setMessage("Exhibitor updated Successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleEditClose();
      } catch (error) {
        setMessage("Error updating exhibitors: " + error);
        setSeverity("error");
        setAlertOpen(true);
        handleEditClose();
      }
    } else {
      handleEditClose();
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setExhibitorToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setExhibitorToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      await axios.delete(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/deleteexhibitor/${exhibitorToDelete}`,{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

        }
      });
      fetchExhibitors();
      setMessage("Exhibitor deleted Successfully");
      setSeverity("success");
      setAlertOpen(true);
      handleCloseDeleteDialog();
    } catch (error) {
      setMessage("Error deleting exhibitors: " + error);
      setSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleEventFilterChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const handleSwitchChange = (field) => (event) => {
    setCurrentExhibitor({ ...currentExhibitor, [field]: event.target.checked ? 1 : 0 });
  };

  const handleBackageChange = (event) => {
    setCurrentExhibitor({ ...currentExhibitor, backage: event.target.value });
  };

  const getValue = (ok) => {
    return ok === 1;
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'institution', headerName: 'Institution', flex: 1 },
    { field: 'tel', headerName: 'Tel', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'backage', headerName: 'Backage', flex: 1, renderCell: (params) => {
      return getBackageText(params.row.backage) }},
    {
      field: 'ok',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => (
        params.value === 1 ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      ),
    },
    { field: 'user_count', headerName: 'User Count', flex: 1 },
    {
      field: 'actions',
      headerName: 'Tools',
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      <Box
  sx={{
    my: 3,
    maxWidth: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5',
    p: 2,
    borderRadius: 2,
    boxShadow: 3,
  }}
>
  <FormControl fullWidth variant="outlined">
    <InputLabel
      id="event-filter-label"
      sx={{
        color: '#333',
        fontWeight: 'bold',
        '&.Mui-focused': {
          color: 'black',
        },
      }}
    >
      Filter by Event
    </InputLabel>
    <Select
      labelId="event-filter-label"
      value={selectedEvent}
      onChange={handleEventFilterChange}
      label="Filter by Event"
      startAdornment={<EventIcon sx={{ color: 'black', mr: 1 }} />}
      sx={{
        backgroundColor: '#fff',
        borderRadius: 1,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'black',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'black',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'black',
        },
        '& .MuiSelect-select': {
          py: 1.5, // Adjust padding for better appearance
          color: '#333',
        },
      }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {events.map((event) => (
        <MenuItem key={event.id} value={event.id}>
          {event.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>

      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={exhibitors}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
        />
      </Box>
      {currentExhibitor && (
        <Modal open={editOpen} onClose={handleEditClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '90%',

          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
          >
            Edit Exhibitor
          </Typography>
      
          <form onSubmit={handleEditSubmit}>
            <TextField
              label="Name"
              value={currentExhibitor.name}
              onChange={(e) => setCurrentExhibitor({ ...currentExhibitor, name: e.target.value })}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Title"
              value={currentExhibitor.title}
              onChange={(e) => setCurrentExhibitor({ ...currentExhibitor, title: e.target.value })}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Institution"
              value={currentExhibitor.institution}
              onChange={(e) => setCurrentExhibitor({ ...currentExhibitor, institution: e.target.value })}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Tel"
              value={currentExhibitor.tel}
              onChange={(e) => setCurrentExhibitor({ ...currentExhibitor, tel: e.target.value })}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Email"
              value={currentExhibitor.email}
              onChange={(e) => setCurrentExhibitor({ ...currentExhibitor, email: e.target.value })}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="backage-label">Backage</InputLabel>
              <Select
                labelId="backage-label"
                label="Backage"
                value={currentExhibitor.backage}
                onChange={handleBackageChange}
                variant="outlined"
              >
                <MenuItem value="0">Default</MenuItem>
                <MenuItem value="1">Platinum</MenuItem>
                <MenuItem value="2">Gold</MenuItem>
                <MenuItem value="3">Silver</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={<Switch checked={getValue(currentExhibitor.ok)} onChange={handleSwitchChange('ok')} />}
              label="Activation"
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2, py: 1.5, fontWeight: 'bold' }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      
      )}
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Delete Exhibitor'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this exhibitor? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button color="error" onClick={handleDelete} >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExhibitorsPage;
