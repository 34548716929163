import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import StarIcon from '@mui/icons-material/Star';
import Dateicon from '@mui/icons-material/AccessTime';
import HotelIcon from '@mui/icons-material/Hotel';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SchoolIcon from '@mui/icons-material/School';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import ArchiveIcon from '@mui/icons-material/Archive';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PublicIcon from '@mui/icons-material/Public';
import HandshakeIcon from '@mui/icons-material/Handshake';
import GroupsIcon from '@mui/icons-material/Groups';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import SendIcon from '@mui/icons-material/Send';
import { Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PollIcon from '@mui/icons-material/Poll';
import ForumIcon from '@mui/icons-material/Forum';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

const DrawerComp = ({
    level2,
    level3,
    level4,
    level5,
    level6,
    level7,
    handleListItemClick,
    selectedComponent,
    handleExpandClick,
    expandedSection,
  }) => {
    return (
        <div>
      <Toolbar />
      <Box
        sx={{
          height: 120,
          display: 'block',
          margin: 'auto',
          marginBottom: 2,
        }}
        component="img"
        alt="Logo"
        src="https://edugate-eg.com/wp-content/uploads/2023/04/10-YEARS-EDUGATE-copy.png"
      />
      <Divider />
      <List>
        {/* EDUGATE Section */}
        <ListItem button onClick={() => handleExpandClick('EDUGATE')}
       
        >
        <Avatar alt="EDU GATE Logo" style={{ marginRight: 16 }} src="./Edutech_logo.ico" />
          <ListItemText primary="EDU GATE" />
          {expandedSection === 'EDUGATE' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandedSection === 'EDUGATE'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Overview')}
              sx={{
                backgroundColor: selectedComponent === 'Overview' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><TrendingUpIcon /></ListItemIcon>
                <ListItemText primary="Overview" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Events')}
              sx={{
                backgroundColor: selectedComponent === 'Events' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><EventIcon /></ListItemIcon>
                <ListItemText primary="Events" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Exhibitors')}
              sx={{
                backgroundColor: selectedComponent === 'Exhibitors' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><PeopleIcon /></ListItemIcon>
                <ListItemText primary="Exhibitors" />
              </ListItem>
            )}
            {!(  level2 || level4 || level5 || level6|| level3 || level7 ) && (
              <ListItem button onClick={() => handleListItemClick('Registrations')}
              sx={{
                backgroundColor: selectedComponent === 'Registrations' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="Registrations" />
              </ListItem>
            )}
            
            


            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('NotificationForm')}
              sx={{
                backgroundColor: selectedComponent === 'NotificationForm' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><SendIcon /></ListItemIcon>
                <ListItemText primary="Notifications" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Sponsors')}
              sx={{
                backgroundColor: selectedComponent === 'Sponsors' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HandshakeIcon /></ListItemIcon>
                <ListItemText primary="Sponsors" />
              </ListItem>
            )}
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Speakers')}
              sx={{
                backgroundColor: selectedComponent === 'Speakers' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><MicExternalOnIcon /></ListItemIcon>
                <ListItemText primary="Speakers" />
              </ListItem>
            )}
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('GotTalentManagment')}
              sx={{
                backgroundColor: selectedComponent === 'GotTalentManagment' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><AudiotrackIcon /></ListItemIcon>
                <ListItemText primary="Got-Talent" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('News')}
              sx={{
                backgroundColor: selectedComponent === 'News' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><NewspaperIcon /></ListItemIcon>
                <ListItemText primary="News" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Rating')}
              sx={{
                backgroundColor: selectedComponent === 'Rating' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><StarIcon /></ListItemIcon>
                <ListItemText primary="Rating" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Generate')}
              sx={{
                backgroundColor: selectedComponent === 'Generate' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><Dateicon /></ListItemIcon>
                <ListItemText primary="Generate Dates" />
              </ListItem>
            )} 
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('ProblemView')}
              sx={{
                backgroundColor: selectedComponent === 'ProblemView' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ReportProblemIcon /></ListItemIcon>
                <ListItemText primary="Reported Issues" />
              </ListItem>
            )} 
{!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('ControlForms')}
              sx={{
                backgroundColor: selectedComponent === 'ControlForms' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><SettingsRemoteIcon /></ListItemIcon>
                <ListItemText primary="Control" />
              </ListItem>
            )} 


            {!(level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Hotel')}
              sx={{
                backgroundColor: selectedComponent === 'Hotel' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HotelIcon /></ListItemIcon>
                <ListItemText primary="Hotel" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Feedback')}
              sx={{
                backgroundColor: selectedComponent === 'Feedback' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><FeedbackIcon /></ListItemIcon>
                <ListItemText primary="Exhibitors Feedback" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Survey')}
              sx={{
                backgroundColor: selectedComponent === 'Survey' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><PollIcon /></ListItemIcon>
                <ListItemText primary="Survey Students" />
              </ListItem>
            )}
            {!(level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Enquiry')}
              sx={{
                backgroundColor: selectedComponent === 'Enquiry' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
                <ListItemText primary="Enquiry" />
              </ListItem>
            )}
            {!(level2 || level3 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Products')}
              sx={{
                backgroundColor: selectedComponent === 'Products' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ProductionQuantityLimitsIcon /></ListItemIcon>
                <ListItemText primary="Products" />
              </ListItem>
            )}
            {!(level2 || level5 || level6 || level7)  && (
              <ListItem button onClick={() => handleListItemClick('Carts')}
              sx={{
                backgroundColor: selectedComponent === 'Carts' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ShoppingBasketIcon /></ListItemIcon>
                <ListItemText primary="Carts" />
              </ListItem>
            )}
            {!(level2 || level3 || level4 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('School Management')}
              sx={{
                backgroundColor: selectedComponent === 'School Management' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><SchoolIcon /></ListItemIcon>
                <ListItemText primary="School Management" />
              </ListItem>
            )}
            {!(level2 || level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Offers')}
              sx={{
                backgroundColor: selectedComponent === 'Offers' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                <ListItemText primary="Offers" />
              </ListItem>
            )}
          </List>
        </Collapse>
  
        {/* EDUTECH Section */}
        <ListItem button onClick={() => handleExpandClick('EDUTECH')}>
        <Avatar style={{ marginRight: 16 }} alt="EDU GATE Logo" src="./Edutech_logo2.ico" />
          <ListItemText primary="EDU TECH" />
          {expandedSection === 'EDUTECH' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandedSection === 'EDUTECH'} timeout="auto" unmountOnExit>
        {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechView')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechView' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="Registrations" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechViewForum')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechViewForum' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ForumIcon /></ListItemIcon>
                <ListItemText primary="Forum" />
              </ListItem>
            )} 
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('AgendaManagementEdutech')}
              sx={{
                backgroundColor: selectedComponent === 'AgendaManagementEdutech' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ViewAgendaIcon /></ListItemIcon>
                <ListItemText primary="Agenda" />
              </ListItem>
            )} 
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechPublicInfo')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechPublicInfo' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><PublicIcon /></ListItemIcon>
                <ListItemText primary="Public Info" />
              </ListItem>
            )} 
            
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('GenerateEdutech')}
              sx={{
                backgroundColor: selectedComponent === 'GenerateEdutech' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><Dateicon /></ListItemIcon>
                <ListItemText primary="Generate Dates" />
              </ListItem>
            )}
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('SpeakersEdutech')}
              sx={{
                backgroundColor: selectedComponent === 'SpeakersEdutech' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><MicExternalOnIcon /></ListItemIcon>
                <ListItemText primary="Speakers" />
              </ListItem>
            )}       
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EDUTECHVideo')}
              sx={{
                backgroundColor: selectedComponent === 'EDUTECHVideo' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><VideoCallIcon /></ListItemIcon>
                <ListItemText primary="Sessions" />
              </ListItem>
            )}         
             {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('SpeakersEdutechForum')}
              sx={{
                backgroundColor: selectedComponent === 'SpeakersEdutechForum' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><GroupsIcon /></ListItemIcon>
                <ListItemText primary="Forum Speakers" />
              </ListItem>
            )} 
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechOfficialPartners')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechOfficialPartners' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HandshakeIcon /></ListItemIcon>
                <ListItemText primary="Offical Sponsors" />
              </ListItem>
            )} 
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechPartners')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechPartners' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HandshakeIcon /></ListItemIcon>
                <ListItemText primary="Sponsors" />
              </ListItem>
            )} 
            {!(level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EnquiryEdutech')}
              sx={{
                backgroundColor: selectedComponent === 'EnquiryEdutech' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
                <ListItemText primary="Enquiry" />
              </ListItem>
            )}        
             </Collapse> 
        
        <ListItem button onClick={() => handleExpandClick('Agritech')}>
        <Avatar style={{ marginRight: 16 }} alt="EDU GATE Logo" src="./logo-agritech-colored-min.ico" />
          <ListItemText primary="Agritech" />
          {expandedSection === 'Agritech' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandedSection === 'Agritech'} timeout="auto" unmountOnExit>
        {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('AgritechView')}
              sx={{
                backgroundColor: selectedComponent === 'AgritechView' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="Registrations" />
              </ListItem>
            )}  
             {!(level3 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('AgritechForumView')}
              sx={{
                backgroundColor: selectedComponent === 'AgritechForumView' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ForumIcon /></ListItemIcon>
                <ListItemText primary="Forum" />
              </ListItem>
            )}      
           </Collapse>
  
        {/* Miningtech Section  */}
        <ListItem button onClick={() => handleExpandClick('Miningtech')}>
        <Avatar style={{ marginRight: 16 }} alt="EDU GATE Logo" src="./mining_tech.ico" />
          <ListItemText primary="Miningtech" />
          {expandedSection === 'Miningtech' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandedSection === 'Miningtech'} timeout="auto" unmountOnExit>
        {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('MiningView')}
              sx={{
                backgroundColor: selectedComponent === 'MiningView' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="Registrations" />
              </ListItem>
            )}
        </Collapse>
        <ListItem button onClick={() => handleExpandClick('Eduventure')}>
        <Avatar style={{ marginRight: 16 }} alt="EDU GATE Logo" src="./eduventure.ico" />
          <ListItemText primary="Eduventure" />
          {expandedSection === 'Eduventure' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandedSection === 'Eduventure'} timeout="auto" unmountOnExit>
        {!(  level2 || level4 || level5 || level6|| level3) && (
              <ListItem button onClick={() => handleListItemClick('Registrations')}
              sx={{
                backgroundColor: selectedComponent === 'Registrations' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="Registrations EDU GATE" />
              </ListItem>
            )}
            {!(  level2 || level4 || level5 || level6 ) && (
              <ListItem button onClick={() => handleListItemClick('TransferredParticipants')}
              sx={{
                backgroundColor: selectedComponent === 'TransferredParticipants' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><SwitchLeftIcon /></ListItemIcon>
                <ListItemText primary="Transferred Participants" />
              </ListItem>
            )}
             {!(  level2 || level4 || level5 || level6) && (
              <ListItem button onClick={() => handleListItemClick('StudentServices')}
              sx={{
                backgroundColor: selectedComponent === 'StudentServices' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
                <ListItemText primary="Partnerships" />
              </ListItem>
            )} 
             {!(  level2 || level4 || level5 || level6) && (
              <ListItem button onClick={() => handleListItemClick('ArchivedData')}
              sx={{
                backgroundColor: selectedComponent === 'ArchivedData' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ArchiveIcon /></ListItemIcon>
                <ListItemText primary="Archived Students" />
              </ListItem>
            )} 
             {!(  level2 || level4 || level5 || level6) && (
              <ListItem button onClick={() => handleListItemClick('ContactEduventure')}
              sx={{
                backgroundColor: selectedComponent === 'ContactEduventure' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><RingVolumeIcon /></ListItemIcon>
                <ListItemText primary="Contact Us" />
              </ListItem>
            )} 
        </Collapse>
      </List>
    </div>
 );
};
  export default DrawerComp;