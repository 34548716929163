import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Box, Button, Modal, TextField, IconButton, Typography, Grid, InputLabel, Select, MenuItem, FormControl, LinearProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Avatar from '@mui/material/Avatar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Collapse, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AddProductsModal from './AddProductsModal';
import EditProductModal from './EditProductModal';

const NewsPage = () => {
  const [products, setProducts] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialProduct, setInitialProduct] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [progress, setProgress] = useState(false);

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }

      // Read the file to check if it's a valid image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is valid
          const extension = file.name.split('.').pop();
          const newName = `edugate_product_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage("The uploaded file is not a valid image or is corrupted");
          setSeverity("error");
          setAlertOpen(true);          
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000",
      },
      secondary: {
        main: "#FFD700",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });

  const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: '10px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getproducts');
      setProducts(response.data.reverse());
    } catch (error) {
      setMessage("Error fetching products: " + error);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handleAddModalOpen = () => setAddModalOpen(true);
  const handleAddModalClose = () => setAddModalOpen(false);

  const handleEditOpen = (newsItem) => {
    setCurrentProduct(newsItem);
    setInitialProduct(newsItem);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentProduct(null);
    setInitialProduct(null);
    setEditOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const hasChanged = Object.keys(initialProduct).some(
      key => initialProduct[key] !== currentProduct[key]
    );
    if (hasChanged || photo) {
      setProgress(true);
      try {
        const { _id, ...updatedProduct } = currentProduct; // Destructure to exclude _id
        const updatedProduct2 = {
          ...updatedProduct,
          photo: photo
        };
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage

        if (!token) {
          // Handle missing token (e.g., redirect to login page)
          console.error('No token found');
          return;
        }
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/updateproduct/${_id}`, updatedProduct2, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

          }
        });
        setPhoto(null);
        fetchNews();
        setMessage("Product updated Successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleEditClose();
      } catch (error) {
        setMessage("Error updating product: " + error);
        setSeverity("error");
        setAlertOpen(true);
        handleEditClose();
      }
      setProgress(false);
    } else {
      handleEditClose();
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setSelectedImage('');
    setImageModalOpen(false);
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'category', headerName: 'Category', width: 150 },
    {
      field: 'link',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => (
        <Avatar
          alt={params.row.name}
          src={params.value}
          sx={{ width: 56, height: 56, cursor: 'pointer' }}
          onClick={() => handleImageClick(params.value)}
        />
      )
    },
    {
      field: 'deleted',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        params.value === '1' ? (
          <CancelIcon style={{ color: 'red' }} />
        ) : (
          <CheckCircleIcon style={{ color: 'green' }} />
        )
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditOpen(params.row)}>
          <EditIcon />
        </IconButton>
      )
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Box sx={{ width: '100%' }}>
          <Collapse in={alertOpen}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {message}
            </Alert>
          </Collapse>
        </Box>
        <Button sx={{ mt: 1, mb: 1 }} variant="contained" color="primary" onClick={handleAddModalOpen}>Add New Product</Button>
        {progress && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={products}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            getRowId={(row) => row._id}
            rowHeight={60} // Increase row height here
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
          />
        </Box>
        <AddProductsModal
          open={addModalOpen}
          handleClose={handleAddModalClose}
          fetchNews={fetchNews}
          setAlertOpen={setAlertOpen}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
        {currentProduct && (
        <EditProductModal
        open={editOpen}
        handleClose={handleEditClose}
        currentProduct={currentProduct}
        setCurrentProduct={setCurrentProduct}
        handleEditSubmit={handleEditSubmit}
        handlePhotoUpload={handlePhotoUpload}
        photo={photo}
        progress={progress}
      />
      
       
        
        )}
        <Modal
  open={imageModalOpen}
  onClose={handleImageModalClose}
  aria-labelledby="image-modal-title"
  aria-describedby="image-modal-description"
  sx={{ zIndex: 1300 }} // Ensure the modal is above other content
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: { xs: '90%', sm: '80%', md: '60%' },
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 2,
      p: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    }}
  >
    <img
      src={selectedImage}
      alt="Product"
      style={{
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 4,
      }}
    />
  </Box>
</Modal>

      </div>
    </ThemeProvider>
  );
};

export default NewsPage;
