import React, { useEffect,useState, useRef } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import './ReservationForm.css';
import axios from 'axios';
import { MenuItem, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ReportProblem from '../ReportProblem';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import HelpButton from '../HelpButton';

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    }
  }
});

const theme2 = createTheme({
  palette: {
    primary: {
      main: "#000000"
    }
  }
});
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const ReservationForm = () => {
  const [value, setValue] = useState(dayjs('2022-04-17T15:30'));
  const [formData, setFormData] = useState({
    fullName: '',
    institution: '',
    checkin: '',
    checkout: '',
    singleRooms: 0,
    doubleRooms: 0,
    tripleRooms: 0,
    email: '',
    phoneNumber: '',
    totalPrice: 0,
    shuttleBusNeeded: false,
    flightNumber: '',
    selectedEvent: '', // New state variable for selected event
    nationality:""

  });

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formRef = useRef(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [dateError, setDateError] = useState('');
  const currentDate = new Date().toISOString().split('T')[0];
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [progress, setProgress] = useState(false);
  const [availableEvents, setAvailableEvents] = useState([]); // State variable for available events
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setCountries(response.data[5].countries);
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);



  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setAvailableEvents(filteredEvents);

    } catch (error) {
    }
  };
  

  useEffect(() => {
    fetchAvailableEvents();
  }, []);
  const calculateTotalPrice = () => {
    const singleRoomPrice = 200;
    const doubleRoomPrice = 220;
    const tripleRoomPrice = 295;

    const checkinDate = new Date(formData.checkin);
    const checkoutDate = new Date(formData.checkout);
    if (isNaN(checkinDate) || isNaN(checkoutDate)) {
      return 0;
    }

    const numberOfNights = Math.floor((checkoutDate - checkinDate) / (1000 * 60 * 60 * 24));
    const singleRoomCost = formData.singleRooms > 0 ? singleRoomPrice * formData.singleRooms * numberOfNights : 0;
    const doubleRoomCost = formData.doubleRooms > 0 ? doubleRoomPrice * formData.doubleRooms * numberOfNights : 0;
    const tripleRoomCost = formData.tripleRooms > 0 ? tripleRoomPrice * formData.tripleRooms * numberOfNights : 0;

    let total = singleRoomCost + doubleRoomCost + tripleRoomCost;

    if (formData.doubleRooms > 0) {
      total -= doubleRoomPrice * numberOfNights;
    } else if (formData.singleRooms > 0) {
      total -= singleRoomPrice * numberOfNights;
    } else {
      total -= (tripleRoomPrice - doubleRoomPrice) * formData.tripleRooms * numberOfNights;
    }

    return Math.max(total, 0);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.checkin > formData.checkout) {
      setDateError('Check-in date must be before or equal to checkout date.');
      return;
    }

    if (formData.checkout <= formData.checkin) {
      setDateError('Check-out date must be after check-in date.');
      return;
    }

    setProgress(true);
    const updatedTotalPrice = calculateTotalPrice();
    setFormData({ ...formData, totalPrice: 0 });

    const form = new FormData(formRef.current);
    form.append('totalPrice', updatedTotalPrice);
    form.append('ArrivalTime', value.$H + ":" + value.$m);

    try {
      setIsSubmitting(true);

      await submitToGoogleSheets(form);
      await submitToBackendAPI();
      setConfirmationMessage('Form submitted successfully! Thank You for choosing EDU GATE');
      setProgress(false);
      setErrorMessage('');
    } catch (err) {
      setErrorMessage('An error occurred while submitting the form.');
      setConfirmationMessage('');
    }
  };

  const submitToGoogleSheets = async (form) => {
    const scriptUrl = 'https://script.google.com/macros/s/AKfycbyq3dC815l-hgEPbvH2LhR4uNKdKxYiZOOGuA1VJ5FpE0et-LwTPlIHyY95N2NRdZjc/exec';
    await fetch(scriptUrl, { mode: 'no-cors', method: 'POST', body: form });
  };

  const submitToBackendAPI = async () => {
    const backendUrl = 'https://edutech-backend-471f7cb69116.herokuapp.com/api/reservationshotel';
    
    const response = await fetch(backendUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fullName: formData.fullName,
        institution: formData.institution,
        checkin: formData.checkin,
        checkout: formData.checkout,
        singleRooms: formData.singleRooms,
        doubleRooms: formData.doubleRooms,
        tripleRooms: formData.tripleRooms,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        totalPrice: formData.totalPrice,
        shuttleBusNeeded: formData.shuttleBusNeeded,
        flightNumber: formData.flightNumber,
        status:"0",
        abroad:"1",
        selectedEvent: formData.selectedEvent,
        nationality: formData.nationality


      }),
    });


    if (!response.ok) {
      throw new Error('Failed to submit data to backend API');
    }
  };


  const [formStates, setFormStates] = useState(null);
  useEffect(() => {
    fetchFormStates();
  }, []);
  
  
  const fetchFormStates = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/formstates');
      setFormStates(response.data.Edugate.hotel_abroad);
    } catch (error) {
      console.error('Error fetching form states', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleShuttleBusChange = (e) => {
    setFormData({ ...formData, shuttleBusNeeded: e.target.checked, flightNumber: '' });
  };

  const handleDateKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <div>

    {formStates && (
            <div style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
              width: '400px'
            }}>
              <Alert 
                icon={<CheckIcon fontSize="inherit" />} 
                severity="error"
                style={{ display: 'flex', alignItems: 'center' }}
              >
    Booking Hotels is Currently Closed. For more Info Contact EDU Gate           
    </Alert>
            </div>
          )}
    
    <div style={formStates ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>
    
    <div className="reservation-form-container2">
    <HelpButton/>
    <h1 style={{textAlign: "center"}}>Hotel Reservation</h1>
    <h2 style={{textAlign: "center"}}> BOOK YOUR ROOMS DURING EDU GATE</h2>   
       <ThemeProvider theme={theme}>
       <a style={{ display:"flex", flexDirection:"column" ,alignItems:"center" ,textDecoration:"none"}}target="_blank" href='https://www.kempinski.com/en/royal-maxim-palace-kempinski-cairo/overview/offers/your-luxury-staycation?utm_source=google&utm_medium=cpc&source=S46992213&&utm_campaign=KICAI2%20-%20Royal+Maxim_646080_Brand_Search_EN_EG&utm_content=kempinski+royal+maxim&gad=1&gclid=Cj0KCQjwjt-oBhDKARIsABVRB0zJKJLCv0DAVkZYfVgylbtyGcLwuQTYsDHvThsJGnrxQFGfa_2QFG8aAnJQEALw_wcB&gclsrc=aw.ds'>
          <Box m={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{maxWidth: 800 }}
            >
              Royal Maxim Palace Kempinski
            </Button>
          </Box>
        </a>
        <Paper elevation={5} style={{ position:"relative",padding: 20, margin: '20px auto', maxWidth: 800 }}>
        <Box sx={{ position: 'absolute', right: 16, top: 16 }}> {/* Positioning the ReportProblem component */}
    <Tooltip title="Report a Problem">
    <IconButton
  color="inherit"
  aria-label="notifications"
  sx={{
    mr: 2,
    zIndex: 1200, // Ensures the menu appears on top of other elements
  }}
>
<ReportProblem dynamicNumber="Hotel Abroad" />
          </IconButton>
        </Tooltip>
        </Box>

          <Box sx={{ textAlign: 'center', mt: 4 }}>

<StyledTitle variant="h5" gutterBottom>
Reservation Form
</StyledTitle>
</Box>
          <form onSubmit={handleFormSubmit} ref={formRef}>

               <Grid mb={2} item xs={12} sm={6}>
               <FormControl required fullWidth>
                  <InputLabel>Select Event</InputLabel>     
                             <Select
                label="Select Event"
                  value={formData.selectedEvent}
                  onChange={handleInputChange}
                  name="selectedEvent"
                  required
                  fullWidth
                >
                  {availableEvents.map((event) => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>

              </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Full Name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Institution"
                  name="institution"
                  value={formData.institution}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>

              <Autocomplete
  id="country-select-demo"
  fullWidth
  options={countries}
  autoHighlight
  getOptionLabel={(option) => option.label}
  value={countries.find((c) => c.label === formData.nationality) || null}
  onChange={(event, newValue) => {
    setFormData({
      ...formData,
      nationality: newValue ? newValue.label : null, // Set to null if no selection
    });
  }}
  inputValue={formData.nationality || ''}
  onInputChange={(event, newInputValue) => {
    setFormData({
      ...formData,
      nationality: newInputValue,
    });
  }}
  renderOption={(props, option) => (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} 
        </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Nationality"
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
      name="nationality"
      required
    />
  )}
/>
</Grid>

              <Grid item xs={6} sm={6}>
                <TextField
                  label="Check-in Date"
                  name="checkin"
                  type="date"
                  value={formData.checkin}
                  onChange={handleInputChange}
                  onKeyDown={handleDateKeyDown}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  inputProps={{ min: currentDate }}
                  fullWidth
                />
              </Grid>
              {formData.checkin && (
                <Grid item xs={6} sm={6}>
                  <TextField
                    label="Check-out Date"
                    name="checkout"
                    type="date"
                    value={formData.checkout}
                    onChange={handleInputChange}
                    onKeyDown={handleDateKeyDown}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    inputProps={{ min: formData.checkin }}
                    fullWidth
                  />
                </Grid>
              )}
              {dateError && (
                <Grid item xs={12}>
                  <Typography className="error-text">{dateError}</Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <InputLabel>Number of Rooms</InputLabel>
                <div style={{ marginTop: "5px" }} className="room-options">
                  <Box xm={4} xs={3}>
                    <TextField
                      label="Single"
                      type="number"
                      name="singleRooms"
                      sx={{ m: 1 }}
                      value={formData.singleRooms}
                      onChange={handleInputChange}
                      fullWidth
                      inputProps={{ min: '0' }}
                    />
                  </Box>
                  <Box xm={4} xs={3}>
                    <TextField
                      label="Double"
                      type="number"
                      name="doubleRooms"
                      value={formData.doubleRooms}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ m: 1 }}
                      inputProps={{ min: '0' }}
                    />
                  </Box>
                  <Box xm={4} xs={3}>
                    <TextField
                      label="Triple"
                      type="number"
                      name="tripleRooms"
                      value={formData.tripleRooms}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ m: 1 }}
                      inputProps={{ min: '0' }}
                    />
                  </Box>
                </div>
                <Grid item xs={12}>
                  <label>
                    <Checkbox
                      name="shuttleBusNeeded"
                      checked={formData.shuttleBusNeeded}
                      onChange={handleShuttleBusChange}
                    />
                    Shuttle Bus Needed
                  </label>
                </Grid>
                {formData.shuttleBusNeeded && (
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                      <TextField
                        label="Flight Number"
                        name="flightNumber"
                        value={formData.flightNumber}
                        onChange={handleInputChange}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                {confirmationMessage && (
                  <Typography className="confirmation-message" style={{ color: 'green' }}>
                    {confirmationMessage}
                  </Typography>
                )}
                {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}
                {errorMessage && (
                  <Typography className="error-message" style={{ color: 'red' }}>
                    {errorMessage}
                  </Typography>
                )}
                <Typography className="note">
                  Note: Rates are inclusive of service charge and VAT, based on Bed & Breakfast.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button disabled={isSubmitting || formStates} type="submit" variant="contained" color="primary" fullWidth>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </ThemeProvider>
    </div>
    </div>
    </div>

    
  );
}

export default ReservationForm;
