import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Box,
  TextField, IconButton, Switch, FormControl, InputLabel, MenuItem, Select, ListItemText, Checkbox, OutlinedInput, Chip,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Collapse, Grid, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddSponsorModal from './AddSponsorModal';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import EditSponsorModal from './EditSponsorModal'; // Adjust the path

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000",
    },
    secondary: {
      main: "#FFD700",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const StyledButton = styled(Button)({
  marginTop: '10px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SponsorsPage = () => {
  const [sponsors, setSponsors] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentSponsor, setCurrentSponsor] = useState(null);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [eventError, setEventError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sponsorToDelete, setSponsorToDelete] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialSponsor, setInitialSponsor] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [progress, setProgress] = useState(false);

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)");
        setSeverity("error")
        setAlertOpen(true);
        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeBytes) {
        setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
        setSeverity("error")
        setAlertOpen(true);
        setPhoto(null);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const extension = file.name.split('.').pop();
          const newName = `edugate_sponsor_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage("The uploaded file is not a valid image or is corrupted");
          setSeverity("error")
          setAlertOpen(true);          
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    fetchSponsors();
  }, []);

  const fetchSponsors = async () => {
    setProgress(true);

    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getsponsors',{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });
      const sponsorsData = response.data.map(sponsor => ({
        ...sponsor,
        event_ids: sponsor.event_ids ? sponsor.event_ids.toString().split(',').map(id => Number(id)) : []
      }));
      
      // Sort by rankNum in ascending order and then reverse to get descending order
      const sortedSponsors = sponsorsData.sort((a, b) => a.rankNum - b.rankNum).reverse();
      const filteredEvents = sortedSponsors.filter(sponsor => sponsor.deleted === "0");

      
      setSponsors(filteredEvents);
    } catch (error) {
      setMessage(`Error fetching sponsors: ${error.message}`);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);

  };
  

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setAvailableEvents(filteredEvents);
    } catch (error) {
      setMessage(`Error fetching events: ${error.message}`);
      setSeverity("error");
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    fetchAvailableEvents();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditOpen = (sponsor) => {
    setCurrentSponsor({ ...sponsor, event_ids: sponsor.event_ids ? sponsor.event_ids.toString().split(',').map(id => Number(id)) : [] });
    setInitialSponsor({...sponsor, event_ids: sponsor.event_ids ? sponsor.event_ids.toString().split(',').map(id => Number(id)) : [] });
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentSponsor(null);
    setInitialSponsor(null);
    setEditOpen(false);
    setEventError('');
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (currentSponsor.event_ids.length === 0) {
      setEventError('At least one event must be selected.');
      return;
    }
    const hasChanged = Object.keys(initialSponsor).some(
      key => initialSponsor[key] !== currentSponsor[key]
    );
    if (hasChanged || photo) {
      setProgress(true);
    try {
    
      const filteredSponsor = {
        ...currentSponsor,
        event_ids: currentSponsor.event_ids.filter(id => id !== 0),
        photo:photo
      };
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      await axios.put(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/updatesponsor/${filteredSponsor.id}`, filteredSponsor, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

        }
      });
      setPhoto(null);
      fetchSponsors();
      setMessage("Sponsor updated successfully");
      setSeverity("success");
      setAlertOpen(true);
      handleEditClose();
    } catch (error) {
      setMessage(`Error updating sponsor: ${error.message}`);
      setSeverity("error");
      setAlertOpen(true);
      handleEditClose();
    }
    setProgress(false);

  }
    else{
      handleEditClose();
    }
  };

  const handleDialogOpen = (id) => {
    setSponsorToDelete(id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSponsorToDelete(null);
  };

  const handleDelete = async () => {
    setProgress(true);
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      await axios.delete(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/deletesponsor/${sponsorToDelete}`,{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });
      fetchSponsors();
      setMessage("Sponsor deleted successfully");
      setSeverity("success");
      setAlertOpen(true);
      handleDialogClose();
    } catch (error) {
      setMessage(`Error deleting sponsor: ${error.message}`);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);

  };

  const handleSwitchChange = (field) => (event) => {
    setCurrentSponsor({ ...currentSponsor, [field]: event.target.checked ? "1" : "0" });
  };

  const handleEventChange = (event) => {
    const { target: { value } } = event;
    setCurrentSponsor({ ...currentSponsor, event_ids: typeof value === 'string' ? value.split(',').map(id => Number(id)) : value });
    setEventError('');  
  };


  const handleRankChange = (e) => {
    const value = Number(e.target.value);
    const rankNum = sponsors.length - (value - 1);
    setCurrentSponsor({ ...currentSponsor, rankNum });
  };
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'image',
      headerName: 'Logo',
      renderCell: (params) => (
        <Avatar
          alt={params.row.name}
          src={params.value}
          sx={{ width: 56, height: 56 }}
        />
      ),
      sortable: false,
      filterable: false,
    },
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 2 },
    {
      field: 'events',
      headerName: 'Events',
      width: 250,
      renderCell: (params) => {
        const eventIds = params.row.event_ids;
        return (
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              flexWrap: 'wrap', 
              gap: 0.5,
              height: '100%'  // Ensure the Chips are centered vertically
            }}
          >
            {eventIds.map((id) => {
              const event = availableEvents.find((ev) => ev.id === id);
              return event ? (
                <Chip key={id} label={event.name} />
              ) : null; 
            })}
          </Box>
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: 'deleted',
      headerName: 'Status',
      renderCell: (params) =>
        params.value === '1' ? (
          <CancelIcon style={{ color: 'red' }} />
        ) : (
          <CheckCircleIcon style={{ color: 'green' }} />
        ),
      sortable: false,
      filterable: false,
    },
    {
      field: 'rank',
      headerName: 'Rank',
      renderCell: (params) => {
        return sponsors.length - (params.row.rankNum - 1)},
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Tools',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleEditOpen(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDialogOpen(params.row.id)}
        />,
      ],
    },
  ];
  
  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      <Button  sx={{mt:1,mb:1}} variant="contained" color="primary" onClick={handleOpen}>Add New Sponsor</Button>
      {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}
      <Box sx={{ height: 600, width: '100%' }}>
  <DataGrid
    rows={sponsors}
    columns={columns}
    pageSize={10}
    rowsPerPageOptions={[10, 25, 50]}
    disableSelectionOnClick
    rowHeight={60} // Increase row height here
    disableColumnMenu
    sx={{
      '& .MuiDataGrid-columnHeaders': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
      },
    }}
    components={{
      NoRowsOverlay: () => (
        <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
          <Typography variant="subtitle1">No Sponsors Available</Typography>
        </Grid>
      ),
    }}
  />
</Box>
      <AddSponsorModal
        open={open}
        handleClose={handleClose}
        fetchSponsors={fetchSponsors}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        setSeverity={setSeverity}
        sponsors={sponsors}
      />
      {currentSponsor && (
      <EditSponsorModal
      editOpen={editOpen}
      handleEditClose={handleEditClose}
      currentSponsor={currentSponsor}
      setCurrentSponsor={setCurrentSponsor}
      handleEditSubmit={handleEditSubmit}
      handlePhotoUpload={handlePhotoUpload}
      photo={photo}
      handleSwitchChange={handleSwitchChange}
      handleEventChange={handleEventChange}
      availableEvents={availableEvents}
      eventError={eventError}
      sponsors={sponsors}
      handleRankChange={handleRankChange}
      progress={progress}
    />
     
      )}
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
      >
        <DialogTitle>{"Delete Sponsor"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this sponsor? This action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SponsorsPage;