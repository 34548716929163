import React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Download from '@mui/icons-material/Download';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  YouTube as YouTubeIcon,
  WhatsApp as WhatsAppIcon,
  ShareRounded as IosShare,
  LocationOn as LocationOnIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import '../styles.css'; // Import the CSS file
import CampaignIcon from '@mui/icons-material/Campaign';
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff"
    },
    secondary: {
      main: "#ffffff"
    }
  },
  typography: {
    button: {
      textTransform: "none",
    }
  },
});


const CustomIconButton = ({ icon, backgroundColor, color, fontSize, href }) => (
  <IconButton
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    style={{
      backgroundColor,
      color,
      fontSize,
      borderRadius: '50%',
      padding: '10px',
    }}
  >
    {icon}
  </IconButton>
);

const SocialIcon = ({ link, icon, size }) => (
  <IconButton href={link} target="_blank" style={{ backgroundColor: 'darkred', color: 'white', fontSize: size }}>
    {icon}
  </IconButton>
);

const EventMenu = ({event }) => {
  const socialData = [
    { link: 'https://www.facebook.com/edugate.eg/', icon: <FacebookIcon />, size: '2rem' },
    { link: 'https://www.instagram.com/edugate.eg/', icon: <InstagramIcon />, size: '2rem' },
    { link: 'https://www.linkedin.com/company/edugate-eg/', icon: <LinkedInIcon />, size: '2rem' },
    { link: 'https://www.youtube.com/@edugate-eg', icon: <YouTubeIcon />, size: '2rem' },
  ];

  const eventInfo = {
    date: event.start_date +" and until the "+ event.end_date,
    name: 'EDUGATE Fair - The International University Fair in Egypt! All universities under one roof',
    location: event.location_address,
  };

  const createWhatsAppMessage = () => {
    const message = `Join us for ${eventInfo.name} on ${eventInfo.date} at ${eventInfo.location}.`;
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/?text=${encodedMessage}`;
  };

  const iconData = [
    {
      icon: <IosShare />,
      backgroundColor: 'darkred',
      color: 'white',
      fontSize: '2rem',
      href: createWhatsAppMessage(),
    },
    {
      icon: <WhatsAppIcon />,
      backgroundColor: 'darkred',
      color: 'white',
      fontSize: '2rem',
      href: 'https://wa.me/+201000429759',
    },
    {
      icon: <LocationOnIcon />,
      backgroundColor: 'darkred',
      color: 'white',
      fontSize: '2rem',
      href: event.map,
    },
    {
      icon: <PhoneIcon />,
      backgroundColor: 'darkred',
      color: 'white',
      fontSize: '2rem',
      href: 'tel:+201000429759',
    },
    {
      icon: <EmailIcon />,
      backgroundColor: 'darkred',
      color: 'white',
      fontSize: '2rem',
      href: 'mailto:info@edugate-eg.com',
    },
  ];
  const handleButtonClick = (link) => {
    // Create an anchor element
    const anchorElement = document.createElement('a');
    // Set the href attribute to the URL you want to open
    anchorElement.href = link;
    // Set the target attribute to '_blank' to open in a new tab
    anchorElement.target = '_blank';
    // Simulate a click on the anchor element
    anchorElement.click();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgb(139, 0, 0)',
        padding: '2rem',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        marginTop: '20vh',
        maxWidth: '400px',
        minWidth: '250px',
      }}
      className="menu-container"
    >
      {/* Logo */}
      <div className="logo-container" style={{ marginBottom: '1rem' }}>
        <img
          className="logo"
          src="https://edugate-eg.com/wp-content/uploads/2023/04/10-YEARS-EDUGATE-copy-1.png"
          alt="Edugate Logo"
          style={{ maxWidth: '150px' }}
        />
      </div>

      {/* Social Media Icons */}
      <div className="social-icons" style={{ display: 'flex', gap: '10px', marginBottom: '1rem' }}>
        {socialData.map((social, index) => (
          <SocialIcon key={index} {...social} />
        ))}
      </div>

      {/* Contact Icons */}
      <div className="contact-icons" style={{ display: 'flex', gap: '10px', marginBottom: '1rem' }}>
        {iconData.map((data, index) => (
          <CustomIconButton key={index} {...data} />
        ))}
      </div>

      <ThemeProvider theme={theme}>
        <Button
          disabled={!event.fair_map} // Disable button if event.fair_map is empty ("")
          startIcon={<Download />}
          variant="contained"
          color="primary"
          style={{ marginBottom: '10px', width: '100%' }}
         // onClick={handleButtonClick(event.fair_map)} 
         href={event.fair_map}

        >
          Fair Map
        </Button>
        <Button
          startIcon={<CampaignIcon />}
          variant="contained"
          color="primary"
          style={{ width: '100%' }}
          href="https://reg.edugate-eg.com/EventSchedule"
        >
          Stage Schedule
        </Button>
      </ThemeProvider>

      <AddToCalendarButton
        name= {"EDUGATE Fair at " + event.location_address + " on the " + event.start_date + " and until the " + event.end_date}
        startDate={event.start_date} 
        options={['Apple', 'Google', 'Yahoo', 'iCal']}
      />
    </div>
  );
};

export default EventMenu;
