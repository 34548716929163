import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default function SimplifiedDataGrid() {
  const [participants, setParticipants] = useState([]);
  const [progress, setProgress] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [events, setEvents] = useState([]);
  const [pdfUrls, setPdfUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false); // New state to track if URLs are fetched
  // Fetch the PDF URLs on component mount
  useEffect(() => {
    const fetchPdfUrls = async () => {
      try {
        const response = await axios.get(
          'https://edutech-backend-471f7cb69116.herokuapp.com/api/event-pdfs'
        ); // Replace with your server URL
        setPdfUrls(response.data.pdfUrls);
      } catch (error) {
        console.error('Error fetching PDF URLs:', error.response?.data || error.message);
      } finally {
        setFetched(true); // Mark fetching as complete
      }
    };

    fetchPdfUrls();
  }, []);

  const handleDownloadZip = async () => {
    if (!pdfUrls.length) {
      alert('No PDFs to download!');
      return;
    }

    setLoading(true);
    const zip = new JSZip();
    const folder = zip.folder('event_pdfs'); // Create a folder inside the ZIP

    try {
      // Download all PDFs and add them to the ZIP
      await Promise.all(
        pdfUrls.map(async (url, index) => {
          try {
            const response = await axios.get(url, { responseType: 'blob' });
            folder.file(`pdf_${index + 1}.pdf`, response.data); // Add each PDF to the ZIP
          } catch (error) {
            console.error(`Failed to download PDF from ${url}:`, error.message);
          }
        })
      );

      // Generate the ZIP file and trigger download
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, 'event_pdfs.zip'); // Use FileSaver to download the ZIP
    } catch (error) {
      console.error('Error creating ZIP file:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchParticipants = async () => {
      setProgress(true);
      try {
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage

        if (!token) {
          // Handle missing token (e.g., redirect to login page)
          console.error('No token found');
          return;
        }
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getallparticipantForum',{
          headers: {
            Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
  
        }
        });
        const data = response.data;
        if (data.length > 0) {
          const mappedData = data.map(row => ({
            id: row._id,
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            mobile: row.mobile,
            title: row.title,
            organization: row.organization,
            whichsession: row.whichsession,
            sessionOption: row.sessionOption,
            eventID: getEventName(row.eventID),
          }));
          setParticipants(mappedData.reverse());
          const uniqueevents = Array.from(new Set(response.data.map(item => item.eventID)));
          setEvents(uniqueevents);//

        }
      } catch (error) {
      }
      setProgress(false);
    };

    fetchParticipants();
  }, []);

  const getEventName = (eventId) => {
    const eventNames = {
      '1': 'First Edition',
      '2': 'Second Edition',
      '3': 'Third Edition',
      '4': 'Fourth Edition',
      '5': 'Fifth Edition',
    };
    return eventNames[eventId] || 'Unknown Edition';
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const filteredParticipants = participants.filter(participant =>
    selectedEvent ? participant.eventID === getEventName(selectedEvent) : true
  );

  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'participants_data';
    const ws = XLSX.utils.json_to_sheet(filteredParticipants);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box sx={{ padding: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, mb: 3 }}>
        {/* Flexbox container for the filter and export button */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="event-select-label">Filter by Event</InputLabel>
            <Select
              labelId="event-select-label"
              value={selectedEvent}
              label="Filter by Event"
              onChange={handleEventChange}
            >
               <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {events.map(year => (
            <MenuItem key={year} value={year}>
              {getEventName(year)}
            </MenuItem>
          ))}
            </Select>
          </FormControl>
  
          <Button onClick={exportToExcel} variant="contained" color="success" sx={{ fontSize: '16px', py: 1.5,marginLeft:1 }}>
            Export Data to Excel
          </Button>
          <Button
  onClick={handleDownloadZip}
  disabled={loading || !fetched}
  variant="contained"
  sx={{
    fontSize: '16px',
    py: 1.5,
    marginLeft: 1,
    backgroundColor: '#0033A1',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#002a80',
    },
    '&:disabled': {
      backgroundColor: '#A1A1A1',
      color: '#FFFFFF',
    },
  }}
>
  {loading ? 'Downloading...' : 'Download Invitations'}
</Button>

        </Box>
      </Box>
  
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
  
      <DataGrid 
        autoHeight
        rows={filteredParticipants}
        pageSize={20}
        columns={[
          { field: 'id', headerName: 'ID', width: 100 },
          { field: 'firstName', headerName: 'First Name', width: 150 },
          { field: 'lastName', headerName: 'Last Name', width: 150 },
          { field: 'email', headerName: 'Email', width: 200 },
          { field: 'mobile', headerName: 'Mobile', width: 150 },
          { field: 'title', headerName: 'Title', width: 150 },
          { field: 'organization', headerName: 'Organization', width: 200 },
          { field: 'whichsession', headerName: 'Which Session', width: 150 },
          { field: 'sessionOption', headerName: 'Which Topic', width: 200 },
          { field: 'eventID', headerName: 'Event', width: 150 }
        ]}
        slots={{ toolbar: GridToolbar }}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}  