import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button, Modal, Box, TextField, IconButton, Switch, FormControl, InputLabel, MenuItem, Select,
  ListItemText, Checkbox, OutlinedInput, Chip, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Slide, Collapse, Grid,Typography, Avatar, Alert, LinearProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import AddOfferModal from './AddOfferModal';
///sss
const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000",
    },
    secondary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const StyledButton = styled(Button)({
  marginTop: '10px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OffersPage = () => {
  const [offers, setOffers] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [eventError, setEventError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [photo, setPhoto] = useState(null);
  const [progress, setProgress] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [imageModalOpen, setImageModalOpen] = useState(false);

  useEffect(() => {
    fetchOffers();
    fetchAvailableEvents();
  }, []);

  const fetchOffers = async () => {
    setProgress(true);
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const offersResponse = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getoffers',{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });
      let offersData = offersResponse.data.map(offer => ({
        ...offer,
        event_ids: offer.event_ids ? offer.event_ids.toString().split(',').map(id => Number(id)) : []
      }));
       offersData = offersData.filter(offer => offer.deleted === "0");

      
      const eventsResponse = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const availableEventsData = eventsResponse.data.filter(event => event.deleted === "0");
      
      const availableEventIds = availableEventsData.map(event => event.id);
      
      const filteredOffers = offersData.filter(offer => {
        const offerEventIds = offer.event_ids;
        return offer.deleted === "0" && offerEventIds.some(id => availableEventIds.includes(id));
      });
  
      setOffers(filteredOffers);
      setAvailableEvents(availableEventsData);
    } catch (error) {
      setMessage(`Error fetching offers: ${error.message}`);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };
  

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setAvailableEvents(filteredEvents);
    } catch (error) {
      setMessage(`Error fetching events: ${error.message}`);
      setSeverity("error");
      setAlertOpen(true);
    }
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeBytes) {
        setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const extension = file.name.split('.').pop();
          const newName = `offer_image_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage("The uploaded file is not a valid image or is corrupted");
          setSeverity("error");
          setAlertOpen(true);
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditOpen = (offer) => {
    setCurrentOffer({
      ...offer,
      event_ids: offer.event_ids ? offer.event_ids.toString().split(',').map(id => Number(id)) : []
    });
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentOffer(null);
    setEditOpen(false);
    setEventError('');
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);
  
    try {
      if (currentOffer.event_ids.length === 0) {
        setEventError('At least one event must be selected.');
        return;
      }
  
      const formData = new FormData();
      formData.append('title', currentOffer.title);
      formData.append('event_ids', currentOffer.event_ids.join(','));
      formData.append('image', photo);
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const response = await axios.put(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/updateoffer/${currentOffer.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });
  
      if (response.status === 200) {
        fetchOffers();
        handleEditClose();
        setMessage('Offer updated successfully');
        setSeverity('success');
        setAlertOpen(true);
        setSelectedImage("");
        setPhoto(null);
      } else {
        setMessage('Error updating offer');
        setSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      setMessage(`Error updating offer: ${error.message}`);
      setSeverity('error');
      setAlertOpen(true);
    }
  
    setProgress(false);
  };
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setSelectedImage('');
    setImageModalOpen(false);
  };
  
  const handleDialogOpen = (id) => {
    setOfferToDelete(id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setOfferToDelete(null);
  };

  const handleDelete = async () => {
    setProgress(true);
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      await axios.delete(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/deleteoffer/${offerToDelete}`,{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });
      fetchOffers();
      setMessage("Offer deleted successfully");
      setSeverity("success");
      setAlertOpen(true);
      handleDialogClose();
    } catch (error) {
      setMessage(`Error deleting offer: ${error.message}`);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };



  const handleEventChange = (event) => {
    const { target: { value } } = event;
    setCurrentOffer({
      ...currentOffer,
      event_ids: typeof value === 'string' ? value.split(',').map(id => Number(id)) : value
    });
    setEventError('');
  };

  const columns = [
    { field: 'title', headerName: 'Title', width: 250 },
    {
        field: 'image',
        headerName: 'Image',
        width: 150,
        renderCell: (params) => (
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              height: '100%'  // Ensure the Avatar is centered vertically
            }}
          >
            <Avatar
              alt={params.row.name}
              src={params.value}
              sx={{ width: 56, height: 56 }}
              onClick={() => handleImageClick(params.value)}

            />
          </Box>
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: 'events',
        headerName: 'Events',
        width: 250,
        renderCell: (params) => {
          const eventIds = params.row.event_ids;
          return (
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                flexWrap: 'wrap', 
                gap: 0.5,
                height: '100%'  // Ensure the Chips are centered vertically
              }}
            >
              {eventIds.map((id) => {
                const event = availableEvents.find((ev) => ev.id === id);
                return event ? (
                  <Chip key={id} label={event.name} />
                ) : null; 
              })}
            </Box>
          );
        },
        sortable: false,
        filterable: false,
      },
      
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleEditOpen(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDialogOpen(params.id)}
        />,
      ],
    },
  ];

  return (
    <Box sx={{ padding: 4 }}>

<Box sx={{ width: '100%' }}>
          <Collapse in={alertOpen}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {message}
            </Alert>
          </Collapse>
        </Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Offers Management
      </Typography>
      <StyledButton sx={{ mt: 1, mb: 1 }} variant="contained" onClick={handleOpen}>
        Add New Offer
      </StyledButton>

      {progress && <LinearProgress color="primary" />}

      <Box sx={{ height: 500, marginTop: 4 }}>
        <DataGrid
          rows={offers}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          rowHeight={80} // Increase row height here
    disableColumnMenu
    sx={{
      '& .MuiDataGrid-columnHeaders': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
      },
    }}
    components={{
      NoRowsOverlay: () => (
        <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
          <Typography variant="subtitle1">No Offers Available</Typography>
        </Grid>
      ),
    }}
        />
      </Box>

      <Modal open={open} onClose={handleClose}>
        <AddOfferModal
          open={open}
          handleClose={handleClose}
          fetchOffers={fetchOffers}
          setAlertOpen={setAlertOpen}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
      </Modal>

      <Modal open={editOpen} onClose={handleEditClose}>
        <Box
          component="form"
          onSubmit={handleEditSubmit}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Edit Offer
          </Typography>

          <TextField
            label="Title"
            value={currentOffer?.title || ''}
            onChange={(e) => setCurrentOffer({ ...currentOffer, title: e.target.value })}
            required
            fullWidth
          />
           <TextField
             label="Image URL"
             value={currentOffer?.image || ''}
             onChange={(e) => setCurrentOffer({ ...currentOffer, image: e.target.value })}
             fullWidth
             required
             disabled
           />

          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
          >
            Upload Image
            <input
              type="file"
              hidden
              onChange={handlePhotoUpload}
            />
          </Button>
          {photo && <Typography variant="body2">Selected Image: {photo.name}</Typography>}

          <FormControl fullWidth margin="normal">
              <InputLabel id="events-multiple-checkbox-label">Events</InputLabel>
              <Select
                labelId="events-multiple-checkbox-label"
                id="events-multiple-checkbox"
                multiple
                value={currentOffer?.event_ids || []}
                onChange={handleEventChange}
                input={<OutlinedInput label="Events" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((id) => {
                      const event = availableEvents.find((ev) => ev.id === id);
                      return event ? (
                        <Chip key={id} label={event.name} />
                      ) : null; // Do not render if event does not exist
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {availableEvents.map((event) => (
                  <MenuItem key={event.id} value={event.id}>
                    <Checkbox checked={currentOffer?.event_ids.includes(event.id)} />
                    <ListItemText primary={event.name} />
                  </MenuItem>
                ))}
              </Select>
              {eventError && <Typography color="error">{eventError}</Typography>}
            </FormControl>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handleEditClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            
          </Box>
          {progress && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress />
              </Box>
            )}
        </Box>
      </Modal>
      <Modal
  open={imageModalOpen}
  onClose={handleImageModalClose}
  aria-labelledby="image-modal-title"
  aria-describedby="image-modal-description"
  sx={{ zIndex: 1300 }} // Ensure the modal is above other content
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: { xs: '90%', sm: '80%', md: '60%' },
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 2,
      p: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    }}
  >
    <img
      src={selectedImage}
      alt="Product"
      style={{
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 4,
      }}
    />
  </Box>
</Modal>

      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
      >
        <DialogTitle>{"Delete Offer"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this offer? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      
    </Box>
    
  );
};

export default OffersPage;
