import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import * as FileSaver from 'file-saver';
import SearchIcon from '@mui/icons-material/Search';
import * as XLSX from 'xlsx';
import { FormControl, InputLabel, MenuItem, Select, Typography, Checkbox, Chip, Paper, TextField ,InputAdornment} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import dayjs from 'dayjs'; // Add dayjs for date parsing
import moment from 'moment'; // For formatting the date and time
import DownloadIcon from '@mui/icons-material/Download';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide } from '@mui/material'; // Import Dialog components
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DataGridDemo() {
    const [participants, setParticipants] = useState([]);
    const [selectedBefore, setSelectedBefore] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('all');
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState([]);
    const [totalAttendees, setTotalAttendees] = useState(0);
    const [availableEvents, setAvailableEvents] = useState([]);
    const [progress, setProgress] = useState(false);
    const [ageFilterApplied, setAgeFilterApplied] = useState(false);
    const [invalidBirthdatesCount, setInvalidBirthdatesCount] = useState(0);
    const [ageComparisonType, setAgeComparisonType] = useState('greater_than'); // Added state for age comparison type
    const [ageValue, setAgeValue] = useState(''); // Added state for age value
    //const [levelnum, setLevelnum] =  useState(() => sessionStorage.getItem('levelnum') || null);
    const [selectedRows, setSelectedRows] = useState([]); // State to track selected rows
    const [maxSelectionReached, setMaxSelectionReached] = useState(false); // State to handle max selection
    const MAX_SELECTION_LIMIT = parseInt(sessionStorage.getItem('MAX_SELECTION_LIMIT') || "0", 10); // Default to 10 if not set
    const [openDialog, setOpenDialog] = useState(false);  // New state for the confirmation dialog
    const [confirmTransfer, setConfirmTransfer] = useState(false); // State to confirm if transfer is approved
    const [openErrorDialog, setOpenErrorDialog] = useState(false); // State for error dialog
    const [errorMessage, setErrorMessage] = useState(''); // Error message state
    const [schoolNameFilter, setSchoolNameFilter] = useState(''); // New state for school name filter
    const [destinationFilter, setDestinationFilter] = useState('');
    const [uniqueDestinations, setUniqueDestinations] = useState([]); // Store unique destinations
    const [studyFieldFilter, setStudyFieldFilter] = useState('');
    const [uniqueStudyFields, setUniqueStudyFields] = useState([]); // Store unique study fields
    const [isStudyingAbroadFilter, setIsStudyingAbroadFilter] = useState('');

    useEffect(() => {
      setMaxSelectionReached(selectedRows.length > MAX_SELECTION_LIMIT);
    }, [selectedRows]);
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
  
    // Open the dialog before transfer
    const handleOpenDialog = () => {
      if (selectedRows.length > 0) {
        setOpenDialog(true);  // Open the dialog if rows are selected
      } else {
        alert('No rows selected for transfer.');
      }
    };

  useEffect(() => {
    const fetchAvailableEvents = async () => {
      try {
        const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
        const filteredEvents = response.data.filter(event => event.deleted === "0");
        setAvailableEvents(response.data.reverse());
      } catch (error) {
      }
    };

    fetchAvailableEvents();
  }, []);
  const fetchTransferredParticipants = async () => {
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
  
      const response = await axios.get(`https://edutech-backend-471f7cb69116.herokuapp.com/api/alltransferred-participants`, {
        headers: { 
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });
      setSelectedBefore(response.data); // Update state with the fetched participants
    } catch (error) {
      setErrorMessage('Error fetching transferred participants.');
      setOpenErrorDialog(true);
    }
  };
  const selectedBeforeIds = selectedBefore.map(participant => participant.id); // Extract IDs from selectedBefore


  // Fetch transferred participants when the component mounts
  useEffect(() => {
    fetchTransferredParticipants();
  }, []);

  useEffect(() => {
    const fetchParticipants = async () => {
      setProgress(true);
      //      let apiUrl = `https://edugate-backend-b5ad4c02888d.herokuapp.com/api/${selectedEvent === 'all' ? 'getallvisitors' : `${selectedEvent}/visitors`}`;

      let apiUrl = `https://edugate-backend-b5ad4c02888d.herokuapp.com/api/${`${selectedEvent}/visitors`}`;
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      try {
        const response = await axios.get(apiUrl,{
          headers: {
            Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
  
          }
        });
        const data = response.data;
        if (data.length > 0) {
          const mappedData = data.map(row => ({
            id: row.id,
            f_name: row.f_name,
            l_name: row.l_name,
            email: row.email,
            mobile: row.mobile,
            city: row.city,
            country: row.country,
            gender: row.gender,
            birthdate: row.birth_date,
            visitor_type: row.visitor_type,
            school_name: row.school_name,
            education_type: row.education_type,
            grade: row.grade,
            studyField: row.studyField,
            is_studying_abroad: row.is_studying_abroad,
            studying_abroad_destination: row.studying_abroad_destination,
            postgraduate_looking_for: row.postgraduate_looking_for,
            hear_aboutus_at: row.hear_aboutus_at,
            budget_dollars:row.budget_dollars,
            budget_EGP:row.budget_EGP,
            date: row.date
          }));
          setParticipants(mappedData.reverse());
          const uniqueDest = Array.from(new Set(data.map(row => row.studying_abroad_destination).filter(Boolean)));
          setUniqueDestinations(uniqueDest);
          const uniqueFields = Array.from(new Set(data.map(row => row.studyField).filter(Boolean)));
          setUniqueStudyFields(uniqueFields);
        }
      } catch (error) {
      }
      setProgress(false);
    };

    fetchParticipants();
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedEvent !== 'all') {
      const fetchAttendees = async () => {
        try {
          const token = localStorage.getItem('token');  // Retrieve the token from localStorage

    if (!token) {
      // Handle missing token (e.g., redirect to login page)
      console.error('No token found');
      return;
    }
          const response = await axios.get(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/${selectedEvent}/attendeees`,{
            headers: { 
              Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
            }
          });
          const data = response.data.total_attendees;
          setTotalAttendees(data);
        } catch (error) {
        }
      };

      fetchAttendees();
    }
  }, [selectedEvent]);
  const parseBirthdate = (birthdate) => {
    const formats = ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY'];
    const parsedDate = dayjs(birthdate, formats, true);
    return parsedDate.isValid() ? parsedDate : null;
};


  const countInvalidBirthdates = (data) => {
    return data.reduce((count, row) => {
      return parseBirthdate(row.birthdate) === null ? count + 1 : count;
    }, 0);
  };

  const applyFilters = () => {
    const filteredData = participants.filter(row => {
        const birthdate = parseBirthdate(row.birthdate);
        const age = birthdate ? dayjs().diff(birthdate, 'year') : null;
        const ageValueNumber = Number(ageValue);

        const ageFilterCondition = ageFilterApplied ? (
          (ageComparisonType === 'greater_than' && age >= ageValueNumber) ||
          (ageComparisonType === 'less_than' && age < ageValueNumber) ||
          (ageComparisonType === 'equal_to' && age === ageValueNumber)
      ) : true;
      
      const schoolNameCondition = schoolNameFilter === '' || (row.school_name || '').toLowerCase().includes(schoolNameFilter.toLowerCase());
      const destinationCondition = destinationFilter === '' || row.studying_abroad_destination === destinationFilter;
      const studyFieldCondition = studyFieldFilter === '' || row.studyField === studyFieldFilter;

      const studyingAbroadCondition = isStudyingAbroadFilter === '' ||
        (isStudyingAbroadFilter === 'Yes' && (row.is_studying_abroad === "1" || row.is_studying_abroad === "true")) ||
        (isStudyingAbroadFilter === 'No' && (row.is_studying_abroad === "0" || row.is_studying_abroad === "false"));


        return (
            ageFilterCondition && schoolNameCondition && destinationCondition && studyFieldCondition && studyingAbroadCondition &&
            (selectedGrades.length === 0 || selectedGrades.map(g => g.toLowerCase()).includes((row.grade || '').toLowerCase())) &&
            (selectedEducation.length === 0 || selectedEducation.map(e => e.toLowerCase()).includes((row.education_type || '').toLowerCase())) &&
            (selectedUserType.length === 0 || selectedUserType.map(u => u.toLowerCase()).includes((row.visitor_type || '').toLowerCase()))
        );
    });

    setFilteredParticipants(filteredData);

    if (ageFilterApplied) {
        const invalidCount = countInvalidBirthdates(filteredData);
        setInvalidBirthdatesCount(invalidCount);
    }
};

// Function to transfer selected participants to another table
// Function to transfer selected participants to another table
const handleConfirmTransfer = () => {
  setConfirmTransfer(true);
  setOpenDialog(false);  // Close dialog after confirmation
};

// Transfer function with dialog confirmation
useEffect(() => {
  if (confirmTransfer) {
    handleTransfer();
    setConfirmTransfer(false);  // Reset the confirmation flag
  }
}, [confirmTransfer]);
const handleTransfer = async () => {
  try {
      const selectedParticipantsData = participants.filter(participant =>
          selectedRows.includes(participant.id) // Filter participants based on selected IDs
      );

      const remaining = parseInt(MAX_SELECTION_LIMIT, 10) - selectedParticipantsData.length;

      const username = sessionStorage.getItem('username'); // Get the username from sessionStorage

      // Map over selected participants to add new columns
      const updatedParticipantsData = selectedParticipantsData.map(participant => ({
          ...participant, // Spread existing participant data
          comment: [], // Add empty comment
          link:"",
          status: "Open", // Add status as "0"
          stage:"Open",
          stages: [{
              currentStage: "Open",
              status:"Active",
              notQualifiedReason: "",
              appliedStatus: "Open",
              finalizedStatus: "",
              tuitionFees: "",
              commissionPercent: "",
              invoiceStatus: "",
              institutionCountry:"",
              comment:[],
              dateTime: [moment().format('MMMM Do YYYY, h:mm:ss a')] // Log initial timestamp
            }],
          englishLevel:"",
          financialSource:"",
          notes:"",
          budget:"",
          ResidenceCountry:"",
          preferredStudyLevel:participant.visitor_type.toLowerCase(),
          highestEducationLevel:"",
          preferredDestinations:[],
          desiredFields:[],
          intakeMonth:null,
          intakeYear:null,
          passport:"",transcript:"",referenceLetter:"",
          personalStatement:"",GDPR:"",
          logs:[],
          dateTime: moment().format('MMMM Do YYYY, h:mm:ss a'),
          archived:"0",
          username: username // Add username from sessionStorage,

      }));

      // Combine username with updated participants data
      const payload = {
          username, // Add username to the payload
          participants: updatedParticipantsData // Include updated participants
      };

      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      
      await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/transfer-participants', payload,{headers: { 
        Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
      }}
      );
      
      // Update selectedBefore with new participants after successful transfer
      setSelectedBefore((prevSelectedBefore) => [
          ...prevSelectedBefore,
          ...updatedParticipantsData
      ]);

      sessionStorage.setItem('MAX_SELECTION_LIMIT', remaining);
      setErrorMessage(`Transfer successful! Remaining transfers: ${remaining}`);
      setOpenErrorDialog(true); // Open error dialog

  } catch (error) {
      setErrorMessage('You have reached the max number of transfers.'); // Set error message
      setOpenErrorDialog(true); // Open error dialog
  }
};



useEffect(() => {
  if(ageValue ===""){
    setAgeFilterApplied(false)
  }else{
    setAgeFilterApplied(true)
  }


  applyFilters();
}, [selectedGrades,isStudyingAbroadFilter,studyFieldFilter,destinationFilter,schoolNameFilter, selectedEducation, selectedUserType, participants, ageFilterApplied, ageComparisonType, ageValue]);


  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'edugate_registrations';
    const ws = XLSX.utils.json_to_sheet(filteredParticipants);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleGradeChange = (event) => {
    const { target: { value } } = event;
    setSelectedGrades(typeof value === 'string' ? value.split(',') : value);
  };
  const handleSchoolNameChange = (event) => {
    setSchoolNameFilter(event.target.value);
  };

  const handleSelectedUserTypeChange = (event) => {
    const { target: { value } } = event;
    setSelectedUserType(typeof value === 'string' ? value.split(',') : value);
  };

  const handleEducationChange = (event) => {
    const { target: { value } } = event;
    setSelectedEducation(typeof value === 'string' ? value.split(',') : value);
  };
  const handleDestinationChange = (event) => {
    setDestinationFilter(event.target.value);
  };
  const handleStudyFieldChange = (event) => {
    setStudyFieldFilter(event.target.value);
  };
  const handleIsStudyingAbroadChange = (event) => {
    setIsStudyingAbroadFilter(event.target.value);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'f_name', headerName: 'First Name', width: 150 },
    { field: 'l_name', headerName: 'Last Name', width: 150 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'country', headerName: 'Country', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 150 },
    { field: 'birthdate', headerName: 'Birthdate', width: 150 },
    { field: 'visitor_type', headerName: 'Visitor Type', width: 150 },
    { field: 'school_name', headerName: 'School Name', width: 150 },
    { field: 'education_type', headerName: 'Education Type', width: 150 },
    { field: 'grade', headerName: 'Grade', width: 150 },
    { field: 'studyField', headerName: 'Study Field', width: 150 },
    { field: 'is_studying_abroad', headerName: 'Studying Abroad', width: 150 },
    { field: 'studying_abroad_destination', headerName: 'Studying Abroad Destination', width: 150 },
    { field: 'budget_dollars', headerName: 'Budget in Dollars', width: 150 },
    { field: 'budget_EGP', headerName: 'Budget in EGP', width: 150 },
    { field: 'postgraduate_looking_for', headerName: 'PostGraduate Looking For', width: 150 },
    { field: 'hear_aboutus_at', headerName: 'Hear about us at', width: 150 },
    { field: 'date', headerName: 'Date', width: 150 }
  ];
  
  // Conditionally add email and mobile columns if levelnum is "1"
  if (sessionStorage.getItem('levelnum') === "1") {
    columns.splice(3, 0, { field: 'email', headerName: 'Email', width: 200 });
    columns.splice(4, 0, { field: 'mobile', headerName: 'Mobile', width: 150 });
  }
  

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box sx={{ padding: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, mb: 3 }}>
      {sessionStorage.getItem('levelnum') === '1' && (

        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Button onClick={exportToExcel} variant="contained" color="success" sx={{ fontSize: '16px', py: 1.5 }}
          disabled={sessionStorage.getItem('levelnum') !== "1"}
          >
            Export Data to Excel
          </Button>
         
        </Box>)}
  
        <Box sx={{ textAlign: 'center', mb: 4, display: 'flex', justifyContent: 'center', gap: 3 }}>
          <Paper elevation={4} sx={{ padding: '20px 30px', borderRadius: 2, backgroundColor: '#ffffff' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
              Total Registrations
            </Typography>
            <Typography variant="h3" color="primary">
              {filteredParticipants.length}
            </Typography>
          </Paper>
  
          {selectedEvent !== 'all' && (
            <Paper elevation={4} sx={{ padding: '20px 30px', borderRadius: 2, backgroundColor: '#ffffff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                Total Attendees
              </Typography>
              <Typography variant="h3" color="primary">
                {totalAttendees}
              </Typography>
            </Paper>
          )}
          <Paper elevation={4} sx={{ padding: '20px 30px', borderRadius: 2, backgroundColor: '#ffffff' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
              Total Transfers Left
            </Typography>
            <Typography variant="h3" color="primary">
              {MAX_SELECTION_LIMIT}
            </Typography>
          </Paper>
         
          {ageFilterApplied && (
            <Paper elevation={4} sx={{ padding: '20px 30px', borderRadius: 2, backgroundColor: '#ffffff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                Invalid Birthdates
              </Typography>
              <Typography variant="h3" color="error">
                {invalidBirthdatesCount}
              </Typography>
            </Paper>
          )}
        </Box>
  
        <Box 
  sx={{ 
    display: 'flex', 
    flexWrap: 'wrap', 
    gap: 2, 
    justifyContent: 'center', 
    mb: 4, 
    p: 3, 
    borderRadius: 2, 
    backgroundColor: '#f7f7f9', 
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
  }}
>
  {/* Event Filter */}
  <FormControl variant="outlined" sx={{ minWidth: 250, boxShadow: 1, borderRadius: 1 }}>
    <InputLabel>Choose Event</InputLabel>
    <Select
      value={selectedEvent}
      onChange={(e) => setSelectedEvent(e.target.value)}
      label="Choose Event"
      sx={{ borderRadius: '8px', overflow: 'hidden' }}
    >
      {availableEvents.map((event) => (
        <MenuItem key={event.id} value={event.id}>
          {event.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  {/* Education Type Filter */}
  <FormControl variant="outlined" sx={{ minWidth: 250, boxShadow: 1, borderRadius: 1 }}>
    <InputLabel>Education Type</InputLabel>
    <Select
      multiple
      value={selectedEducation}
      onChange={handleEducationChange}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map((value) => <Chip key={value} label={value} />)}
      </Box>
      )}
      label="Education Type"
      sx={{ borderRadius: '8px', overflow: 'hidden' }}
    >
      {[
        "American Diploma",
        "IGCSE Diploma",
        "Thanaweya Amma",
        "Canadian Diploma",
        "International Baccalaureate Diploma",
        "French Baccalaureate Diploma",
        "German Abitur",
        "BTEC",
        "WAEC",
        "Nile Certificarte (Cambridge)",
        "International Education System",
        "Foreign Certificate",
        "Other"
      ].map(type => (
        <MenuItem key={type} value={type}>
          <Checkbox checked={selectedEducation.includes(type)} />
          <ListItemText primary={type} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  {/* Grade Filter */}
  <FormControl variant="outlined" sx={{ minWidth: 250, boxShadow: 1, borderRadius: 1 }}>
  <InputLabel>Grade</InputLabel>
  <Select
    multiple
    value={selectedGrades}
    onChange={handleGradeChange}
    renderValue={(selected) => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map((value) => (
          <Chip key={value} label={value} sx={{  borderRadius: 1, padding: '4px 8px' }} />
        ))}
      </Box>
    )}
    label="Grade"
    sx={{ borderRadius: '8px', overflow: 'hidden' }}
  >
    {["Grade 10", "Grade 11", "Grade 12"].map(grade => (
      <MenuItem key={grade} value={grade}>
        <Checkbox checked={selectedGrades.includes(grade)} />
        <ListItemText primary={grade} />
      </MenuItem>
    ))}
  </Select>
</FormControl>

{/* Visitor Type Filter */}
<FormControl variant="outlined" sx={{ minWidth: 250, boxShadow: 1, borderRadius: 1 }}>
  <InputLabel>Visitor Type</InputLabel>
  <Select
    multiple
    value={selectedUserType}
    onChange={handleSelectedUserTypeChange}
    renderValue={(selected) => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map((value) => (
          <Chip key={value} label={value} sx={{ borderRadius: 1, padding: '4px 8px' }} />
        ))}
      </Box>
    )}
    label="Visitor Type"
    sx={{ borderRadius: '8px', overflow: 'hidden' }}
  >
    {[
      { value: "parent", label: "Parent" },
      { value: "postGraduate", label: "PostGraduate" },
      { value: "underGraduate", label: "UnderGraduate" }
    ].map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        <Checkbox checked={selectedUserType.includes(value)} />
        <ListItemText primary={label} />
      </MenuItem>
    ))}
  </Select>
</FormControl>


  {/* Age Filter */}
  


  {/* Willingness to Study Abroad Filter */}
  <FormControl variant="outlined" sx={{ minWidth: 250, boxShadow: 1, borderRadius: 1 }}>
    <InputLabel>Willingness to study abroad</InputLabel>
    <Select
      label="Willingness to study abroad"
      value={isStudyingAbroadFilter}
      onChange={handleIsStudyingAbroadChange}
      sx={{ borderRadius: '8px' }}
    >
      <MenuItem value=""><em>All</em></MenuItem>
      <MenuItem value="Yes">Yes</MenuItem>
      <MenuItem value="No">No</MenuItem>
    </Select>
  </FormControl>

  {/* Destination Filter */}
  <FormControl variant="outlined" sx={{ minWidth: 250, boxShadow: 1, borderRadius: 1 }}>
    <InputLabel>Destination</InputLabel>
    <Select
      label="Destination"
      value={destinationFilter}
      onChange={handleDestinationChange}
      sx={{ borderRadius: '8px' }}
    >
      {uniqueDestinations.map((destination, index) => (
        <MenuItem key={index} value={destination}>{destination}</MenuItem>
      ))}
    </Select>
  </FormControl>

  {/* Study Field Filter */}
  <FormControl variant="outlined" sx={{ minWidth: 250, boxShadow: 1, borderRadius: 1 }}>
    <InputLabel>Study Field</InputLabel>
    <Select
      label="Study Field"
      value={studyFieldFilter}
      onChange={handleStudyFieldChange}
      sx={{ borderRadius: '8px' }}
    >
      <MenuItem value=""><em>All Study Fields</em></MenuItem>
      {uniqueStudyFields.map((field, index) => (
        <MenuItem key={index} value={field}>{field}</MenuItem>
      ))}
    </Select>
  </FormControl>


{/* Search by School Name */}

  <FormControl fullWidth sx={{  boxShadow: 1, borderRadius: 1, p: 2 }}>
  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <InputLabel>Age Filter</InputLabel>
      <Select
        value={ageComparisonType}
        onChange={(e) => setAgeComparisonType(e.target.value)}
        label="Age Filter"
        sx={{ width: '50%', borderRadius: '8px' }}
      >
        <MenuItem value="greater_than">Greater Than or Equal</MenuItem>
        <MenuItem value="less_than">Less Than</MenuItem>
        <MenuItem value="equal_to">Equal To</MenuItem>
      </Select>
      <TextField
        type="number"
        value={ageValue}
        onChange={(e) => setAgeValue(e.target.value)}
        label="Age"
        sx={{ width: '50%', borderRadius: '8px' }}
      />
        </Box>

  </FormControl>
  </Box>

<Box sx={{ maxWidth: 600, mx: 'auto', mt: 2 }}>
  <TextField
    label="Search by School Name"
    variant="outlined"
    value={schoolNameFilter}
    onChange={handleSchoolNameChange}
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon color="action" />
        </InputAdornment>
      ),
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        '&.Mui-focused fieldset': {
          borderColor: '#8B0000',
        },
      },
    }}
  />
</Box>

</Box>

      <Button 
        onClick={handleOpenDialog}  // Open the dialog before transfer
        variant="contained" 
     color="primary" 
     sx={{ fontSize: '10px', py: 1.5, marginBottom:"10px" }}
     disabled={selectedRows.length === 0 || maxSelectionReached}
                    >
                        Transfer Selected Entries
                    </Button>
                    <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Confirm Transfer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to transfer the selected participants?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmTransfer} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={openErrorDialog}
          onClose={() => setOpenErrorDialog(false)}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="error-dialog-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenErrorDialog(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <DataGrid 
        autoHeight
        rows={filteredParticipants}
        pageSize={20}

        columns={columns} // Pass the updated columns array

       // slots={{ toolbar: GridToolbar }}
       checkboxSelection
       onRowSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = filteredParticipants.filter((row) =>
          selectedIDs.has(row.id.toString())
        );
        setSelectedRows(selectedRowData.map((row) => row.id)); // Update selectedRows state if needed
      }}
      isRowSelectable={(params) => !selectedBeforeIds.includes(params.row.id)} // Disable selection for rows in selectedBefore
       disableRowSelectionOnClick
      />
    </Box>
  );
}  