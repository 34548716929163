import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, CardActions, TextField, Button, Typography, Link, Box,FormControl, InputLabel, MenuItem, Select, IconButton, Tooltip, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle ,Collapse,} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GetAppIcon from '@mui/icons-material/GetApp';
import dayjs from 'dayjs';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const SpeakerCard = ({ speaker, updateSpeaker, deleteSpeaker, allSlots }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({ ...speaker });
  const [availableSlots, setAvailableSlots] = useState([]);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false); // State for submit confirmation dialog
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = React.useState(false);
  const [initialSpeaker, setInitialSpeaker] = useState(null);
  const [progress, setProgress] = useState(false);

  ////
  const [presentation, setPresentation] = useState(null);
  const [photo, setPhoto] = useState(null);

  const handleEditClick = () => {
    setInitialSpeaker(speaker);
    setIsEditing(true);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000",
      },
      secondary: {
        main: "#FFD700",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });
  const StyledButton = styled(Button)({
    marginTop: '10px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  });
  ///
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );



  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setAvailableEvents(filteredEvents);
    } catch (error) {
      setMessage("Error fetching events" + {error});
      setSeverity("error")
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    fetchAvailableEvents();
  }, []);

  useEffect(() => {
    if (formData.date) {
      const formattedDate = dayjs(formData.date).format('YYYY-MM-DD');
      const slotDataForDate = allSlots.find(slotData => slotData.date === formattedDate);
      setAvailableSlots(slotDataForDate ? slotDataForDate.slots : []);
    } else {
      setAvailableSlots([]);
    }
  }, [formData.date, allSlots]);

  const handleDateChange = (newDate) => {
    if (newDate) {
      const updatedFormData = { ...formData, date: newDate };
      setFormData(updatedFormData);

      const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
      const slotDataForDate = allSlots.find(slotData => slotData.date === formattedDate);
      setAvailableSlots(slotDataForDate ? slotDataForDate.slots : []);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        setMessage("Failed to copy" + {err});
        setSeverity("error")
        setAlertOpen(true);
      });
  };

  const handlePresentationUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ['application/pdf'];
      if (!validFileTypes.includes(file.type)) {
        setMessage('Please upload a valid PDF file');
        setSeverity("error")
        setAlertOpen(true);
        setPresentation(null);
        return;
      }
      const maxSizeBytes = 5 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage('File size exceeds the limit of 5MB. Please choose a smaller file.');
        setSeverity("error")
        setAlertOpen(true);
        setPresentation(null);
        return;
      }

      // Check if file is corrupted
      const reader = new FileReader();
      reader.onload = () => {
        const extension = file.name.split('.').pop();
        const newName = `edugate_speaker_${Date.now()}.${extension}`;
        const renamedFile = new File([file], newName, { type: file.type });
        setPresentation(renamedFile);
      };
      reader.onerror = () => {
        setMessage('The uploaded file is corrupted');
        setSeverity("error")
        setAlertOpen(true);
        setPresentation(null);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)");
        setSeverity("error")
        setAlertOpen(true);
        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
        setSeverity("error")
        setAlertOpen(true);
        setPhoto(null);
        return;
      }

      // Read the file to check if it's a valid image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is valid
          const extension = file.name.split('.').pop();
          const newName = `edugate_speaker_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage("The uploaded file is not a valid image or is corrupted");
          setSeverity("error")
          setAlertOpen(true);          
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    const formattedDate = dayjs(formData.date).format('YYYY-MM-DD');
    const formDataToUpdate = {
      ...formData,
      date: formattedDate,
      presentation: presentation,
      photo: photo
    };
    const sp = {
      ...speaker,
      photo: null,
      presentation: null
    };

    const hasChanged = Object.keys(sp).some(
      key => sp[key] !== formDataToUpdate[key]
    );

    if (hasChanged || photo || presentation) {
      setProgress(true);

      try {
        // Assuming updateSpeaker returns the updated speaker data
        const updatedSpeaker = await updateSpeaker(speaker._id, formDataToUpdate);
        // Update formData with the new data from the backend
        setFormData({ ...updatedSpeaker.updatedSpeaker });
        //        setFormData({ ...formData, status:updatedSpeaker.status,  photoId:updatedSpeaker.photoId});

        setIsEditing(false);
      } catch (error) {
        setMessage('Failed to update speaker' + error);
        setSeverity("error");
        setAlertOpen(true);
      } finally {
        setProgress(false);
      }
    } else {
      setIsEditing(false);
    }
};

  const handleSubmit = async () => {
    if (formData.photoId === "" || formData.photoId === null) {
      setMessage('You need to upload image before submitting the speaker to the App');
      setSeverity("error")
      setAlertOpen(true);    }
    else if (formData.status === "0") {
      setSubmitDialogOpen(true); // Open submit confirmation dialog
    }  else {
      setMessage('Speaker was already submitted to the app before. Contact Youssef');
      setSeverity("error")
      setAlertOpen(true);
    }
  };

  const confirmSubmitSpeaker = async () => {
    setProgress(true);
    const { date, slot, name, description, photoId, ...otherData } = formData;
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    const myArray = slot.split("-");
    const formattedDateTime = `${formattedDate}T${myArray[0].substring(0, myArray[0].length - 1)}`;
    const formDataWithDateTime = {
      ...otherData,
      'date-time': formattedDateTime,
      event_id: speaker.event_id, // Assuming event_id is available in speaker object
      description: description,
      image: photoId,
      name: name,
      status: "1"
    };
    const formData3 = {
      ...formData,
      status: "1",
      appfirst:"1"
    };
   

    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      await axios.post('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/submitspeaker', formDataWithDateTime,{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });
      const { appfirst, ...restOfSpeaker } = formData3;
      const updatedSpeaker = await updateSpeaker(formData._id, formData3);
      setFormData({ ...updatedSpeaker.updatedSpeaker });
      setMessage("Speaker submitted Successfully");
      setSeverity("success")
      setAlertOpen(true);
    } catch (error) {
      setMessage('Failed to submit speaker' + {error});
      setSeverity("error")
      setAlertOpen(true);
    }

    setSubmitDialogOpen(false); // Close submit confirmation dialog after submission
    setProgress(false);

  };

  const handleCloseSubmitDialog = () => {
    setSubmitDialogOpen(false); // Close submit confirmation dialog without submitting
  };

  const isFormComplete = () => {
    return formData.name && formData.university && formData.phone && formData.email && formData.description && formData.date && formData.slot && formData.presentationId && formData.photoId;
  };

  const isDateDisabled = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    const slotDataForDate = allSlots.find(slotData => slotData.date === formattedDate);
    return !slotDataForDate || slotDataForDate.slots.length === 0;
  };

  return (
    <Card className="card" sx={{ margin: 2 }}>
      {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}
      <Box sx={{ width: '100%' }}>
      <Collapse in={alertOpen}>
        <Alert
        severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
      <CardContent>
        {isEditing ? (
          <div>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="University"
              name="university"
              value={formData.university}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Topic"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              margin="normal"
            />
            <Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  clearable
                  label="Date"
                  inputFormat="dd/MM/yyyy"
                  value={formData.date}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={isDateDisabled}
                  fullWidth
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  sx={{ width: '100%' }}
                  textFieldStyle={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Available Slots</InputLabel>
              <Select
                label="Available Slots"
                name="slot"
                value={formData.slot}
                onChange={handleInputChange}
              >
                {availableSlots.map((slot, index) => (
                  <MenuItem key={index} value={slot}>{slot}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Presentation URL"
              name="presentationId"
              value={formData.presentationId}
              onChange={handleInputChange}
              margin="normal"
            />
            <Button onClick={() => copyToClipboard(formData.presentationId)} variant="outlined" color="primary" disabled={!formData.presentationId}>
              Copy Presentation URL
            </Button>
            <TextField
              fullWidth
              label="Photo URL"
              name="photoId"
              value={formData.photoId}
              onChange={handleInputChange}
              margin="normal"
            />
            <Button onClick={() => copyToClipboard(formData.photoId)} variant="outlined" color="primary" disabled={!formData.photoId}>
              Copy Photo URL
            </Button>
            <Grid item xs={12} sm={6}>
                    <input
                      accept="application/pdf"
                      style={{ display: 'none' }}
                      id="upload-presentation"
                      type="file"
                      onChange={handlePresentationUpload}
                    />
                    <label htmlFor="upload-presentation">
                      <StyledButton
                        startIcon={<CloudUploadIcon />}
                        variant="contained" component="span">
                        Upload New Presentation
                      </StyledButton>
                    </label>
                    {presentation && <Typography>{presentation.name}</Typography>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="upload-photo"
                      type="file"
                      onChange={handlePhotoUpload}
                    />
                    <label htmlFor="upload-photo">
                      <StyledButton
                        startIcon={<CloudUploadIcon />}
                        variant="contained" component="span">
                        Upload New Photo
                      </StyledButton>
                    </label>
                    {photo && <Typography>{photo.name}</Typography>}
                  </Grid>
          </div>
        ) : (
          <div>
            <Typography variant="h6">{speaker.name}</Typography>
            <Typography variant="body1">Event: {availableEvents.find(event => event.id === speaker.event_id)?.name || 'Unknown Event'}</Typography>

            <Typography variant="body1">University: {speaker.university}</Typography>
            <Typography variant="body1">Phone: {speaker.phone}</Typography>
            <Typography variant="body1">Email: {speaker.email}</Typography>
            <Typography variant="body1">Topic: {speaker.description}</Typography>
            <Typography variant="body1">Date: {dayjs(speaker.date).format('DD/MM/YYYY')}</Typography>
            <Typography variant="body1">Slot: {speaker.slot}</Typography>
            <Stack direction="row" spacing={1} alignItems="center" mt={2}>
              <Typography variant="body1">Presentation:</Typography>
              <Tooltip title="Download Presentation">
                <span>
                  <IconButton color="success" component={Link} href={speaker.presentationId} target="_blank" rel="noopener noreferrer" disabled={!speaker.presentationId}>
                    <GetAppIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Copy Presentation URL">
                <span>
                  <IconButton color="primary" onClick={() => copyToClipboard(speaker.presentationId)} disabled={!speaker.presentationId}>
                    <FileCopyIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" mt={2}>
              <Typography variant="body1">Photo:</Typography>
              <Tooltip title="Download Photo">
                <span>
                  <IconButton color="success" component={Link} href={speaker.photoId} target="_blank" rel="noopener noreferrer" disabled={!speaker.photoId}>
                    <GetAppIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Copy Photo URL">
                <span>
                  <IconButton color="primary" onClick={() => copyToClipboard(speaker.photoId)} disabled={!speaker.photoId}>
                    <FileCopyIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
            {speaker.photoId && <img src={speaker.photoId} alt={speaker.name} width="100" style={{ marginTop: '16px' }} />}
          </div>
        )}
      </CardContent>
      <CardActions>
        {isEditing ? (
          <>
            <Button variant="contained" color="success" onClick={handleSave}>
              Save
            </Button>
            <Button variant="contained" color="primary" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button variant="contained" color="primary" onClick={handleEditClick}>
              Edit
            </Button>
            <Button variant="contained" color="error" onClick={() => deleteSpeaker(speaker._id,speaker.status)}>
              Delete
            </Button>
          </>
        )}
        {/* Add Submit button */}
        {!isEditing && (
          <Button
            variant="contained"
            color="success"
            onClick={handleSubmit}
            disabled={formData.status === "1"} // Disable unless all inputs are filled
          >
            Submit
          </Button>
        )}
      </CardActions>
      {/* Submit Confirmation Dialog */}
      <Dialog
        open={submitDialogOpen}
        onClose={handleCloseSubmitDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Submit Speaker"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit this Speaker to the app? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSubmitDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmSubmitSpeaker} color="error" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="copied To Clipboard"
        action={action}
      />
    </Card>
    
  );
};

export default SpeakerCard;
