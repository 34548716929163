// EditProductModal.js
import React from 'react';
import { Modal, Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const EditProductModal = ({
  open,
  handleClose,
  currentProduct,
  setCurrentProduct,
  handleEditSubmit,
  handlePhotoUpload,
  photo,
  progress
}) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-product-title"
      aria-describedby="edit-product-description"
      sx={{ zIndex: 1300 }} // Ensure the modal is above other content
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '60%' },
          maxHeight: '90%',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          outline: 'none',
        }}
      >
        <Typography
          id="edit-product-title"
          variant="h5"
          component="h2"
          sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
        >
          Edit Product
        </Typography>

        <form onSubmit={handleEditSubmit}>
          <TextField
            label="Title"
            value={currentProduct.name}
            onChange={(e) => setCurrentProduct({ ...currentProduct, name: e.target.value })}
            fullWidth
            margin="normal"
            variant="outlined"
            sx={{ mb: 1 }}
          />

          <TextField
            label="Description"
            value={currentProduct.description}
            onChange={(e) => setCurrentProduct({ ...currentProduct, description: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            variant="outlined"
            sx={{ mb: 1 }}
          />

          <TextField
            label="Category"
            value={currentProduct.category}
            onChange={(e) => setCurrentProduct({ ...currentProduct, category: e.target.value })}
            fullWidth
            margin="normal"
            variant="outlined"
            sx={{ mb: 1 }}
          />

          <TextField
            label="URL"
            value={currentProduct.link}
            disabled
            fullWidth
            margin="normal"
            variant="outlined"
            sx={{ mb: 1 }}
          />

          <Box sx={{ mt: 1 }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-photo"
              type="file"
              onChange={handlePhotoUpload}
            />
            <label htmlFor="upload-photo">
              <Button
                startIcon={<CloudUploadIcon />}
                variant="outlined"
                component="span"
                fullWidth
                sx={{
                  py: 1.5,
                  fontWeight: 'bold',
                  borderColor: 'primary.main',
                }}
              >
                Upload Photo
              </Button>
            </label>
            {photo && (
              <Typography sx={{ mt: 1, fontSize: '0.9rem', color: 'text.secondary' }}>
                {photo.name}
              </Typography>
            )}
          </Box>

          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel id="status-select-label">Select Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status"
              value={currentProduct.deleted}
              onChange={(e) => setCurrentProduct({ ...currentProduct, deleted: e.target.value })}
              label="Select Status"
            >
              <MenuItem value="0">Available</MenuItem>
              <MenuItem value="1">Not Available</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ py: 1.5, px: 4, fontWeight: 'bold' }}
            >
              Submit
            </Button>
          </Box>

          {progress && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress />
            </Box>
          )}
        </form>
      </Box>
    </Modal>
  );
};

export default EditProductModal;
