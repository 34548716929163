import React, { useState, useEffect,useRef } from 'react';
import {Box, Typography, Button, Modal, IconButton, Tooltip, TextField, Divider,useMediaQuery} from '@mui/material';
import {Add as AddIcon, Edit as EditIcon, Visibility as VisibilityIcon, CheckCircle as CheckCircleIcon, Warning as WarningIcon, HourglassEmpty as HourglassEmptyIcon,} from '@mui/icons-material';
import moment from 'moment';
import {FormControl,Select, MenuItem,InputLabel,InputAdornment,Grid} from '@mui/material';
import { AttachMoney as AttachMoneyIcon, Percent as PercentIcon, Calculate as CalculateIcon } from '@mui/icons-material';
import countries from './countries'; // Import your country list
import { Autocomplete } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import axios from 'axios';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CancelIcon from '@mui/icons-material/Cancel';
import NotesIcon from '@mui/icons-material/Notes';
import { Comment, Send, Close } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
const StageEditModal = ({
  open,
  onClose,
  currentParticipant,
  setCurrentParticipant,
  handleEditSubmit,
}) => {
  const [selectedStageIndex, setSelectedStageIndex] = useState(null);
  const [isViewing, setIsViewing] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [services, setServices] = useState([]);
  const [stageFormData, setStageFormData] = useState({});
  const [newStage, setNewStage] = useState({
    currentStage: 'Open',
    status: 'Active',
    notQualifiedReason: '',
    appliedStatus: '',
    finalizedStatus: '',
    tuitionFees: '',
    commissionPercent: '',
    invoiceStatus: '',
    university: '',
    course: '',
    country: '',
    provider: '',
    dateTime: [moment().format('MMMM Do YYYY, h:mm:ss a')],
  });
  const modalContentRef = useRef(null);

  useEffect(() => {
    if (selectedStageIndex !== null && !isViewing && !openNotes) {
      // Scroll to the top of the modal
      if (modalContentRef.current) {
     //   modalContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });

      }
      //onClose();
    }
  }, [selectedStageIndex, isViewing]);

  const snapshotRef = useRef(null);//


  const fetchServices = async () => {
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getservices',{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

      }
      });
      setServices(response.data.reverse());
    } catch (error) {
        console.error('Error fetching services:', error);  // Log the error for debugging

    }
  };
  useEffect(() => {
    fetchServices();
  }, []);

///
  const handleAddNewStage = () => {
    const updatedStages = [newStage,...currentParticipant.stages];
    setCurrentParticipant({
      ...currentParticipant,
      stages: updatedStages,
    });
    setNewStage({
      ...newStage,
      dateTime: [moment().format('MMMM Do YYYY, h:mm:ss a')],
    });
  };

  const handleStageChange = (field, value) => {
    setStageFormData({ ...stageFormData, [field]: value });
  };

  const handleOpenEdit = (stage, index) => {
    setSelectedStageIndex(index);
    setStageFormData(stage);
    setIsViewing(false); // Editing mode
  };

  const handleOpenView = (stage) => {
    setStageFormData(stage);
    setIsViewing(true); // Viewing mode
  };
  const handleOpenNotes = (stage,index) => {
    setStageFormData(stage);
    setSelectedStageIndex(index);
    setOpenNotes(true); // Viewing mode
  };

  const handleCloseEdit = () => {
    setSelectedStageIndex(null);
    setStageFormData({});
  };

  const handleCloseView = () => {
    setIsViewing(false);
    setStageFormData({});
  };
  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;  // Ensure comment is not empty

    // Create a new comment object with timestamp
    const commentWithTimestamp = {
        text: newComment,
        dateTime: moment().format('MMMM Do YYYY, h:mm:ss a'), // Current timestamp
    };

    // Append the new comment to the participant's existing comments
    const updatedComments = [...(stageFormData.comment || []), commentWithTimestamp];

    // Create a new object with the updated comments
    const updatedParticipantData = {
        ...stageFormData,
        comment: updatedComments,
    };

    // Update the current participant's comments in state
    setStageFormData(updatedParticipantData);
    const updatedStages = currentParticipant.stages.map((stage, index) => (
      index === selectedStageIndex
          ? {
              ...stage,
              ...updatedParticipantData,
              dateTime: [...stage.dateTime, moment().format('MMMM Do YYYY, h:mm:ss a')],
          }
          : stage
  ));

  // Use callback in setState to ensure the updated state is used
  setCurrentParticipant((prevState) => {
      const updatedParticipant = { ...prevState, stages: updatedStages };

      // Call the submit function here, which will now have the updated participant data
      handleEditSubmit(updatedParticipant);

      return updatedParticipant;
  });
 //handleCommentClose()
 setNewComment("")


   
    // Close the modal after submitting the comment
  };

  const handleSaveChanges = () => {
    const updatedStages = currentParticipant.stages.map((stage, index) => (
        index === selectedStageIndex
            ? {
                ...stage,
                ...stageFormData,
                dateTime: [...stage.dateTime, moment().format('MMMM Do YYYY, h:mm:ss a')],
            }
            : stage
    ));

    // Use callback in setState to ensure the updated state is used
    setCurrentParticipant((prevState) => {
        const updatedParticipant = { ...prevState, stages: updatedStages };

        // Call the submit function here, which will now have the updated participant data
        handleEditSubmit(updatedParticipant);

        return updatedParticipant;
    });

    handleCloseEdit();
};

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Active':
        return <CheckCircleIcon sx={{ color: 'green' }} />;
      case 'Normal':
        return <HourglassEmptyIcon sx={{ color: 'orange' }} />;
      case 'Non Active':
        return <WarningIcon sx={{ color: 'red' }} />;
      default:
        return <HourglassEmptyIcon sx={{ color: 'gray' }} />;
    }
  };
  const getStatusColor2 = (Active) => {
    switch (Active) {
      case 'Active':
        return 'green';
      case 'Normal':
        return 'orange';
      case 'Non Active':
        return 'red';
        default:
          return 'gray';
    }
  };
  const finalAmount = stageFormData && stageFormData.tuitionFees != null && stageFormData.commissionPercent != null
    ? ((stageFormData.tuitionFees * (stageFormData.commissionPercent / 100)).toFixed(2))
    : '0.00';

    const handleDestinationsChange = (event, value) => {
        setStageFormData((prev) => ({
          ...prev,
          institutionCountry: value, // Update preferred destinations with the new selected values
        }));
      };

      const getStatusIcon2 = (status) => {
        switch (status) {
          case 'Hot':
            return <WhatshotIcon style={{ color: 'orange', marginLeft: 8, marginRight: 4 }} />;
          case 'Cold':
            return <AcUnitIcon style={{ color: 'blue', marginLeft: 8, marginRight: 4 }} />;
          case 'Warm':
            return <HourglassBottomIcon style={{ color: 'gold', marginLeft: 8, marginRight: 4 }} />;
          default:
            return <LockOpenIcon style={{ color: 'gray', marginLeft: 8, marginRight: 4 }} />;
        }
      };
      
      const getStatusColor = (status) => {
        switch (status) {
          case 'Hot':
            return 'green';
          case 'Cold':
            return 'blue';
          case 'Warm':
            return 'orange';
          default:
            return 'gray';
        }
      };
       
      const getFlagEmoji = (countryCode) =>
        typeof String.fromCodePoint !== 'undefined'
          ? countryCode
              .toUpperCase()
              .replace(/./g, (char) =>
                String.fromCodePoint(char.charCodeAt(0) + 127397)
              )
          : null;  
       
     
          const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
          ];
          
          const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));


          const handleCommentClose = () => {
            setStageFormData({});
            setSelectedStageIndex(null)
            setNewComment('');
            setOpenNotes(false);
          };

          const takeSnapshot = () => {
            if (snapshotRef.current) {
              html2canvas(snapshotRef.current).then(canvas => {
                const imgData = canvas.toDataURL('image/png'); // Use a valid image format
                const pdf = new jsPDF('p', 'mm', 'a4'); // Create a new jsPDF instance
                
                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();
                
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
                const scaleFactor = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
                
                const x = (pageWidth - imgWidth * scaleFactor) / 2;
                const y = (pageHeight - imgHeight * scaleFactor) / 2;
          
                pdf.addImage(imgData, 'PNG', x, y, imgWidth * scaleFactor, imgHeight * scaleFactor);
          
                const filename = `${currentParticipant.f_name}-${currentParticipant._id}.pdf`;
                pdf.save(filename); // Save as PDF
              });
            }
          };


  return (
    <Modal open={open} onClose={onClose}  sx={{ zIndex: 1300 }}>
      <Box
        ref={modalContentRef}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 700,
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          maxHeight: '85%',
        }}
      >
      {selectedStageIndex == null && !isViewing && !openNotes&& (<>

        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}>
          Stages for {currentParticipant?.f_name}
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>All Stages</Typography>
          <Tooltip title="Add New Stage">
            <IconButton onClick={handleAddNewStage}>
              <AddIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>

        <Divider sx={{ mb: 3 }} />

        {currentParticipant?.stages?.map((stage, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: 2,
              border: '1px solid #e0e0e0',
              backgroundColor: '#f9f9f9',
              p: 2,
              mb: 2,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {stage?.currentStage}
              </Typography>
              <Typography variant="body1">
              <strong>Status:</strong> {stage?.status || 'N/A'} {getStatusIcon(stage?.status)}
              </Typography>
              <Typography variant="body1"><strong>Current Stage:</strong> {stage?.currentStage || 'N/A'}</Typography>
              <Typography variant="body1"><strong>University:</strong> {stage?.institutionName || 'N/A'}</Typography>
              <Typography variant="body1"><strong>Program:</strong> {stage?.programName || 'N/A'}</Typography>
              <Typography variant="body1"><strong>Intake Year:</strong> {currentParticipant?.intakeYear || 'N/A'}</Typography>
              <Typography variant="body1">
  <strong>Intake Month:</strong> {currentParticipant?.intakeMonth ? monthNames[currentParticipant.intakeMonth - 1] : 'N/A'}
</Typography>              <Typography variant="body1"><strong>Provider:</strong> {stage?.providerName || 'N/A'}</Typography>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
  <strong>Institution Country:</strong> 
  {stage?.institutionCountry?.label && stage?.institutionCountry?.code 
    ? <>
        {getFlagEmoji(stage.institutionCountry.code)}&nbsp;
        {stage.institutionCountry.label}
      </>
    : 'N/A'}
</Typography> 


              <Typography variant="body1"><strong>Last Modified:</strong> {stage?.dateTime[stage.dateTime.length - 1]}</Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Tooltip title="Edit Stage">
                <IconButton onClick={() => handleOpenEdit(stage, index)}>
                  <EditIcon color="action" />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Details">
                <IconButton onClick={() => handleOpenView(stage)}>
                  <VisibilityIcon color="action" />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Notes">
                <IconButton onClick={() => handleOpenNotes(stage,index)}>
                  <NotesIcon color="action" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ))}
        </>)}
      
<Modal open={openNotes} onClose={handleCommentClose}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: 600,
      bgcolor: 'background.paper',
      borderRadius: 3,
      boxShadow: 24,
      maxHeight: '90vh',
      overflowY: 'auto',
      p: 4,
    }}
  >
    {/* Close Button */}
    <IconButton 
      onClick={handleCommentClose} 
      sx={{ position: 'absolute', top: 16, right: 16, color: 'grey.600' }}>
      <Close />
    </IconButton>

    {/* Title with Comment Icon */}
    <Box display="flex" alignItems="center" mb={3}>
      <Comment sx={{ color: 'primary.main', fontSize: 28, mr: 1 }} />
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Stage Notes
      </Typography>
    </Box>

    <Divider sx={{ mb: 3 }} />

    {/* Display Existing Comments */}
    {stageFormData?.comment?.length > 0 ? (
      stageFormData.comment
        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)) // Order by date
        .map((comment, index) => (
          <Box
            key={index}
            sx={{
              mb: 2,
              p: 2,
              border: '1px solid #eee',
              borderRadius: 2,
              backgroundColor: '#f9f9f9',
              wordWrap: 'break-word',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
              {comment.text}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
              {comment.dateTime}
            </Typography>
          </Box>
        ))
    ) : (
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
        No Notes yet.
      </Typography>
    )}

    <Divider sx={{ my: 3 }} />

    {/* Input Field for New Comment */}
    <TextField
      label="Add a Note"
      value={newComment}
      multiline
      rows={4}
      onChange={(e) => setNewComment(e.target.value)}
      fullWidth
      required
      margin="normal"
      variant="outlined"
      InputProps={{
        sx: { borderRadius: 2, maxWidth: '100%', width: '100%' },
        endAdornment: (
          <IconButton   disabled={newComment.trim() === ""} // Disable if newComment is empty or only spaces
           onClick={handleCommentSubmit} color="primary">
            <Send />
          </IconButton>
        ),
      }}
      InputLabelProps={{ sx: { fontWeight: 'bold' } }}
    />    
  </Box>
</Modal>
              
          

        {/* Viewing Modal */}
        {isViewing && (
        
          <Modal open={isViewing} onClose={handleCloseView} sx={{ zIndex: 1400,backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isSmallScreen ? '90%' : '80%',
              maxHeight: '90vh',
              overflowY: 'auto',
              p: 4,
              bgcolor: 'background.paper',
              boxShadow: 24,
              bgcolor: 'rgba(255, 255, 255, 0.8)', // Makes the content box semi-transparent
            }}
          >
            <IconButton 
      onClick={handleCloseView} 
      sx={{ position: 'absolute', top: 10, right: 10, color: 'grey.600' }}>
      <Close />
    </IconButton>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <IconButton color="primary" onClick={takeSnapshot}>
          <CameraAltIcon />
        </IconButton>
      </Box>
      <div ref={snapshotRef}>
            <Typography 
  variant="h5" 
  sx={{ 
    mb: 3, 
    fontWeight: 'bold', 
    textAlign: 'center', 
    backgroundColor: 'white', 
    py: 2, // Adding some padding for better spacing
    borderRadius: 2, // Optional: Adding rounded corners for better aesthetics
    boxShadow: 1 // Optional: Adding a subtle shadow for more depth
  }}
>
  Student & Application Details
</Typography>

            <Grid container spacing={3} direction={isSmallScreen ? 'column' : 'row'}>
              {/* Student Information Card */}
              <Grid item xs={12} sm={6} lg={4}>
              <Card 
  sx={{ 
    borderRadius: 3,  // Rounded corners
    boxShadow: 3,     // Add shadow to give depth
    backgroundColor: 'white', // Background color
    transition: '0.3s', // Smooth transition for hover effects
    '&:hover': {       // Hover effect to elevate the card
      boxShadow: 6,
      transform: 'scale(1.02)', // Slight zoom on hover
    }
  }}
>                  <CardContent>
        
            {/* Student Information Section */}
  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, display: 'flex', alignItems: 'center' }}>
    Student Information
    <PersonIcon sx={{ ml: 1 }} />
  </Typography>
  <Divider sx={{ mb: 2 }} />

  {/* Student ID */}
  <Typography variant="body1" sx={{ mb: 1 }}>
    <strong>Student ID:</strong> {currentParticipant?._id || 'N/A'}
  </Typography>

  {/* Name */}
  <Typography variant="body1" sx={{ mb: 1 }}>
    <strong>Name:</strong> {`${currentParticipant?.f_name || 'N/A'} ${currentParticipant?.l_name || ''}`}
  </Typography>

  {/* Date of Birth */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.birthdate ? 'black' : 'red' }}>
    <strong>Date of Birth:</strong> {currentParticipant?.birthdate || 'Not Provided'}
  </Typography>

  {/* Mobile */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.mobile ? 'black' : 'red' }}>
    <strong>Mobile:</strong> {currentParticipant?.mobile || 'Not Provided'}
  </Typography>

  {/* Email */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.email ? 'black' : 'red' }}>
    <strong>Email:</strong> {currentParticipant?.email || 'Not Provided'}
  </Typography>

  {/* Financial Source */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.financialSource ? 'black' : 'red' }}>
    <strong>Financial Source:</strong> {currentParticipant?.financialSource || 'N/A'}
  </Typography>

  {/* Desired Fields */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.studyFields?.length ? 'black' : 'red' }}>
    <strong>Desired Fields:</strong> {currentParticipant?.studyFields?.length ? currentParticipant.studyFields.map(field => field.label).join(', ') : 'N/A'}
  </Typography>

  {/* Desired Destinations */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.preferredDestinations?.length ? 'black' : 'red' }}>
    <strong>Desired Destinations:</strong>
    {Array.isArray(currentParticipant?.preferredDestinations) && currentParticipant.preferredDestinations.length
      ? currentParticipant.preferredDestinations.map((destination) => 
        `${destination?.code ? getFlagEmoji(destination.code) + ' ' : ''}${destination?.label || 'N/A'}`
        ).join(', ')
      : 'N/A'}
  </Typography>

  {/* Preferred Start */}
  <Typography variant="body1" sx={{ mb: 1, color: (currentParticipant?.intakeMonth && currentParticipant?.intakeYear) ? 'black' : 'red' }}>
    <strong>Preferred Start:</strong> {currentParticipant?.intakeMonth ? monthNames[currentParticipant.intakeMonth - 1] : 'N/A'}, {currentParticipant?.intakeYear || ''}
  </Typography>

  {/* Country of Residence */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.ResidenceCountry?.label ? 'black' : 'red' }}>
    <strong>Country of Residence:</strong> {currentParticipant?.ResidenceCountry?.label || 'N/A'}
  </Typography>

  {/* Nationality */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.country ? 'black' : 'red' }}>
    <strong>Nationality:</strong> {currentParticipant?.country || 'N/A'}
  </Typography>

  {/* Highest Education Level */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.highestEducationLevel ? 'black' : 'red' }}>
    <strong>Highest Education Level:</strong> {currentParticipant?.highestEducationLevel || 'N/A'}
  </Typography>

  {/* Preferred Study Level */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.preferredStudyLevel ? 'black' : 'red' }}>
    <strong>Preferred Study Level:</strong> {currentParticipant?.preferredStudyLevel || 'N/A'}
  </Typography>

  {/* English Certificate */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.englishLevel ? 'black' : 'red' }}>
    <strong>English Certificate:</strong> {currentParticipant?.englishLevel || 'N/A'}
  </Typography>

  {/* Budget */}
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.budget ? 'black' : 'red' }}>
    <strong>Budget:</strong> {currentParticipant?.budget ? `$${currentParticipant.budget}` : 'N/A'}
  </Typography>

  {/* Status */}
  <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: getStatusColor(currentParticipant?.status) }}>
    <strong>Status:</strong>
    {currentParticipant?.status ? getStatusIcon2(currentParticipant.status) : null}
    {currentParticipant?.status || 'N/A'}
  </Typography>
  </CardContent>
                </Card>
              </Grid>
        
            {/* Application Information Section */}
            <Grid item xs={12} sm={6} lg={4}>
            <Card 
  sx={{ 
    borderRadius: 3,  // Rounded corners
    boxShadow: 3,     // Add shadow to give depth
    backgroundColor: 'white', // Background color
    transition: '0.3s', // Smooth transition for hover effects
    '&:hover': {       // Hover effect to elevate the card
      boxShadow: 6,
      transform: 'scale(1.02)', // Slight zoom on hover
    }
  }}
>                  <CardContent>  
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, display: 'flex', alignItems: 'center' }}>
    Application Information
    <SchoolIcon sx={{ ml: 1 }} />
  </Typography>
  <Divider sx={{ mb: 2 }} />

  {/* Application Status */}
  <Typography variant="body1" sx={{ mb: 1, display: 'flex', alignItems: 'center', color: getStatusColor2(stageFormData?.status) }}>
    <strong>Application Status:</strong>
    {getStatusIcon(stageFormData?.status)}
    {stageFormData?.status || 'N/A'}
  </Typography>

  {/* Current Stage */}
  <Typography variant="body1" sx={{ mb: 1 }}>
    <strong>Current Stage:</strong> {stageFormData?.currentStage || 'N/A'}
  </Typography>
  {stageFormData?.currentStage === 'Not Qualified'  && (
    <Typography variant="body1" sx={{ mb: 1 }}>
    <strong>Not Qualified Reason:</strong> {stageFormData?.notQualifiedReason || 'N/A'}
  </Typography>
  )}
  {stageFormData?.currentStage === 'Applied'  && (
   <Typography 
   variant="body1" 
   sx={{ 
     mb: 1, 
     display: 'flex', 
     alignItems: 'center',
     color: (() => {
       if (stageFormData?.appliedStatus === 'In Progress') return 'orange';
       if (['Submitted', 'Accepted Offer'].includes(stageFormData?.appliedStatus)) return 'green';
       if (['Cancelled', 'Rejected Offer'].includes(stageFormData?.appliedStatus)) return 'red';
       return 'black';
     })(),
     textDecoration: ['Cancelled', 'Rejected Offer'].includes(stageFormData?.appliedStatus) ? 'line-through' : 'none',
   }}
 >
  <strong>Applicant Stage:</strong> 
   {(() => {
     switch (stageFormData?.appliedStatus) {
       case 'In Progress':
         return <HourglassBottomIcon sx={{ mr: 1 }} />;
       case 'Submitted':
       case 'Accepted Offer':
         return <CheckCircleIcon sx={{ mr: 1 }} />;
       case 'Cancelled':
       case 'Rejected Offer':
         return <CancelIcon sx={{ mr: 1 }} />;
       default:
         return null;
     }
   })()}
   {stageFormData?.appliedStatus || 'N/A'}
 </Typography>
  )}
  {stageFormData?.currentStage === 'Finalized'  && (
    <Typography variant="body1" sx={{ mb: 1 }}>
    <strong>Finalized Status:</strong> {stageFormData?.finalizedStatus || 'N/A'}
  </Typography>
  )}
    {stageFormData?.currentStage === 'Visa'  && (
    <Typography variant="body1" sx={{ 
      mb: 1, 
      display: 'flex', 
      alignItems: 'center',
      color: (() => {
        if (stageFormData?.visaStatus === 'Visa Submitted') return 'orange';
        if (['Visa Approved'].includes(stageFormData?.visaStatus)) return 'green';
        if (['Visa Rejected'].includes(stageFormData?.visaStatus)) return 'red';
        return 'black';
      })(),
      textDecoration: ['Visa Rejected'].includes(stageFormData?.visaStatus) ? 'line-through' : 'none',
    }}>
    <strong>Visa Status:</strong> 
    {(() => {
     switch (stageFormData?.visaStatus) {
       case 'Visa Submitted':
         return <HourglassBottomIcon sx={{ mr: 1 }} />;
       case 'Visa Approved':
         return <CheckCircleIcon sx={{ mr: 1 }} />;
       case 'Visa Rejected':
         return <CancelIcon sx={{ mr: 1 }} />;
       default:
         return null;
     }
   })()}
   {stageFormData?.visaStatus || 'N/A'}
  </Typography>
  )}
  {stageFormData?.currentStage === 'Commission Tracking'  && (
    <>
    <Typography variant="body1" sx={{ 
      mb: 1, 
      display: 'flex', 
      alignItems: 'center',
      color: (() => {
        if (stageFormData?.invoiceStatus === 'Pending') return 'orange';
        if (['Paid'].includes(stageFormData?.invoiceStatus)) return 'green';
        if (['Rejected'].includes(stageFormData?.invoiceStatus)) return 'red';
        return 'black';
      })(),
      textDecoration: ['Rejected'].includes(stageFormData?.invoiceStatus) ? 'line-through' : 'none',
    }}>
    <strong>Commission Status:</strong> 
    
    {(() => {
     switch (stageFormData?.invoiceStatus) {
       case 'Pending':
         return <HourglassBottomIcon sx={{ mr: 1 }} />;
       case 'Paid':
         return <CheckCircleIcon sx={{ mr: 1 }} />;
       case 'Rejected':
         return <CancelIcon sx={{ mr: 1 }} />;
       default:
         return null;
     }
   })()}
    
    {stageFormData?.invoiceStatus || 'N/A'}
  </Typography>
  <Typography variant="body1" sx={{ mb: 1 }}>
    <strong>Finalized Status:</strong> {stageFormData?.finalizedStatus || 'N/A'}
  </Typography>
</>
  )}

  {/* Institution Name */}
  <Typography variant="body1" sx={{ mb: 1, color: stageFormData?.institutionName ? 'black' : 'red' }}>
    <strong>Institution Name:</strong> {stageFormData?.institutionName || 'N/A'}
  </Typography>

  {/* Institution Country */}
  <Typography variant="body1" sx={{ mb: 1, display: 'flex', alignItems: 'center', color: stageFormData?.institutionCountry?.label ? 'black' : 'red' }}>
    <strong>Institution Country:</strong>
    {stageFormData?.institutionCountry?.label ? (
      <>
        {stageFormData?.institutionCountry?.code ? getFlagEmoji(stageFormData.institutionCountry.code) : ''}&nbsp;
        {stageFormData.institutionCountry.label}
      </>
    ) : 'N/A'}
  </Typography>

  {/* Program Name */}
  <Typography variant="body1" sx={{ mb: 1, color: stageFormData?.programName ? 'black' : 'red' }}>
    <strong>Program Name:</strong> {stageFormData?.programName || 'N/A'}
  </Typography>

  {/* Program ID */}
  <Typography variant="body1" sx={{ mb: 1, color: stageFormData?.programID ? 'black' : 'red' }}>
    <strong>Program ID:</strong> {stageFormData?.programID || 'N/A'}
  </Typography>

  {/* Application ID */}
  <Typography variant="body1" sx={{ mb: 1, color: stageFormData?.applicationID ? 'black' : 'red' }}>
    <strong>Application ID:</strong> {stageFormData?.applicationID || 'N/A'}
  </Typography>

  {/* Provider Name */}
  <Typography variant="body1" sx={{ mb: 1, color: stageFormData?.providerName ? 'black' : 'red' }}>
    <strong>Provider Name:</strong> {stageFormData?.providerName || 'N/A'}
  </Typography>
  </CardContent>
                </Card>
              </Grid>
              
              <Grid item xs={12} sm={6} lg={4}>
              <Card 
  sx={{ 
    borderRadius: 3,  // Rounded corners
    boxShadow: 3,     // Add shadow to give depth
    backgroundColor: 'white', // Background color
    transition: '0.3s', // Smooth transition for hover effects
    '&:hover': {       // Hover effect to elevate the card
      boxShadow: 6,
      transform: 'scale(1.02)', // Slight zoom on hover
    }
  }}
>                  <CardContent>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, display: 'flex', alignItems: 'center' }}>
    Financial Information
    <AttachMoneyIcon sx={{ ml: 1 }} />
  </Typography>
  <Divider sx={{ mb: 2 }} />

  {/* Fee Agreement */}
  <Typography variant="body1" sx={{ mb: 1, color: stageFormData?.FeeAgreementwithPartnerInstitution ? 'black' : 'red' }}>
    <strong>Fee Agreement:</strong> {stageFormData?.FeeAgreementwithPartnerInstitution || 'Not Provided'}
  </Typography>

  {/* Partner University/Pathway Program */}
  <Typography variant="body1" sx={{ mb: 1, color: stageFormData?.PartnerUniversityPathwayProgram ? 'black' : 'red' }}>
    <strong>Partner University/Pathway Program:</strong> {stageFormData?.PartnerUniversityPathwayProgram || 'N/A'}
  </Typography>

  {/* Tuition Fees */}
  <Typography variant="body1" sx={{ mb: 1, color: stageFormData?.tuitionFees ? 'black' : 'red' }}>
    <strong>Tuition Fees:</strong> {stageFormData?.tuitionFees ? `$${stageFormData?.tuitionFees}` : 'N/A'}
  </Typography>

  {/* Commission Percent */}
  <Typography variant="body1" sx={{ mb: 1, color: stageFormData?.commissionPercent ? 'black' : 'red' }}>
    <strong>Commission %:</strong> {stageFormData?.commissionPercent ? `${stageFormData?.commissionPercent}%` : 'N/A'}
  </Typography>
  <Typography variant="body1" sx={{ mb: 1, color: 'black'}}>
    <strong> Final Amount (USD):</strong> ${finalAmount}
  </Typography>
  </CardContent>
                </Card>
              </Grid>
            </Grid>
            </div>
          </Box>
        </Modal>
        
        )}

        {/* Editing Modal */}
        {selectedStageIndex !== null && !isViewing && !openNotes&& (
        //  <Modal open={selectedStageIndex !== null} onClose={handleCloseEdit} disablePortal // Ensure modal content is rendered within the root DOM
          // sx={{ zIndex: 4000 }} // Increase zIndex of the Modal
         // >


            <Box
              sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: 2,
              border: '1px solid #e0e0e0',
              backgroundColor: '#f9f9f9',
              p: 2,
              mb: 2,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box>
            
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <IconButton 
      onClick={handleCloseEdit} 
      sx={{ position: 'absolute', top: 30, right: 30, color: 'grey.600' }}>
      <Close />
    </IconButton>

              <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold' }}>
                Edit Stage: {stageFormData.currentStage}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Application Information</Typography>
      <Divider sx={{ mb: 2 }} />
              <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Application Status</InputLabel>
              <Select
                label="Application Status"
                value={stageFormData?.status || ''}
                onChange={(e) => handleStageChange('status', e.target.value)}
              
                >
              <MenuItem  value="Active">Active</MenuItem>
              <MenuItem  value="Normal">Normal</MenuItem>
              <MenuItem  value="Non Active">Non Active</MenuItem>                 
              </Select>
            </FormControl>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Stage</InputLabel>
              <Select
                label="Stage"
                value={stageFormData?.currentStage || ''}
                onChange={(e) => handleStageChange('currentStage', e.target.value)}
                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}
                >
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="Not Qualified">Not Qualified</MenuItem>
              <MenuItem value="Qualified">Qualified</MenuItem>                 
              <MenuItem value="Applied">Applied</MenuItem>
              <MenuItem value="Finalized">Finalized</MenuItem>
              <MenuItem value="Visa">Visa</MenuItem>
              <MenuItem value="Commission Tracking">Commission Tracking</MenuItem>
              </Select>
            </FormControl>
            {stageFormData?.currentStage === 'Not Qualified'  && (
              <FormControl variant="outlined"  required margin="normal" fullWidth>
              <InputLabel>Reason</InputLabel>
              <Select
                label="Reason"
                value={stageFormData?.notQualifiedReason || ''}
                onChange={(e) => handleStageChange('notQualifiedReason', e.target.value)}
                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Not Serious">Not Serious</MenuItem>
              <MenuItem value="Not Interested">Not Interested</MenuItem>
              <MenuItem value="Academic Requirments Not Met">Academic Requirments Not Met</MenuItem>                 
              <MenuItem value="Financial Reasons">Financial Reasons</MenuItem>
              <MenuItem value="Wrong Number">Wrong Number</MenuItem>
              <MenuItem value="Wrong Person">Wrong Person</MenuItem>
              <MenuItem value="Signed with other agent">Signed with other agent</MenuItem>
              <MenuItem value="Not Supported Destination">Not Supported Destination</MenuItem>

              </Select>
            </FormControl>
            )}
             {stageFormData?.currentStage === 'Applied'  && (
                <>
              <FormControl variant="outlined"  required margin="normal" fullWidth>
              <InputLabel>Applicant Stage</InputLabel>
              <Select
                label="Applicant Stage"
                value={stageFormData?.appliedStatus || ''}
                onChange={(e) => handleStageChange('appliedStatus', e.target.value)}
                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Submitted">Submitted</MenuItem>
              <MenuItem value="Accepted Offer">Accepted Offer</MenuItem>                 
              <MenuItem value="Rejected Offer">Rejected Offer</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
            
             {/* Student Info Section */}
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Student Information</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Student Name"
              value={`${currentParticipant?.f_name} ${currentParticipant?.l_name}`}
              disabled
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Student ID"
              value={currentParticipant?._id}
              disabled
            />
          </FormControl>
        </Grid> 
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Date of Birth"
              value={currentParticipant?.birthdate}
              disabled
              fullWidth
            />
          </FormControl>
          </Grid>
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Applicant Country"
              value={currentParticipant?.ResidenceCountry?.label || ""}
              disabled
              fullWidth
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

          <FormControl variant="outlined" required margin="normal" fullWidth>
              <InputLabel>Degree</InputLabel>
              <Select
                label="Degree"
                value={currentParticipant?.degree || ''}
                onChange={((e) => {
                    setCurrentParticipant({ ...currentParticipant, degree: e.target.value });
                  })}

                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Language School">Language School</MenuItem>
              <MenuItem value="Foundation">Foundation</MenuItem>
              <MenuItem value="Bachelor">Bachelor</MenuItem>
              <MenuItem value="Master">Master</MenuItem>
              <MenuItem value="Doctorate">Doctorate</MenuItem>

              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>

<FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Financial Source</InputLabel>
              <Select
                label="Financial Source"
                value={currentParticipant?.financialSource || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, financialSource: e.target.value })}
                >
                <MenuItem value="Self-Funded">Self-Funded</MenuItem>
                      <MenuItem value="Parents">Parents</MenuItem>
                      <MenuItem value="Scholarship">Scholarship</MenuItem>
                      <MenuItem value="Bank Loan">Bank Loan</MenuItem>
                      <MenuItem value="Employer Scholarship">Employer Scholarship</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
      <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['year']}
    label="Desired Year"
    // Initialize the value to the year if it exists or null
    value={currentParticipant?.intakeYear ? new Date(currentParticipant.intakeYear, 0) : null}
    onChange={(newDate) => {
      // Ensure we only save the year
      const year = newDate ? newDate.getFullYear() : null; // Extract year or set to null
      setCurrentParticipant({ ...currentParticipant, intakeYear: year });
    }}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    sx={{ width: '100%' }}
    minDate={new Date(new Date().getFullYear(), 0)} // Ensure only future years are selectable
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>
</Grid>

        {/* Month Picker */}
        <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['month']}
    label="Desired Month"
    // Initialize the value to a date object where the month is set (0-based for JS Date)
    value={currentParticipant?.intakeMonth ? new Date(2024, currentParticipant.intakeMonth - 1) : null}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    onChange={(newDate) => {
      // Extract the selected month and add 1 to convert from 0-11 to 1-12
      const month = newDate ? newDate.getMonth() + 1 : null;
      setCurrentParticipant({ ...currentParticipant, intakeMonth: month });
    }}
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>

</Grid>


      </Grid>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Institution Information</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Institution Name"
              value={stageFormData?.institutionName}
              onChange={(e) => handleStageChange('institutionName', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

          <Autocomplete
              id="country-select-demo"
              options={countries}
              getOptionLabel={(option) => option.label} // Show country name as label
              value={stageFormData?.institutionCountry || null} // Bind selected countries from currentParticipant
              onChange={handleDestinationsChange} // Handle change to update currentParticipant's destinations
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
               <img
                 loading="lazy"
                 width="20"
                 srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                 src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                 alt=""
               />
               {option.label} 
             </Box>                
            )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Institution Country"
                  placeholder="Institution Country"
                  margin="normal"
                />
              )}
              isOptionEqualToValue={(option, value) => option.label === value.label} // Ensure values are matched by name
            />
                      </Grid>
                      <Grid item xs={12} sm={6}>

               <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Program Name"
              value={stageFormData?.programName}
              onChange={(e) => handleStageChange('programName', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

               <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Program ID"
              value={stageFormData?.programID}
              onChange={(e) => handleStageChange('programID', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Application ID"
              value={stageFormData?.applicationID}
              onChange={(e) => handleStageChange('applicationID', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" required margin="normal" fullWidth>
  <InputLabel>Provider Name</InputLabel>
  <Select
    label="Provider Name"
    value={stageFormData?.providerName || ''}
    onChange={(e) => handleStageChange('providerName', e.target.value)}
    MenuProps={{
      PopperProps: {
        modifiers: [
          {
            name: 'zIndex',
            enabled: true,
            phase: 'afterWrite',
            fn: ({ state }) => {
              state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
            },
          },
        ],
      },
    }}
  >
    {services.map((service) => (
      <MenuItem key={service.id} value={service.name}>
        {service.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

            </Grid>
        </Grid>

          </>
          
            )}
            {stageFormData?.currentStage === 'Finalized'  && (
                <>
              <FormControl variant="outlined" required margin="normal" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={stageFormData?.finalizedStatus || ''}
                onChange={(e) => handleStageChange('finalizedStatus', e.target.value)}
                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Conditional Offer">Conditional Offer</MenuItem>
              <MenuItem value="Unconditional Offer">Unconditional Offer</MenuItem>
              <MenuItem value="Withdraw">Withdraw</MenuItem>
              </Select>
            </FormControl> 
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Student Information</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Student Name"
              value={`${currentParticipant?.f_name} ${currentParticipant?.l_name}`}
              disabled
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Student ID"
              value={currentParticipant?._id}
              disabled
            />
          </FormControl>
        </Grid> 
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Date of Birth"
              value={currentParticipant?.birthdate}
              disabled
              fullWidth
            />
          </FormControl>
          </Grid>
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Applicant Country"
              value={currentParticipant?.ResidenceCountry?.label || ""}
              disabled
              fullWidth
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

          <FormControl variant="outlined" required margin="normal" fullWidth>
              <InputLabel>Degree</InputLabel>
              <Select
                label="Degree"
                value={currentParticipant?.degree || ''}
                onChange={((e) => {
                    setCurrentParticipant({ ...currentParticipant, degree: e.target.value });
                  })}

                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Language School">Language School</MenuItem>
              <MenuItem value="Foundation">Foundation</MenuItem>
              <MenuItem value="Bachelor">Bachelor</MenuItem>
              <MenuItem value="Master">Master</MenuItem>
              <MenuItem value="Doctorate">Doctorate</MenuItem>

              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>

<FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Financial Source</InputLabel>
              <Select
                label="Financial Source"
                value={currentParticipant?.financialSource || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, financialSource: e.target.value })}
                >
                <MenuItem value="Self-Funded">Self-Funded</MenuItem>
                      <MenuItem value="Parents">Parents</MenuItem>
                      <MenuItem value="Scholarship">Scholarship</MenuItem>
                      <MenuItem value="Bank Loan">Bank Loan</MenuItem>
                      <MenuItem value="Employer Scholarship">Employer Scholarship</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
      <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['year']}
    label="Desired Year"
    // Initialize the value to the year if it exists or null
    value={currentParticipant?.intakeYear ? new Date(currentParticipant.intakeYear, 0) : null}
    onChange={(newDate) => {
      // Ensure we only save the year
      const year = newDate ? newDate.getFullYear() : null; // Extract year or set to null
      setCurrentParticipant({ ...currentParticipant, intakeYear: year });
    }}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    sx={{ width: '100%' }}
    minDate={new Date(new Date().getFullYear(), 0)} // Ensure only future years are selectable
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>
</Grid>

        {/* Month Picker */}
        <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['month']}
    label="Desired Month"
    // Initialize the value to a date object where the month is set (0-based for JS Date)
    value={currentParticipant?.intakeMonth ? new Date(2024, currentParticipant.intakeMonth - 1) : null}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    onChange={(newDate) => {
      // Extract the selected month and add 1 to convert from 0-11 to 1-12
      const month = newDate ? newDate.getMonth() + 1 : null;
      setCurrentParticipant({ ...currentParticipant, intakeMonth: month });
    }}
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>

</Grid>


      </Grid>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Institution Information</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Institution Name"
              value={stageFormData?.institutionName}
              onChange={(e) => handleStageChange('institutionName', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

          <Autocomplete
              id="country-select-demo"
              options={countries}
              getOptionLabel={(option) => option.label} // Show country name as label
              value={stageFormData?.institutionCountry || null} // Bind selected countries from currentParticipant
              onChange={handleDestinationsChange} // Handle change to update currentParticipant's destinations
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
               <img
                 loading="lazy"
                 width="20"
                 srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                 src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                 alt=""
               />
               {option.label} 
             </Box>                
            )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Institution Country"
                  placeholder="Institution Country"
                  margin="normal"
                />
              )}
              isOptionEqualToValue={(option, value) => option.label === value.label} // Ensure values are matched by name
            />
                      </Grid>
                      <Grid item xs={12} sm={6}>

               <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Program Name"
              value={stageFormData?.programName}
              onChange={(e) => handleStageChange('programName', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

               <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Program ID"
              value={stageFormData?.programID}
              onChange={(e) => handleStageChange('programID', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Application ID"
              value={stageFormData?.applicationID}
              onChange={(e) => handleStageChange('applicationID', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" required margin="normal" fullWidth>
  <InputLabel>Provider Name</InputLabel>
  <Select
    label="Provider Name"
    value={stageFormData?.providerName || ''}
    onChange={(e) => handleStageChange('providerName', e.target.value)}
    MenuProps={{
      PopperProps: {
        modifiers: [
          {
            name: 'zIndex',
            enabled: true,
            phase: 'afterWrite',
            fn: ({ state }) => {
              state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
            },
          },
        ],
      },
    }}
  >
    {services.map((service) => (
      <MenuItem key={service.id} value={service.name}>
        {service.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

            </Grid>
        </Grid>
        <FormControl variant="outlined" required margin="normal" fullWidth>
            <TextField
            label="Tuition Fees"
            variant="outlined"
            fullWidth
            value={stageFormData?.tuitionFees || ''}
            onChange={(e) => handleStageChange('tuitionFees', e.target.value)}

            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
              inputMode: 'numeric',
            }}
            placeholder="Enter tuition fees"
            margin="normal"
          />
                      </FormControl>
                      <FormControl variant="outlined" required margin="normal" fullWidth>
                      <TextField
              label="Commission (%)"
              variant="outlined"
              fullWidth
              value={stageFormData?.commissionPercent || ''}
              onChange={(e) => handleStageChange('commissionPercent', e.target.value)}

              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PercentIcon />
                  </InputAdornment>
                ),
                inputMode: 'numeric',
              }}
              placeholder="Enter commission %"
              margin="normal"
            />
            </FormControl>
            <Box
              sx={{
                backgroundColor: '#e3f2fd',
                padding: 2,
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Final Amount (USD):
              </Typography>
              <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                <CalculateIcon sx={{ marginRight: 1 }} />
                ${finalAmount}
              </Typography>
            </Box>


          </>
            )}
             {stageFormData?.currentStage === 'Visa'  && (
                <>
              <FormControl variant="outlined"  required margin="normal" fullWidth>
              <InputLabel>Applicant Stage</InputLabel>
              <Select
                label="Applicant Stage"
                value={stageFormData?.visaStatus || ''}
                onChange={(e) => handleStageChange('visaStatus', e.target.value)}
                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Visa Submitted">Visa Submitted</MenuItem>
              <MenuItem value="Visa Approved">Visa Approved</MenuItem>
              <MenuItem value="Visa Rejected">Visa Rejected</MenuItem>
              </Select>
            </FormControl>
            
             {/* Student Info Section */}
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Student Information</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Student Name"
              value={`${currentParticipant?.f_name} ${currentParticipant?.l_name}`}
              disabled
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Student ID"
              value={currentParticipant?._id}
              disabled
            />
          </FormControl>
        </Grid> 
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Date of Birth"
              value={currentParticipant?.birthdate}
              disabled
              fullWidth
            />
          </FormControl>
          </Grid>
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Applicant Country"
              value={currentParticipant?.ResidenceCountry?.label || ""}
              disabled
              fullWidth
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

          <FormControl variant="outlined" required margin="normal" fullWidth>
              <InputLabel>Degree</InputLabel>
              <Select
                label="Degree"
                value={currentParticipant?.degree || ''}
                onChange={((e) => {
                    setCurrentParticipant({ ...currentParticipant, degree: e.target.value });
                  })}

                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Language School">Language School</MenuItem>
              <MenuItem value="Foundation">Foundation</MenuItem>
              <MenuItem value="Bachelor">Bachelor</MenuItem>
              <MenuItem value="Master">Master</MenuItem>
              <MenuItem value="Doctorate">Doctorate</MenuItem>

              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>

<FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Financial Source</InputLabel>
              <Select
                label="Financial Source"
                value={currentParticipant?.financialSource || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, financialSource: e.target.value })}
                >
                <MenuItem value="Self-Funded">Self-Funded</MenuItem>
                      <MenuItem value="Parents">Parents</MenuItem>
                      <MenuItem value="Scholarship">Scholarship</MenuItem>
                      <MenuItem value="Bank Loan">Bank Loan</MenuItem>
                      <MenuItem value="Employer Scholarship">Employer Scholarship</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
      <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['year']}
    label="Desired Year"
    // Initialize the value to the year if it exists or null
    value={currentParticipant?.intakeYear ? new Date(currentParticipant.intakeYear, 0) : null}
    onChange={(newDate) => {
      // Ensure we only save the year
      const year = newDate ? newDate.getFullYear() : null; // Extract year or set to null
      setCurrentParticipant({ ...currentParticipant, intakeYear: year });
    }}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    sx={{ width: '100%' }}
    minDate={new Date(new Date().getFullYear(), 0)} // Ensure only future years are selectable
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>
</Grid>

        {/* Month Picker */}
        <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['month']}
    label="Desired Month"
    // Initialize the value to a date object where the month is set (0-based for JS Date)
    value={currentParticipant?.intakeMonth ? new Date(2024, currentParticipant.intakeMonth - 1) : null}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    onChange={(newDate) => {
      // Extract the selected month and add 1 to convert from 0-11 to 1-12
      const month = newDate ? newDate.getMonth() + 1 : null;
      setCurrentParticipant({ ...currentParticipant, intakeMonth: month });
    }}
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>

</Grid>


      </Grid>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Institution Information</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Institution Name"
              value={stageFormData?.institutionName}
              onChange={(e) => handleStageChange('institutionName', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

          <Autocomplete
              id="country-select-demo"
              options={countries}
              getOptionLabel={(option) => option.label} // Show country name as label
              value={stageFormData?.institutionCountry || null} // Bind selected countries from currentParticipant
              onChange={handleDestinationsChange} // Handle change to update currentParticipant's destinations
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
               <img
                 loading="lazy"
                 width="20"
                 srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                 src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                 alt=""
               />
               {option.label} 
             </Box>                
            )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Institution Country"
                  placeholder="Institution Country"
                  margin="normal"
                />
              )}
              isOptionEqualToValue={(option, value) => option.label === value.label} // Ensure values are matched by name
            />
                      </Grid>
                      <Grid item xs={12} sm={6}>

               <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Program Name"
              value={stageFormData?.programName}
              onChange={(e) => handleStageChange('programName', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

               <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Program ID"
              value={stageFormData?.programID}
              onChange={(e) => handleStageChange('programID', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Application ID"
              value={stageFormData?.applicationID}
              onChange={(e) => handleStageChange('applicationID', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" required margin="normal" fullWidth>
  <InputLabel>Provider Name</InputLabel>
  <Select
    label="Provider Name"
    value={stageFormData?.providerName || ''}
    onChange={(e) => handleStageChange('providerName', e.target.value)}
    MenuProps={{
      PopperProps: {
        modifiers: [
          {
            name: 'zIndex',
            enabled: true,
            phase: 'afterWrite',
            fn: ({ state }) => {
              state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
            },
          },
        ],
      },
    }}
  >
    {services.map((service) => (
      <MenuItem key={service.id} value={service.name}>
        {service.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

            </Grid>
        </Grid>

          </>
          
            )}
            {stageFormData?.currentStage === 'Commission Tracking'  && (
              <div>
              <FormControl variant="outlined" required margin="normal" fullWidth>
              <InputLabel>Commission Status</InputLabel>
              <Select
                label="Commission Status"
                value={stageFormData?.invoiceStatus || ''}
                onChange={(e) => handleStageChange('invoiceStatus', e.target.value)}
                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Paid">Paid</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" required margin="normal" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={stageFormData?.finalizedStatus || ''}
                onChange={(e) => handleStageChange('finalizedStatus', e.target.value)}
                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Conditional Offer">Conditional Offer</MenuItem>
              <MenuItem value="Unconditional Offer">Unconditional Offer</MenuItem>
              <MenuItem value="Withdraw">Withdraw</MenuItem>
              </Select>
            </FormControl> 
            <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Partner University/Pathway Program"
              value={stageFormData?.PartnerUniversityPathwayProgram || ""}
              onChange={(e) => handleStageChange('PartnerUniversityPathwayProgram', e.target.value)}
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Fee Agreement with Partner Institution"
              value={stageFormData?.FeeAgreementwithPartnerInstitution || ""}
              onChange={(e) => handleStageChange('FeeAgreementwithPartnerInstitution', e.target.value)}
              required
              number
            />
          </FormControl>
          </Grid> </Grid>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Student Information</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Student Name"
              value={`${currentParticipant?.f_name} ${currentParticipant?.l_name}`}
              disabled
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Student ID"
              value={currentParticipant?._id}
              disabled
            />
          </FormControl>
        </Grid> 
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Date of Birth"
              value={currentParticipant?.birthdate}
              disabled
              fullWidth
            />
          </FormControl>
          </Grid>
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Applicant Country"
              value={currentParticipant?.ResidenceCountry?.label || ""}
              disabled
              fullWidth
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

          <FormControl variant="outlined" required margin="normal" fullWidth>
              <InputLabel>Degree</InputLabel>
              <Select
                label="Degree"
                value={currentParticipant?.degree || ''}
                onChange={((e) => {
                    setCurrentParticipant({ ...currentParticipant, degree: e.target.value });
                  })}

                MenuProps={{
                    PopperProps: {
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'afterWrite',
                          fn: ({ state }) => {
                            state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
                          },
                        },
                      ],
                    },
                  }}

                >
              <MenuItem value="Language School">Language School</MenuItem>
              <MenuItem value="Foundation">Foundation</MenuItem>
              <MenuItem value="Bachelor">Bachelor</MenuItem>
              <MenuItem value="Master">Master</MenuItem>
              <MenuItem value="Doctorate">Doctorate</MenuItem>

              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>

<FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Financial Source</InputLabel>
              <Select
                label="Financial Source"
                value={currentParticipant?.financialSource || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, financialSource: e.target.value })}
                >
                <MenuItem value="Self-Funded">Self-Funded</MenuItem>
                      <MenuItem value="Parents">Parents</MenuItem>
                      <MenuItem value="Scholarship">Scholarship</MenuItem>
                      <MenuItem value="Bank Loan">Bank Loan</MenuItem>
                      <MenuItem value="Employer Scholarship">Employer Scholarship</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
      <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['year']}
    label="Desired Year"
    // Initialize the value to the year if it exists or null
    value={currentParticipant?.intakeYear ? new Date(currentParticipant.intakeYear, 0) : null}
    onChange={(newDate) => {
      // Ensure we only save the year
      const year = newDate ? newDate.getFullYear() : null; // Extract year or set to null
      setCurrentParticipant({ ...currentParticipant, intakeYear: year });
    }}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    sx={{ width: '100%' }}
    minDate={new Date(new Date().getFullYear(), 0)} // Ensure only future years are selectable
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>
</Grid>

        {/* Month Picker */}
        <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['month']}
    label="Desired Month"
    // Initialize the value to a date object where the month is set (0-based for JS Date)
    value={currentParticipant?.intakeMonth ? new Date(2024, currentParticipant.intakeMonth - 1) : null}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    onChange={(newDate) => {
      // Extract the selected month and add 1 to convert from 0-11 to 1-12
      const month = newDate ? newDate.getMonth() + 1 : null;
      setCurrentParticipant({ ...currentParticipant, intakeMonth: month });
    }}
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>

</Grid>


      </Grid>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Institution Information</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Institution Name"
              value={stageFormData?.institutionName}
              onChange={(e) => handleStageChange('institutionName', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

          <Autocomplete
              id="country-select-demo"
              options={countries}
              getOptionLabel={(option) => option.label} // Show country name as label
              value={stageFormData?.institutionCountry || null} // Bind selected countries from currentParticipant
              onChange={handleDestinationsChange} // Handle change to update currentParticipant's destinations
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
               <img
                 loading="lazy"
                 width="20"
                 srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                 src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                 alt=""
               />
               {option.label} 
             </Box>                
            )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Institution Country"
                  placeholder="Institution Country"
                  margin="normal"
                />
              )}
              isOptionEqualToValue={(option, value) => option.label === value.label} // Ensure values are matched by name
            />
                      </Grid>
                      <Grid item xs={12} sm={6}>

               <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Program Name"
              value={stageFormData?.programName}
              onChange={(e) => handleStageChange('programName', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

               <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Program ID"
              value={stageFormData?.programID}
              onChange={(e) => handleStageChange('programID', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Application ID"
              value={stageFormData?.applicationID}
              onChange={(e) => handleStageChange('applicationID', e.target.value)}

              fullWidth
              required
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" required margin="normal" fullWidth>
  <InputLabel>Provider Name</InputLabel>
  <Select
    label="Provider Name"
    value={stageFormData?.providerName || ''}
    onChange={(e) => handleStageChange('providerName', e.target.value)}
    MenuProps={{
      PopperProps: {
        modifiers: [
          {
            name: 'zIndex',
            enabled: true,
            phase: 'afterWrite',
            fn: ({ state }) => {
              state.elements.popper.style.zIndex = '2100'; // Ensure higher z-index
            },
          },
        ],
      },
    }}
  >
    {services.map((service) => (
      <MenuItem key={service.id} value={service.name}>
        {service.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

            </Grid>
        </Grid>
                          <FormControl variant="outlined" required margin="normal" fullWidth>
            <TextField
            label="Tuition Fees"
            variant="outlined"
            fullWidth
            value={stageFormData?.tuitionFees || ''}
            onChange={(e) => handleStageChange('tuitionFees', e.target.value)}

            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
              inputMode: 'numeric',
            }}
            placeholder="Enter tuition fees"
            margin="normal"
          />
                      </FormControl>
                      <FormControl variant="outlined" required margin="normal" fullWidth>
                      <TextField
              label="Commission (%)"
              variant="outlined"
              fullWidth
              value={stageFormData?.commissionPercent || ''}
              onChange={(e) => handleStageChange('commissionPercent', e.target.value)}

              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PercentIcon />
                  </InputAdornment>
                ),
                inputMode: 'numeric',
              }}
              placeholder="Enter commission %"
              margin="normal"
            />
            </FormControl>
            <Box
              sx={{
                backgroundColor: '#e3f2fd',
                padding: 2,
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Final Amount (USD):
              </Typography>
              <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                <CalculateIcon sx={{ marginRight: 1 }} />
                ${finalAmount}
              </Typography>
            </Box>

                      </div>

            )} 
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
  variant="outlined"
  color="secondary"
  onClick={handleCloseEdit}
  sx={{
    mr: 2, // Space between buttons
    borderRadius: '20px', // Softer rounded corners for a modern look
    textTransform: 'none', // Keep text in original case
    borderColor: 'secondary.main', // Border color matches the theme
    paddingX: 3, // Extra padding for better appearance
    fontWeight: '500', // Slightly less bold for a subtler look
    background: 'linear-gradient(45deg, #f5f5f5, #e0e0e0)', // Subtle gradient background
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
    '&:hover': {
      backgroundColor: '#d3d3d3', // Slightly darker on hover
      borderColor: 'secondary.dark', // Change border color on hover
    },
  }}
>
  Cancel
</Button>


  <Button
    variant="contained"
    color="primary"
    onClick={handleSaveChanges}
    sx={{
      borderRadius: '8px', // Consistent rounded corners
      textTransform: 'none', // Disable uppercase
      paddingX: 3, // Add horizontal padding to make the button wider
      fontWeight: 'bold', // Give it a professional bold look
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for a premium feel
      '&:hover': {
        backgroundColor: 'primary.dark', // Darker shade on hover
      },
    }}
  >
    Save Changes
  </Button>
</Box>

              </LocalizationProvider>
              </Box>

            </Box>
      //    </Modal>

        )}
      </Box>
    </Modal>
  );
};

export default StageEditModal;
