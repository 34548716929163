import React, { useState } from 'react';
import {
  Box, Button, Modal, TextField, Typography, FormControl,InputLabel,Select,MenuItem,
} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import LinearProgress from '@mui/material/LinearProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
  overflowY: 'auto',
};

const AddEventModal = ({ open, handleClose, fetchEvents, setAlertOpen,setMessage ,setSeverity }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('10:00');
  const [endTime, setEndTime] = useState('20:00');
  const [locationAddress, setLocationAddress] = useState('');
  const [locationURL, setLocationURL] = useState('');
  const [has_talent, sethas_talent] = useState('');
  const [has_stage, sethas_stage] = useState('');
  const [is_school, setis_school] = useState('');
  const [embedded, setEmbedded] = useState('');

  const [progress, setProgress] = useState(false);

  const [error, setError] = useState('');//
  const currentDate = new Date().toISOString().split('T')[0];

  const formatDate = (date) => dayjs(date).format('YYYY-MM-DD');
  const formatTime = (time) => dayjs(time, 'HH:mm').format('HH:mm');

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dayjs(endDate).isBefore(dayjs(startDate))) {
      setError('End date cannot be before start date');
      return;
    }
    const formattedStartTime = dayjs(`2020-01-01 ${startTime}`, 'YYYY-MM-DD HH:mm');
    const formattedEndTime = dayjs(`2020-01-01 ${endTime}`, 'YYYY-MM-DD HH:mm');

    if (formattedEndTime.isBefore(formattedStartTime)) {
      setError('End time cannot be before start time');
      return;
    }
    setProgress(true);
    try {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const capitalizedEventName = capitalizeFirstLetter(name);

      const formData = {
        name:capitalizedEventName,
        description,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        start_time: startTime,
        end_time: endTime,
        location_address: locationAddress,
        map: locationURL,
        embedded_map:embedded,
        has_stage,
        has_talent,
        is_school,
      };
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const response = await axios.post('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/addevent', formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });

      if (response.status === 201) {
        fetchEvents();
        handleCloseModal();
        setMessage("Event created Successfully");
      setSeverity("success")
      setAlertOpen(true);
      } else {
      setMessage("Error adding event");
      setSeverity("error")
      setAlertOpen(true);//
      }
    } catch (error) {
      setMessage("Error adding event");
      setSeverity("error")
      setAlertOpen(true);    }
      setProgress(false);

  };

  const handleCloseModal = () => {
    setName('');
    setDescription('');
    setStartDate('');
    setEndDate('');
    setStartTime('');
    setEndTime('');
    setLocationAddress('');
    setLocationURL('');
    setEmbedded('');
    sethas_stage('');
    setis_school('');
    sethas_talent('');
    setError('');
    handleClose();
  };

  return (
    <Modal
  open={open}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      maxHeight: '85%',
      overflowY: 'auto',
    }}
  >
    <Typography
      id="modal-title"
      variant="h5"
      component="h2"
      sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}
    >
      Add New Event
    </Typography>

    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Event Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Description"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={3}
          variant="outlined"
        />
      </FormControl>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
            variant="outlined"
            inputProps={{ min: currentDate }}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="End Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
            variant="outlined"
            inputProps={{ min: currentDate }}
          />
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Start Time"
            type="time"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            required
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="End Time"
            type="time"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            required
            variant="outlined"
          />
        </FormControl>
      </Box>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Location Address"
          fullWidth
          value={locationAddress}
          onChange={(e) => setLocationAddress(e.target.value)}
          required
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Google Embedded Address"
          fullWidth
          value={embedded}
          onChange={(e) => setEmbedded(e.target.value)}
          required
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Location URL"
          fullWidth
          value={locationURL}
          onChange={(e) => setLocationURL(e.target.value)}
          required
          variant="outlined"
        />
      </FormControl>
      <Box sx={{ mt:2,display: 'flex', gap: 2 }}>

                   <FormControl required fullWidth>
                      <InputLabel>Stage</InputLabel>
                      <Select
                        label="Stage"
                        value={has_stage}
                        name="Stage"
                        fullWidth
                        required
                        onChange={(e) => sethas_stage(e.target.value)}

                      >  
                        <MenuItem value="1">Create Stage</MenuItem>
                        <MenuItem value="0">No Stage</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl required fullWidth>
                      <InputLabel>School Event</InputLabel>
                      <Select
                        label="School Event"
                        value={is_school}
                        name="is_school"
                        fullWidth
                        required
                        onChange={(e) => setis_school(e.target.value)}

                      >  
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl required fullWidth>
                      <InputLabel>Got Talent</InputLabel>
                      <Select
                        label="Got Talent"
                        value={has_talent}
                        name="Got Talent"
                        fullWidth
                        required
                        onChange={(e) => sethas_talent(e.target.value)}

                      >  
                        <MenuItem value="1">Create Got Talent</MenuItem>
                        <MenuItem value="0">No Got Talent</MenuItem>
                      </Select>
                    </FormControl>
                    </Box>

      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
      >
        Add Event
      </Button>

      {progress && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
    </form>
  </Box>
</Modal>

  );
};

export default AddEventModal;
