import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Modal,Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, TextField, IconButton, Select, MenuItem, FormControl, InputLabel, Avatar, Typography, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CommentIcon from '@mui/icons-material/Comment';
import MailOutlineIcon from '@mui/icons-material/MailOutline'; // Import mail icon
import SendIcon from '@mui/icons-material/Send'; // Import the Send icon


const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
      secondary: {
        main: "#000000"
      }
    }
  });
const ProblemsPage = () => {
  const [problems, setProblems] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentProblem, setCurrentProblem] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [progress, setProgress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");

  const [emailModalOpen, setEmailModalOpen] = useState(false); // State for email modal
const [emailData, setEmailData] = useState({
  name: '',
  email: '',
  message: '',
});
  useEffect(() => {
    fetchProblems();
  }, [filterStatus]);

  const fetchProblems = async () => {
    setProgress(true);
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getproblems',{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

      }
      });
      let filteredProblems = response.data;
      if (filterStatus) {
        filteredProblems = filteredProblems.filter(problem => problem.status === filterStatus);
      }
      setProblems(filteredProblems.reverse());
    } catch (error) {
    }
    setProgress(false);
  };

  const handleEditOpen = (problem) => {
    setCurrentProblem(problem);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentProblem(null);
    setEditOpen(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedComment("");
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/updateproblem/${currentProblem._id}`, currentProblem,{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

      }
      });
      fetchProblems();
      handleEditClose();
    } catch (error) {
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setSelectedImage('');
    setImageModalOpen(false);
  };

  const handleStatusChange = (e) => {
    setFilterStatus(e.target.value);
  };
  const handleCommentClick = (comment) => {
    setSelectedComment(comment);
    setOpenModal(true);
  };
  //
  // Open the email modal
const handleEmailModalOpen = (problem) => {
  setEmailData({
    name: problem.name,
    email: problem.email,
    message: '',  // Default empty message
  });
  setEmailModalOpen(true);
};

// Close the email modal
const handleEmailModalClose = () => {
  setEmailModalOpen(false);
  setEmailData({
    name: '',
    email: '',
    message: '',
  });
};

// Handle email input changes
const handleEmailInputChange = (e) => {
  const { name, value } = e.target;
  setEmailData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

// Submit the email form
const handleSendEmail = async (e) => {
  e.preventDefault();
  try {
    const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
    await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/send-email', emailData,{
      headers: {
        Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

    }
    }); // Send email using the API
    handleEmailModalClose(); // Close the modal after success
    fetchProblems(); // Refresh the list
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'dynamicNumber', headerName: 'Where', width: 200 },
    { field: 'problem', headerName: 'Problem Description', width: 300,
    renderCell: (params) => (
      <IconButton
onClick={() => handleCommentClick(params.value)} variant="outlined">
<CommentIcon/>
</IconButton>

)
     },
    {
      field: 'screenshotUrl',
      headerName: 'Screenshot',
      width: 150,
      renderCell: (params) => params.value ? (
        <Avatar
          src={params.value}
          sx={{ width: 56, height: 56, cursor: 'pointer' }}
          onClick={() => handleImageClick(params.value)}
        />
      ) : 'No Screenshot'
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        params.value === '0' ? (
          <CancelIcon style={{ color: 'red' }} />
        ) : (
          <CheckCircleIcon style={{ color: 'green' }} />
        )
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,  // Increased width for new icon
      renderCell: (params) => (
        <>
        <IconButton onClick={() => handleEditOpen(params.row)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleEmailModalOpen(params.row)} style={{ marginLeft: '10px' }}>
          <MailOutlineIcon />
        </IconButton>
      </>
      )
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Problems List
        </Typography>

        <FormControl sx={{ mb: 2, minWidth: 150 }}>
          <InputLabel>Status Filter</InputLabel>
          <Select value={filterStatus} onChange={handleStatusChange} label="Status Filter">
            <MenuItem value="">All</MenuItem>
            <MenuItem value="1">Resolved</MenuItem>
            <MenuItem value="0">Pending</MenuItem>
          </Select>
        </FormControl>

        {progress ? <LinearProgress /> : (
          <Box sx={{ height: 600 }}>
            <DataGrid
              rows={problems}
              columns={columns}
              pageSize={10}
              getRowId={(row) => row._id}
              disableSelectionOnClick
            />
          </Box>
        )}

        {/* Edit Modal */}
        <Modal open={editOpen} onClose={handleEditClose}>
          <Box sx={{ ...modalStyle }}>
            <Typography variant="h6">Edit Problem Status</Typography>
            {currentProblem && (
              <form onSubmit={handleEditSubmit}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={currentProblem.status}
                    onChange={(e) => setCurrentProblem({ ...currentProblem, status: e.target.value })}
                    label="Status"
                  >
                    <MenuItem value="1">Resolved</MenuItem>
                    <MenuItem value="0">Pending</MenuItem>
                  </Select>
                </FormControl>
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Save Changes
                </Button>
              </form>
            )}
          </Box>
        </Modal>

        {/* Image Modal */}
        
        <Modal open={imageModalOpen} onClose={handleImageModalClose}>
          <Box sx={{ ...modalStyle, display: 'flex', justifyContent: 'center' }}>
            <img src={selectedImage} alt="Screenshot" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Modal>
      </Box>
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Problem Description</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedComment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={emailModalOpen} onClose={handleEmailModalClose} maxWidth="sm" fullWidth>
        <DialogTitle>Send Email</DialogTitle>
        <form onSubmit={handleSendEmail}>
          <DialogContent>
            <TextField
              margin="dense"
              label="Name"
              name="name"
              value={emailData.name}
              onChange={handleEmailInputChange}
              fullWidth
              disabled
            />
            <TextField
              margin="dense"
              label="Email"
              name="email"
              value={emailData.email}
              onChange={handleEmailInputChange}
              fullWidth
              disabled
            />
            <TextField
              margin="dense"
              label="Message"
              name="message"
              value={emailData.message}
              onChange={handleEmailInputChange}
              fullWidth
              multiline
              rows={4}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEmailModalClose} color="primary">
              Cancel
            </Button>
            <Button 
  type="submit" 
  color="primary" 
  variant="contained"
  sx={{ padding: '6px 12px', fontSize: '0.75rem' }} // Make the button smaller
  startIcon={<SendIcon />} // Add the send icon to the button
>
  Send Email
</Button>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default ProblemsPage;

// Custom styles
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};
