// EditSponsorModal.js
import React from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  LinearProgress,
  FormControlLabel,
  Switch,
  Chip,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Alert,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const EditSponsorModal = ({
  editOpen,
  handleEditClose,
  currentSponsor,
  setCurrentSponsor,
  handleEditSubmit,
  handlePhotoUpload,
  photo,
  handleSwitchChange,
  handleEventChange,
  availableEvents,
  eventError,
  sponsors,
  handleRankChange,
  progress
}) => {
  return (
    <Modal open={editOpen} onClose={handleEditClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          maxHeight: '85%',
          overflowY: 'auto',
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
        >
          Edit Sponsor
        </Typography>

        <form onSubmit={handleEditSubmit}>
          {/* Name Input */}
          <TextField
            label="Name"
            value={currentSponsor.name}
            onChange={(e) => setCurrentSponsor({ ...currentSponsor, name: e.target.value })}
            fullWidth
            margin="normal"
            required
            variant="outlined"
          />

          {/* Type Select */}
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              label="Type"
              value={currentSponsor.type}
              onChange={(e) => setCurrentSponsor({ ...currentSponsor, type: e.target.value })}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="Silver">Silver</MenuItem>
              <MenuItem value="Gold">Gold</MenuItem>
              <MenuItem value="Platinum">Platinum</MenuItem>
            </Select>
          </FormControl>

          {/* Description Input */}
          <TextField
            label="Description"
            value={currentSponsor.description}
            onChange={(e) => setCurrentSponsor({ ...currentSponsor, description: e.target.value })}
            fullWidth
            margin="normal"
            variant="outlined"
          />

          {/* Image URL (Disabled) */}
          <TextField
            label="Image URL"
            value={currentSponsor.image}
            onChange={(e) => setCurrentSponsor({ ...currentSponsor, image: e.target.value })}
            fullWidth
            margin="normal"
            required
            disabled
            variant="outlined"
          />

          {/* File Upload Section */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-photo"
                type="file"
                onChange={handlePhotoUpload}
              />
              <label htmlFor="upload-photo">
                <Button
                  startIcon={<CloudUploadIcon />}
                  variant="outlined"
                  component="span"
                  fullWidth
                  sx={{
                    py: 1.5,
                    fontWeight: 'bold',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                  }}
                >
                  Change Image
                </Button>
              </label>
              {photo && <Typography sx={{ mt: 1, color: 'text.secondary' }}>{photo.name}</Typography>}
            </Grid>
          </Grid>

          {/* Deleted Switch */}
          <FormControlLabel
            control={
              <Switch
                checked={currentSponsor.deleted === '1'}
                onChange={handleSwitchChange('deleted')}
              />
            }
            label="Deleted"
            sx={{ mt: 2 }}
          />

          {/* Event Select */}
          <FormControl fullWidth margin="normal" sx={{ mt: 2 }}>
            <InputLabel id="event-label">Event</InputLabel>
            <Select
              labelId="event-label"
              id="event"
              multiple
              value={currentSponsor.event_ids}
              onChange={handleEventChange}
              input={<OutlinedInput label="Event" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected
                    .map((value) => availableEvents.find((event) => event.id === value))
                    .filter((event) => event) // Filter out undefined events
                    .map((event) => (
                      <Chip key={event.id} label={event.name} />
                    ))}
                </Box>
              )}
              variant="outlined"
            >
              {availableEvents.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                  <Checkbox checked={currentSponsor.event_ids.includes(event.id)} />
                  <ListItemText primary={event.name} />
                </MenuItem>
              ))}
            </Select>
            {eventError && <Alert severity="error" sx={{ mt: 1 }}>{eventError}</Alert>}
          </FormControl>

          {/* Rank Input */}
          <TextField
            label="Rank"
            type="number"
            value={sponsors.length - (currentSponsor.rankNum - 1)}
            onChange={handleRankChange}
            fullWidth
            margin="normal"
            required
            inputProps={{ min: 1, max: sponsors.length }}
            variant="outlined"
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
          >
            Save
          </Button>

          {/* Progress Indicator */}
          {progress && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress />
            </Box>
          )}
        </form>
      </Box>
    </Modal>
  );
};

export default EditSponsorModal;
